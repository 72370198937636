/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';

import * as api from '../../api/index';
import FreshChat from 'react-freshchat';
import Text from '../../components/molecules/text';
import Button from '../../components/molecules/button';
import Config from '../../Config';
import Header from '../../components/organisms/header/index';
import SideMenu from '../../components/organisms/menu/index';
import { confirmAlert } from 'react-confirm-alert';
import AreaCode from '../../assets/icons/pngs/areaCode.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Dropzone from './Dropzone';
import TableAlt from '../orders/TableAlt';
import Loading from '../../assets/loading.gif';
import { useMediaQuery } from 'react-responsive';
import { MenuContext } from '../../MenuContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: transparent;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100vw;
  height: 100vh;
`;

const MenuOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 64px;
  padding-right: 64px;
`;

const MenuOption = styled.a`
width: 95%
justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: 'Inter';
  font-weight: 400;
 font-size: 18px;
 cursor: pointer;
  ${(props) => (props.fontColor ? `color: ${props.fontColor}` : '#0e0e0f')};
  ${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight}` : '')};
  cursor: pointer;
  display: flex;
  ${(props) => (props.bottom ? 'bottom: 24px;' : '')}
  ${(props) => (props.bottom ? 'position: absolute;' : '')}
  border-top: 1px solid rgb(227, 229, 232);
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
`;

const Row = styled.div`
  margin-top: 22px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;

  width: 100%;
`;
const PhoneInput = styled.input`
  top: 416px;
  left: 308px;
  width: 340px;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e1e4eb;
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Inter';
  text-align: left;
  padding-left: 60px;
  padding-right: 10px;
  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
  background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
  background-position: left;
  background-size: 60px;
  background-repeat: no-repeat;
`;
const Input = styled.input`
  top: 416px;
  left: 308px;
  width: 384px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #cccccc;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'Inter';
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;

  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
`;

const VerifyForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
`;

const HomePage = ({ history, match }) => {
  const theme = localStorage.getItem('theme') + '-mode';
  const {sidemenu} = useContext(MenuContext);

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
});

  let api_token;
  let secondaryUser;
  const field = 'user_id';
  const url = window.location.href;
  if (url.includes('?' + field + '=')) {
    const index = url.indexOf('=') + 1;
    const userId = url.slice(index, url.length);
    secondaryUser = true;
    api_token = window.localStorage.getItem(`${userId}`);
  } else {
    api_token = localStorage.getItem('api_token');
  }

  if (!api_token) {
    console.log('dash was hit');
    // debugger;
    history.push('/login');
  } else if (typeof api_token === 'object') {
    if (api_token.message.includes('invalid')) {
      localStorage.clear();
      history.push('/login');
    }
  }

  const [user, setUser] = useState(false);
  const [subaccounts, setSubAccounts] = useState(false);
  const [addSubAccount, setSubAccount] = useState(false);
  const [settingsMenu, setSettingsMenu] = useState(true);
  const [filesAccepted, setFilesAccepted] = useState([]);


  useEffect(() => {
    if(filesAccepted.type) {
      history.push({ 
       pathname: '/tools',
       state: {
        'file': filesAccepted
       }
      });
    }

  }, [filesAccepted, history])


  const getSubaccounts = () => {
    api.getApiAccess({ api_token }).then((result) => {
      // debugger;
      if (result) {
        console.log('getSubAccounts', result);
        setSubAccounts(result.sub_accounts);
      }
    });
  };

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map(file => {
      let valid = [];
      if(file.type == "text/csv") {
        valid.push(file);
      }

      setFilesAccepted(file);
    });
  }, []);

  const getUser = useCallback(() => {
    api
      .getUserDetails({
        api_token,
      })
      .then((result) => {
        // debugger;
        if (result.error === 'invalid_api_key') {
          console.log('this is from sub account');
          localStorage.removeItem('api_token');
          history.push('/login');
        } else if (result.phone_verified == false) {
          history.push("/register");
        } else {
          console.log('user', result);
          setUser(result);
        }
      });
  }, [api_token, history]);

  useEffect(() => {
    getUser();
    // getSubaccounts();
  }, []);

  const logoutUser = () => {
    const field = 'user_id';
    const url = window.location.href;
    if (url.includes('?' + field + '=')) {
      const index = url.indexOf('=') + 1;
      const userId = url.slice(index, url.length);
      localStorage.removeItem(userId);
    } else {
      localStorage.removeItem('api_token');
    }

    history.push('/login');
  };

  return (
    <div className={theme}>
      {sidemenu ? (<SideMenu history={history} />) : 
      (<OuterContainer>
        <Header history={history} />

        <div style={{display: 'flex',width: '100%'}}>
          {!isTabletOrMobileDevice && <SideMenu history={history} />}
          {isTabletOrMobileDevice ? (
            <div className="main-content">
              <div className="inner-box">
                <h2>Welcome back,<br/> {user.name}</h2>

                {/* <div> */}
                  <div className="round-container">
                    <div className="section">
                      Total Fees Paid
                      <h1>{user.business_stats ? user.business_stats.total_fees : '0.00'}</h1>
                    </div>
                    <div className="section">
                      Completed Orders
                      <h1>{user.business_stats ? user.business_stats.completed : '0'}</h1>
                    </div>
                  </div>

                  <div className="round-container mt-4">
                  <div className="section">
                      Active Orders
                      <h1>{user.business_stats ? user.business_stats.active : '0'}</h1>
                    </div>
                    <div className="section">
                      Average Order Price
                      <h1>{user.business_stats ? user.business_stats.average : '0.00'}</h1>
                    </div>
                  </div>
                {/* </div> */}
                <div className="containers">
                  <div className="round-container">
                    <h2>Download Order Template</h2>
                    <p className="expand">Please download the file below to obtain a bulk-order template</p>
                    <button className="general-button"
                    onClick={() => {
                      window.location.replace("https://business.gokada.ng/bulk_upload_template.csv");
                    }}
                    >Download Template</button>
                  </div>
                  <div className="round-container mt-4">
                    <h2>Bulk Upload</h2>
                    <Dropzone onDrop={onDrop} accept={"text/csv"} />
                    <button className="general-button">Upload</button>
                  </div>
                </div>
              </div>
          </div>
          ) : (

          <div className="main-content">
            {!user && (
              <div style={{textAlign: 'center',width: '100%','marginTop': '100px'}}>
                <img src={Loading} width="100" height="100" />
              </div>
            )}
            {user && (
            <div className="inner-box">
              <h1>Welcome back, {user.name}</h1>
              <div className="round-container">
                <div className="section">
                  Total Fees Paid
                  <h1>{user.business_stats ? user.business_stats.total_fees : '0.00'}</h1>
                </div>
                <div className="section">
                  Completed Orders
                  <h1>{user.business_stats ? user.business_stats.completed : '0'}</h1>
                </div>
                <div className="section">
                  Active Orders
                  <h1>{user.business_stats ? user.business_stats.active : '0'}</h1>
                </div>
                <div className="section">
                  Average Order Price
                  <h1>{user.business_stats ? user.business_stats.average : '0.00'}</h1>
                </div>
              </div>

              <div className="containers">
                <div className="round-container">
                  <h2>Download Order Template</h2>
                  <p className="expand">Please download the file below to obtain a bulk-order template</p>
                  <button className="general-button"
                  onClick={() => {
                    window.location.replace("https://business.gokada.ng/bulk_upload_template.csv");
                  }}
                  >Download Template</button>
                </div>
                <div className="round-container">
                  <h2>Bulk Upload</h2>
                  <Dropzone onDrop={onDrop} accept={"text/csv"} />
                  <button className="general-button">Upload</button>
                </div>
              </div>

              <h2 style={{marginTop: '40px'}}>Scheduled Orders</h2>

              <div style={{textAlign: 'center',width: '100%'}}>
                {user && user.business_orders_schd.map((row,index) => (
                  <div>
                    <TableAlt user={user} index={index} data2={row[0]} data={row} hideCheckbox={true} key={Math.random()} /> <br />
                  </div>
                ))}
                {!user && (
                  <div style={{textAlign: 'center',width: '100%'}}>
                    <img src={Loading} width="100" height="100" />
                  </div>
                )}
              </div>
            </div>
            )}
            
          </div>
          )}
        </div>


      </OuterContainer>)
}
    </div>
  );
};

export default HomePage;
