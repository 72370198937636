import React from 'react';
import styled from 'styled-components';

import locationSVG from '../../../assets/icons/svgs/ic-location.svg';
import descriptionSVG from '../../../assets/icons/svgs/ic-description.svg';
import emailSVG from '../../../assets/icons/svgs/ic-email.svg';
import nameSVG from '../../../assets/icons/svgs/ic-name.svg';
import phoneSVG from '../../../assets/icons/svgs/ic-phone.svg';
import openSVG from '../../../assets/icons/svgs/ic-opened-arrow.svg';
import closedSVG from '../../../assets/icons/svgs/ic-closed-arrow.svg';
import addSVG from '../../../assets/icons/svgs/ic-add.svg';
import removeSVG from '../../../assets/icons/svgs/ic-remove.svg';
import callSVG from '../../../assets/icons/svgs/btn-call.svg';
import rightSVG from '../../../assets/icons/svgs/ic-chevron-right.svg';
import searchingSVG from '../../../assets/icons/svgs/img-searching.svg';
import homeSVG from '../../../assets/icons/svgs/home.svg';
import keySVg from '../../../assets/icons/svgs/key.svg';
import listSVG from '../../../assets/icons/svgs/list.svg';
import toolSVG from '../../../assets/icons/svgs/tool.svg';
import sidebarSVG from '../../../assets/icons/svgs/sidebar.svg';
import addressSVG from '../../../assets/icons/svgs/addressbook.svg';
import LightSVG from '../../../assets/icons/svgs/switch-light.svg';
import DarkSVG from '../../../assets/icons/svgs/switch-dark.svg';

const getIcon = (name) => {
  switch (name) {
    case 'light':
      return LightSVG;
    case 'dark':
      return DarkSVG;
    case 'phone':
      return phoneSVG;
    case 'email':
      return emailSVG;
    case 'add':
      return addSVG;
    case 'remove':
      return removeSVG;
    case 'closed':
      return closedSVG;
    case 'open':
      return openSVG;
    case 'home':
      return homeSVG;
    case 'key':
      return keySVg;
    case 'list':
      return listSVG;
    case 'tool':
      return toolSVG;
    case 'sidebar':
      return sidebarSVG;
    case 'addressbook':
      return addressSVG;
    case 'name':
      return nameSVG;
    case 'description':
      return descriptionSVG;
    case 'location':
      return locationSVG;
    case 'call':
      return callSVG;
    case 'right':
      return rightSVG;
    case 'searching':
      return searchingSVG;
    default:
      break;
  }
};

const IconContainer = styled.img`
  width: ${(props) => props.size || 24}px;
  height: ${(props) => props.size || 24}px;
  object-fit: fill;
  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) => (props.cursor ? `cursor:${props.cursor}` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
  ${(props) => (props.gridArea ? `grid-area: ${props.gridArea};` : '')};
`;
const DashIcon = ({ gridArea, name, src, size, onClick, ...props }) => {
  const svg = getIcon(name);
  return (
    <IconContainer
      gridArea={gridArea}
      src={src || svg}
      size={size}
      onClick={onClick}
      {...props}
    />
  );
};

export default DashIcon;
