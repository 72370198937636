import React, { useState, useEffect, Fragment, useRef } from "react";
import styled from "styled-components";
import _, { set } from "lodash";

import Header from "../../components/organisms/header/index";
import Text from "../../components/molecules/text/index";
import Button from "../../components/molecules/button";
import ActionButton from "../../components/molecules/action-button";
import Icon from "../../components/molecules/icon";
import DashIcon from "../../components/molecules/dash-icon";

import Cash from "../../assets/icons/pngs/ic-cash.png";
import Wallet from "../../assets/icons/pngs/ic-wallet.png";
import * as api from "../../api/index";
import WaypointInputs from "./WaypointInputs.js";
import Map from "./GoogleMap.js";
import Mapbox from "./Mapbox.js";
import AreaCode from "../../assets/icons/pngs/areaCode.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { usePaystackPayment, PaystackConsumer } from "react-paystack";
import Select from "react-select";

const Dashboard = ({ history }) => {
    // Variables and states to be passes throughout components
    const theme = localStorage.getItem("theme") + "-mode";
    let api_token;
    const field = "user_id";
    const url = window.location.href;
    if (url.includes("?" + field + "=")) {
        const index = url.indexOf("=") + 1;
        const userId = url.slice(index, url.length);
        api_token = window.localStorage.getItem(`${userId}`);
    } else {
        api_token = localStorage.getItem("api_token");
    }

    if (!api_token) {
        history.push("/login");
    } else if (typeof api_token === "object") {
        if (api_token.message.includes("invalid")) {
            localStorage.clear();
            history.push("/login");
        }
    }
    const [user, setUser] = useState({});
    const [center, setCenter] = useState({
        lat: "" || 3.363280699999999,
        lng: "" || 6.5441226,
    });

    const [taskView, setTaskView] = useState(true);
    const [addressBookView, setAddressBookView] = useState(false);
    const [route_optimize, setRouteOpt] = useState(0);
    const [mapsMarkersStack, setMapMarkers] = useState([]);
    const [pickupAddress, setPickupAddress] = useState("");
    const [pickupAddressDetails, setPickupAddressDetails] = useState("");
    const [pickupName, setPickupName] = useState("");
    const [pickupPhone, setPickupPhone] = useState("");
    const [pickupEmail, setPickupEmail] = useState("");
    const [openPickup, setPickupOpen] = useState(false);
    const [pickupSelectedDate, handlePickupDateChange] = useState(
        moment(new Date()).add(15, "m").toDate()
    );

    const [checked, setChecked] = useState(false);

    const [pickupLocation, setPickupLocation] = useState({
        address: "",
        lng: "",
        lat: "",
        location_id: "",
        location: "",
    });

    const [finalEstimate, setFinalEstimate] = useState({
        finalDistance: 0,
        finalTime: 0,
        finalCost: 0,
    });

    const blankWaypoint = {
        dropoffLocation: {
            location: "dropoff-location",
            address: "",
            lat: "",
            lng: "",
            location_id: "",
        },
        estimate: {
            user_id: "",
            amount_quoted: "",
            pickup_location_id: "",
            dropoff_location_id: "",
            distance_km: "",
            minutes: "",
            order_id: "",
        },
        recipientName: "",
        dropoff_address_details: "",
        recipientPhone: "",
        description: "",
        order_category_id: { id: "", name: "" },
        recipientEmail: "",
    };
    const [msg, setMsg] = useState("");
    const [balance, setBalance] = useState(0);
    const [waypointsStack, setWaypointsStack] = useState([
        { ...blankWaypoint },
    ]);
    const [autocompleteResults, setAutocompleteResults] = useState([]);
    const [paymentOption, setPaymentOption] = useState("cash");
    const [waypointInputError, setWaypointInputError] = useState(false);
    const [cashBorderColor, setCashBorderColor] = useState(false);
    const [walletBorderColor, setWalletBorderColor] = useState(false);
    const [orderCategories, setOrderCategories] = useState([]);
    const [regions, setRegions] = useState([]);
    const [region_id, setRegionID] = useState(0);
    let btnRef = useRef();
    let btn_class = cashBorderColor ? "activePay" : "inactivePay";
    let wallet_class = walletBorderColor ? "inactivePay" : "activePay";
    // Input handle changes
    // const handlePickupLocation = (e) => setPickupLocation(e.target.value);
    const handlePickupLocation = _.debounce(
        (text) => {
            setPickupAddress(text);
            getPickupAutocompleteResult();
        },
        500,
        { maxWait: 1000, trailing: true, leading: false }
    );

    const getUser = () => {
        api.getUserDetails({
            api_token,
        }).then((result) => {
            if (result.error === "invalid_api_key") {
                localStorage.removeItem("api_token");
                history.push("/login");
            } else if (result.new_user === true) {
                history.push("/profile");
            } else {
                localStorage.setItem("name", result.name);
                setUser(result);
            }
        });
    };

    const getOrderCategories = () => {
        api.getWaypointOrderCategories({ api_token }).then((result) => {
            if (result.order_categories.length >= 1) {
                const orderCat = [];
                result.order_categories.map((cat) => {
                    cat.label = jsUcfirst(cat.name);
                    orderCat.push(cat);
                });
                setOrderCategories(orderCat);
            }

            const regionArr = Object.values(result.regions);   
            if (regionArr.length >= 1) {
                const regions = [];
                regionArr.forEach((cat) => {
                    regions.push({id: cat.id, label: `${cat.name} - ${cat.description}`});
                });
                setRegions(regions);
            }
        });
        function jsUcfirst(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    };

    useEffect(() => {
        getUser();
        getOrderCategories();
        getApiAccessDetails();
    }, []);

    const changeColor = () => {
        setCashBorderColor(!cashBorderColor);
        setWalletBorderColor(!walletBorderColor);
    };

    const changeCardColor = () => {
        setWalletBorderColor(!walletBorderColor);
        setCashBorderColor(!cashBorderColor);
    };
    // Adds empty waypoint input field in UI
    const addWaypoint = () => {
        setWaypointsStack([...waypointsStack, { ...blankWaypoint }]);
    };

    // Goes through the event.target's className to attribute the correct value
    // for each indexed Wayppoints dropOfflocation.address vs. recipientName, recipientPhone, and description

    const handleCategoryChange = (e, idx) => {
        const updatedWaypoints = [...waypointsStack];
        let id = e.id;
        let name = e.name;

        updatedWaypoints[idx].order_category_id.id = id;
        updatedWaypoints[idx].order_category_id.name = name;
        setWaypointsStack(updatedWaypoints);
    };

    const handleWaypointChange = (e) => {
        const updatedWaypoints = [...waypointsStack];
        if (
            typeof updatedWaypoints[e.target.dataset.idx][
                e.target.classList[2]
            ] === "string"
        ) {
            if (e.target.classList[2].includes("Phone")) {
                let telephone = e.target.value;

                // if value is not blank, then test the regex
                if (telephone === "" || /^[0-9\b]+$/.test(telephone)) {
                    updatedWaypoints[e.target.dataset.idx][
                        e.target.classList[2]
                    ] = telephone;
                }
            } else {
                updatedWaypoints[e.target.dataset.idx][e.target.classList[2]] =
                    e.target.value;
            }
        } else {
            if (
                updatedWaypoints[e.target.dataset.idx][
                    e.target.classList[2]
                ].location.includes("dropoff-location")
            ) {
                updatedWaypoints[e.target.dataset.idx][
                    e.target.classList[2]
                ].address = e.target.value;
            } else {
                updatedWaypoints[e.target.dataset.idx][e.target.classList[2]] =
                    e.target.value;
            }
        }
        setWaypointsStack(updatedWaypoints);
    };

    //get API dev balance

    const getApiAccessDetails = () => {
        api.getApiAccess({ api_token }).then((result) => {
            if (result.id) {
                setBalance(result.balance);
            } else if (result.message.includes("signup")) {
                setBalance(0);
            }
        });
    };
    const removeWaypoint = ({ idx }) => {
        waypointsStack.splice(idx, 1);
        setWaypointsStack(waypointsStack);

        //Remove waypoint from mapMarkerStack;
        if (mapsMarkersStack.length > waypointsStack.length) {
            mapsMarkersStack.splice(idx + 1, 1);
            setMapMarkers(mapsMarkersStack);
            // _.debounce(setMapMarkers(mapsMarkersStack), 500);
        }

        //Add to final estimate
        let finalCost = waypointsStack.reduce(function (prev, cur) {
            return parseFloat(prev) + parseFloat(cur.estimate.amount_quoted);
        }, 0);
        let finalDistance = waypointsStack.reduce(function (prev, cur) {
            return prev + cur.estimate.distance_km;
        }, 0);
        let finalTime = waypointsStack.reduce(function (prev, cur) {
            return prev + cur.estimate.minutes;
        }, 0);

        setFinalEstimate({
            finalDistance: parseFloat(finalDistance),
            finalTime: finalTime,
            finalCost: finalCost,
        });
    };

    const getPickupAutocompleteResult = () => {
        const sessionToken = localStorage.getItem("session");
        const context = "pickup";
        api.autocomplete({
            query: pickupAddress,
            session: sessionToken,
            api_token,
            context: context,
        }).then((results) => {
            setAutocompleteResults(results);
        });
    };

    // API Functions
    const getCoords = ({ idx, location_id, pickUp, dropOff, context }) => {
        const api_token = localStorage.getItem("api_token");
        let pickupId;
        let dropoffId;
        let params;

        // Get the location Id for the autocomplete result picked
        api.getLatLng({
            location_id,
            api_token,
        })
            .then((res) => {
                if (pickUp === true) {
                    if (waypointsStack[0].dropoffLocation.address) {
                        const updatedMapMarkers = [...mapsMarkersStack];
                        setPickupAddress(res.address);
                        setPickupLocation({
                            address: res.address,
                            lng: res.lng,
                            lat: res.lat,
                            location_id: res.location_id,
                            location: "pickup-location",
                        });

                        updatedMapMarkers[0] = {
                            address: res.address,
                            lng: res.lng,
                            lat: res.lat,
                            location_id: res.location_id,
                            location: "pickup-location",
                        };

                        // _.debounce(setMapMarkers(updatedMapMarkers), 500);
                        setMapMarkers(updatedMapMarkers);
                        params = {
                            region_id,
                            pickups: [
                                {
                                    address: pickupLocation.address,
                                    latitude: pickupLocation.lat,
                                    longitude: pickupLocation.lng,
                                },
                            ],
                            dropoffs: [
                                {
                                    address:
                                        waypointsStack[0].dropoffLocation
                                            .address,
                                    latitude:
                                        waypointsStack[0].dropoffLocation.lat,
                                    longitude:
                                        waypointsStack[0].dropoffLocation.lng,
                                },
                            ],
                        };
                        // let pickupId = location_id;
                        // let dropoffId = waypointsStack[0].dropoffLocation.location_id;
                        if (region_id) {
                            api
                                // .getEstimate({
                                //   pickupId,
                                //   dropoffId,
                                //   api_token,
                                // })
                                .getEstimateV3({
                                    params,
                                    api_token,
                                })
                                .then((res) => {
                                    const updatedWaypoints = [
                                        ...waypointsStack,
                                    ];

                                    updatedWaypoints[0].estimate.amount_quoted =
                                        res.original_price;
                                    updatedWaypoints[0].estimate.distance_km =
                                        res.distance_km;
                                    updatedWaypoints[0].estimate.minutes =
                                        res.minutes;

                                    setWaypointsStack(updatedWaypoints);

                                    //Add to final estimate
                                    let finalCost = waypointsStack.reduce(
                                        function (prev, cur) {
                                            return (
                                                parseFloat(prev) +
                                                parseFloat(
                                                    cur.estimate.amount_quoted
                                                )
                                            );
                                        },
                                        0
                                    );
                                    let finalDistance = waypointsStack.reduce(
                                        function (prev, cur) {
                                            return (
                                                prev + cur.estimate.distance_km
                                            );
                                        },
                                        0
                                    );
                                    let finalTime = waypointsStack.reduce(
                                        function (prev, cur) {
                                            return prev + cur.estimate.minutes;
                                        },
                                        0
                                    );

                                    setFinalEstimate({
                                        finalDistance: finalDistance,
                                        finalTime: finalTime,
                                        finalCost: finalCost,
                                    });

                                    return pickupLocation;
                                })
                                .catch((err) => {
                                    console.log("Getting Estimate error:", err);
                                });
                        }
                    } else {
                        const updatedMapMarkers = [...mapsMarkersStack];
                        setPickupAddress(res.address);
                        setPickupLocation({
                            address: res.address,
                            lng: res.lng,
                            lat: res.lat,
                            location_id: res.location_id,
                            location: "pickup-location",
                        });

                        updatedMapMarkers[0] = {
                            address: res.address,
                            lng: res.lng,
                            lat: res.lat,
                            location_id: res.location_id,
                            location: "pickup-location",
                        };

                        // _.debounce(setMapMarkers(updatedMapMarkers), 500);
                        setMapMarkers(updatedMapMarkers);
                        return pickupLocation;
                    }
                } else if (dropOff === true) {
                    const updatedWaypoints = [...waypointsStack];
                    const updatedMapMarkers = [...mapsMarkersStack];

                    updatedWaypoints[idx].dropoffLocation.address = res.address;
                    updatedWaypoints[idx].dropoffLocation.lng = res.lng;
                    updatedWaypoints[idx].dropoffLocation.lat = res.lat;
                    updatedWaypoints[idx].dropoffLocation.location_id =
                        res.location_id;
                    updatedWaypoints[
                        idx
                    ].dropoffLocation.location = `dropoff-location-${idx + 1}`;

                    updatedMapMarkers[idx + 1] = {
                        address: res.address,
                        lng: res.lng,
                        lat: res.lat,
                        location_id: res.location_id,
                        location: `dropoff-location-${idx + 1}`,
                    };

                    setWaypointsStack(updatedWaypoints);
                    setMapMarkers(updatedMapMarkers);
                    // _.debounce(setMapMarkers(updatedMapMarkers), 500);
                    // Set pickup location id and dropoff location id
                    pickupId = mapsMarkersStack[idx].location_id;
                    dropoffId = waypointsStack[idx].dropoffLocation.location_id;

                    params = {
                        region_id,
                        pickups: [
                            {
                                address: mapsMarkersStack[idx].address,
                                latitude: mapsMarkersStack[idx].lat,
                                longitude: mapsMarkersStack[idx].lng,
                            },
                        ],
                        dropoffs: [
                            {
                                address:
                                    waypointsStack[idx].dropoffLocation.address,
                                latitude:
                                    waypointsStack[idx].dropoffLocation.lat,
                                longitude:
                                    waypointsStack[idx].dropoffLocation.lng,
                            },
                        ],
                    };

                    if (dropoffId === pickupId) {
                        setWaypointInputError(true);
                    } else {
                        setWaypointInputError(false);
                    }

                    // Get the estimate of this waypoint's order
                    if (region_id) {
                        api
                            // .getEstimate({
                            //   pickupId,
                            //   dropoffId,
                            //   api_token,
                            // })
                            .getEstimateV3({
                                params,
                                api_token,
                            })
                            .then((res) => {
                                const updatedWaypoints = [...waypointsStack];

                                // updatedWaypoints[idx].estimate.user_id = res.user_id;
                                // updatedWaypoints[idx].estimate.pickup_location_id =
                                //   res.pickup_location_id;
                                // updatedWaypoints[idx].estimate.dropoff_location_id =
                                //   res.dropoff_location_id;
                                // updatedWaypoints[idx].estimate.order_id = res.order_id;
                                updatedWaypoints[idx].estimate.amount_quoted =
                                    res.original_price;
                                updatedWaypoints[idx].estimate.distance_km =
                                    res.distance_km;
                                updatedWaypoints[idx].estimate.minutes =
                                    res.minutes;

                                setWaypointsStack(updatedWaypoints);

                                //Add to final estimate
                                let finalCost = waypointsStack.reduce(function (
                                    prev,
                                    cur
                                ) {
                                    return (
                                        parseFloat(prev) +
                                        parseFloat(cur.estimate.amount_quoted)
                                    );
                                },
                                0);
                                let finalDistance = waypointsStack.reduce(
                                    function (prev, cur) {
                                        return prev + cur.estimate.distance_km;
                                    },
                                    0
                                );
                                let finalTime = waypointsStack.reduce(function (
                                    prev,
                                    cur
                                ) {
                                    return prev + cur.estimate.minutes;
                                },
                                0);

                                setFinalEstimate({
                                    finalDistance: finalDistance,
                                    finalTime: finalTime,
                                    finalCost: finalCost,
                                });

                                return waypointsStack;
                            })
                            .catch((err) => {
                                console.log("Getting Estimate error:", err);
                            });
                    }
                }
            })
            .catch((err) => {
                console.log("Getting Coordinates error:", err);
            });
    };

    const getEstimate = () => {
        if (
            region_id &&
            pickupLocation.address &&
            waypointsStack[0].dropoffLocation.address
        ) {
            const params = {
                region_id,
                pickups: [
                    {
                        address: pickupLocation.address,
                        latitude: pickupLocation.lat,
                        longitude: pickupLocation.lng,
                    },
                ],
                dropoffs: [
                    {
                        address: waypointsStack[0].dropoffLocation.address,
                        latitude: waypointsStack[0].dropoffLocation.lat,
                        longitude: waypointsStack[0].dropoffLocation.lng,
                    },
                ],
            };

            api
                // .getEstimate({
                //   pickupId,
                //   dropoffId,
                //   api_token,
                // })
                .getEstimateV3({
                    params,
                    api_token,
                })
                .then((res) => {
                    const updatedWaypoints = [...waypointsStack];

                    updatedWaypoints[0].estimate.amount_quoted = res.original_price;
                    updatedWaypoints[0].estimate.distance_km = res.distance_km;
                    updatedWaypoints[0].estimate.minutes = res.minutes;

                    setWaypointsStack(updatedWaypoints);

                    //Add to final estimate
                    let finalCost = waypointsStack.reduce(function (prev, cur) {
                        return (
                            parseFloat(prev) +
                            parseFloat(cur.estimate.amount_quoted)
                        );
                    }, 0);
                    let finalDistance = waypointsStack.reduce(function (
                        prev,
                        cur
                    ) {
                        return prev + cur.estimate.distance_km;
                    },
                    0);
                    let finalTime = waypointsStack.reduce(function (prev, cur) {
                        return prev + cur.estimate.minutes;
                    }, 0);

                    setFinalEstimate({
                        finalDistance: finalDistance,
                        finalTime: finalTime,
                        finalCost: finalCost,
                    });

                    return pickupLocation;
                })
                .catch((err) => {
                    console.log("Getting Estimate error:", err);
                    debugger;
                });
        }
    };

    const signupApi = (cb) => {
        api.signUpApiAcess({
            app_name: "n/a",
            app_description: "n/a",
            api_token,
        }).then((result) => {
            cb(result);
        });
    };

    const handleWallet = (paystackRef) => {
        api.getApiAccess({ api_token }).then((result) => {
            if (
                result.id &&
                (result.balance < finalEstimate.finalCost ||
                    (finalEstimate.finalCost == 0 && result.balance == 0))
            ) {
                handleWalletTopup(paystackRef, result);
            } else if (
                result &&
                result.message &&
                result.message.includes("signup")
            ) {
                signupApi((result) => {
                    handleWalletTopup(paystackRef, result);
                });
            }
        });
    };

    const handleClose = () => {
        setPaymentOption("cash");
    };

    const config = {
        reference: new Date().getTime(),
        email: user.email || "customer@email.com",
        amount:
            (finalEstimate.finalCost == 0 ? 1000 : finalEstimate.finalCost) *
            100,
        publicKey: process.env.REACT_APP_PAYSTACK,
        // channels: ['card'],
    };

    const componentProps = {
        ...config,
        text: "Confirm",
        onSuccess: (response) => {
            validatePaystack(response);
        },
        onClose: handleClose,
    };

    const handleWalletTopup = (paystackRef, data) => {
        paystackRef();
    };

    const validatePaystack = (response) => {
        api.validatePaystackTopoff({
            reference: response.reference,
            api_token,
        }).then((result) => {
            getApiAccessDetails();
            confirmOrder();
        });
    };

    const confirmOrder = () => {
        // const api_token = localStorage.getItem('api_token');
        // Set the parameters for the the order by iterating through the
        // waypoints stack array and get each order's ish

        if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "disabled");
        }

        // let params = {
        //   pickup_address_details: pickupAddressDetails,
        //   pickup_name: pickupName,
        //   pickup_phone_number: pickupPhone,
        //   pickup_email: pickupEmail,
        //   pickup_date_time: moment(pickupSelectedDate).format(),
        //   total_waypoints: waypointsStack.length,
        //   payment_option: paymentOption,
        //   route_optimize: route_optimize,
        // };

        let params = {
            region_id,
            pickups: [
                {
                    address: pickupLocation.address,
                    latitude: pickupLocation.lat,
                    longitude: pickupLocation.lng,
                    customer_name: pickupName,
                    customer_email: pickupEmail,
                    customer_phone: pickupPhone,
                    category: waypointsStack[0]["order_category_id"].name,
                    pickup_datetime: moment(pickupSelectedDate).format(),
                },
            ],
            dropoffs: [],
            payment_mode: paymentOption,
            route_optimize: route_optimize,
        };

        for (let i = 0; i < waypointsStack.length; i++) {
            params["dropoffs"].push({
                address: waypointsStack[i].dropoffLocation.address,
                latitude: waypointsStack[i].dropoffLocation.lat,
                longitude: waypointsStack[i].dropoffLocation.lng,
                customer_name: waypointsStack[i]["recipientName"],
                customer_email: waypointsStack[i]["recipientEmail"],
                customer_phone: waypointsStack[i]["recipientPhone"],
                description: waypointsStack[i]["description"],
            });
            // params['waypoint_' + (i + 1) + '_order_id'] =
            //   waypointsStack[i]['estimate']['order_id'];
            // params['waypoint_' + (i + 1) + '_recipient_name'] =
            //   waypointsStack[i]['recipientName'];
            // params['waypoint_' + (i + 1) + '_email'] =
            //   waypointsStack[i]['recipientEmail'];
            // params['waypoint_' + (i + 1) + '_recipient_phone_number'] =
            //   waypointsStack[i]['recipientPhone'];
            // params['waypoint_' + (i + 1) + '_dropoff_address_details'] =
            //   waypointsStack[i]['dropoff_address_details'];
            // params['waypoint_' + (i + 1) + '_description'] =
            //   waypointsStack[i]['description'];
            // params['waypoint_' + (i + 1) + '_order_category_id'] =
            //   waypointsStack[i]['order_category_id'].id;
        }
        api.confirmOrderV3({ api_token, params })
            .then((result) => {
                if (result.error) {
                    if (result.message.includes("already confirmed")) {
                        confirmAlert({
                            title: "Confirm to submit",
                            message:
                                "This order has already been confirmed. Please find this order in your order history.",
                            buttons: [
                                {
                                    label: "Okay",
                                    onClick: () => {
                                        if (
                                            history.location.search.length >= 1
                                        ) {
                                            history.push(
                                                `/orders/${history.location.search}`
                                            );
                                        } else history.push("/orders");
                                    },
                                },
                            ],
                        });
                    } else if (result.error === "insufficient_funds") {
                        confirmAlert({
                            title: "Insufficient Funds",
                            message:
                                "There are not enough funds in your selected wallet. Would you like to select a different payment option or would you like to top-off your wallet balance?",
                            buttons: [
                                {
                                    label: "Change Payment Option",
                                    onClick: () => setPaymentOption(""),
                                },
                                {
                                    label: "Topoff Balance",
                                    onClick: () => {
                                        if (
                                            history.location.search.length >= 1
                                        ) {
                                            history.push(
                                                `/profile/api_access/${history.location.search}`
                                            );
                                        } else history.push("/profile/wallet");
                                    },
                                },
                            ],
                        });
                    } else if (result.message.includes("regular operation")) {
                        confirmAlert({
                            title: `Out Of Operation's Time Range`,
                            message: result.message,
                            buttons: [
                                {
                                    label: "Okay",
                                    onClick: () => setPaymentOption(""),
                                },
                            ],
                        });
                    } else {
                        if (btnRef.current) {
                            btnRef.current.removeAttribute("disabled");
                        }
                        confirmAlert({
                            title: "Error",
                            message: result.message,
                            buttons: [
                                {
                                    label: "Okay",
                                    onClick: () => {},
                                },
                            ],
                        });
                    }
                } else {
                    confirmAlert({
                        title: "Confirm to submit",
                        message:
                            "This order has been confirmed and there is a driver pending. Please find this order in your order history.",
                        buttons: [
                            {
                                label: "Okay",
                                onClick: () => {
                                    if (history.location.search.length >= 1) {
                                        history.push(
                                            `/orders/${result.order_id}/${history.location.search}`
                                        );
                                    } else
                                        history.push(
                                            `/orders/${result.order_id}`
                                        );
                                },
                            },
                        ],
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const customStyles = {
        container: (provided, state) => ({
            ...provided,

            width: "610px",
        }),
        control: (provided, state) => ({
            ...provided,

            height: "46px",
            marginTop: "10px",
            border: "solid 2px #00c795",
            borderRadius: "8px",
            fontFamily: "Inter",
            marginLeft: "10px",
        }),
        menu: (provided, state) => ({
            ...provided,
            fontFamily: "Inter",
            padding: 10,
        }),
    };

    return (
        <ViewContainer>
            <Header history={history} />

            <BodyContainer>
                {addressBookView && (
                    <WaypointContainer className={theme}>
                        <InnerWaypointContainer>
                            <div>
                                <Text
                                    fontSize={32}
                                    marginBottom={20}
                                    fontWeight={"bold"}
                                    onClick={() => {
                                        setAddressBookView(false);
                                        setTaskView(true);
                                    }}
                                >
                                    Saved Addresses
                                </Text>
                            </div>
                        </InnerWaypointContainer>
                    </WaypointContainer>
                )}

                {taskView && (
                    <WaypointContainer className={theme}>
                        <InnerWaypointContainer>
                            <div>
                                <Text
                                    fontSize={32}
                                    marginBottom={20}
                                    fontWeight={"bold"}
                                >
                                    New Task
                                </Text>
                            </div>

                            <Text
                                fontSize={12}
                                marginBottom={5}
                                color={"#ddd"}
                                fontWeight={"bold"}
                            >
                                * required fields
                            </Text>

                            {!openPickup && (
                                <PickupContainer
                                    onClick={() => setPickupOpen(!openPickup)}
                                >
                                    <Row
                                        marginTop={10}
                                        marginBottom={10}
                                        justifyContent={"space-between"}
                                    >
                                        <Row>
                                            <DashIcon
                                                name={"closed"}
                                                size={24}
                                                onClick={() =>
                                                    setPickupOpen(!openPickup)
                                                }
                                            />
                                            <Text
                                                marginLeft={16}
                                                fontSize={14}
                                                fontWeight={"bold"}
                                                cursor={"pointer"}
                                                onClick={() =>
                                                    setPickupOpen(!openPickup)
                                                }
                                                textTransform={"uppercase"}
                                            >
                                                Pickup
                                            </Text>
                                            {(() => {
                                                if (
                                                    pickupLocation.address
                                                        .length >= 1
                                                )
                                                    return (
                                                        <Text
                                                            marginLeft={16}
                                                            fontSize={14}
                                                            fontWeight={"bold"}
                                                            textTransform={
                                                                "uppercase"
                                                            }
                                                        >
                                                            {
                                                                pickupLocation.address
                                                            }
                                                        </Text>
                                                    );
                                            })()}
                                        </Row>

                                        {/* <Row>
                      <DashIcon
                        name={'addressbook'}
                        size={20}
                        onClick={() => {
                          setTaskView(false);
                          setAddressBookView(true);
                        }}
                      />
                      <Text
                        marginLeft={4}
                        fontSize={10}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={() => {
                          setTaskView(false);
                          setAddressBookView(true);
                        }}
                        textTransform={'uppercase'}
                      >
                        {' '}
                        Load Addresses
                      </Text>
                    </Row> */}
                                    </Row>
                                </PickupContainer>
                            )}

                            {openPickup && (
                                <PickupContainer>
                                    <input
                                        type={"hidden"}
                                        value={"something"}
                                    />

                                    <Row justifyContent={"space-between"}>
                                        <Row>
                                            <DashIcon
                                                name={"open"}
                                                size={24}
                                                onClick={() =>
                                                    setPickupOpen(!openPickup)
                                                }
                                            />
                                            <Text
                                                marginLeft={16}
                                                fontSize={14}
                                                fontWeight={"bold"}
                                                cursor={"pointer"}
                                                onClick={() =>
                                                    setPickupOpen(!openPickup)
                                                }
                                                textTransform={"uppercase"}
                                            >
                                                Pickup
                                            </Text>
                                        </Row>
                                        {/* 
                    <Row>
                      <DashIcon
                        name={'addressbook'}
                        size={20}
                        onClick={() => {
                          setTaskView(false);
                          setAddressBookView(true);
                        }}
                      />
                      <Text
                        marginLeft={4}
                        fontSize={10}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={() => {
                          setTaskView(false);
                          setAddressBookView(true);
                        }}
                        textTransform={'uppercase'}
                      >
                        {' '}
                        Load Addresses
                      </Text>
                    </Row> */}
                                    </Row>

                                    <Row>
                                        <DashIcon name={"location"} size={24} />
                                        <Select
                                            id="region"
                                            styles={customStyles}
                                            options={regions}
                                            menuPlacement="auto"
                                            className="region"
                                            placeholder="Choose Region *"
                                            onChange={(e) => {
                                                setRegionID(e.id);
                                                getEstimate();
                                            }}
                                        />
                                    </Row>

                                    <Row>
                                        <DashIcon name={"location"} size={24} />

                                        {!autocompleteResults && (
                                            <PickupInput
                                                id="pickupLocation"
                                                className="pickupLocation"
                                                name={pickupAddress}
                                                type="text"
                                                autocomplete="text"
                                                value={pickupAddress}
                                                onFocus={() => {
                                                    const api_token =
                                                        localStorage.getItem(
                                                            "api_token"
                                                        );
                                                    const splitApi =
                                                        api_token.substring(
                                                            0,
                                                            4
                                                        );
                                                    const sessionToken =
                                                        splitApi.concat(
                                                            Date.now()
                                                        );

                                                    localStorage.setItem(
                                                        "session",
                                                        sessionToken
                                                    );
                                                }}
                                                onBlur={() => {
                                                    localStorage.removeItem(
                                                        "session"
                                                    );
                                                }}
                                                onChange={(e) =>
                                                    handlePickupLocation(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder={"Pickup Location"}
                                                marginTop={10}
                                                marginLeft={10}
                                            />
                                        )}
                                        <Column
                                            width={"-webkit-fill-available"}
                                        >
                                            {autocompleteResults && (
                                                <PickupInput
                                                    type="text"
                                                    autocomplete="text"
                                                    id="pickupLocation"
                                                    className="pickupLocation"
                                                    name={pickupAddress}
                                                    value={pickupAddress}
                                                    onFocus={() => {
                                                        const api_token =
                                                            localStorage.getItem(
                                                                "api_token"
                                                            );
                                                        const splitApi =
                                                            api_token.substring(
                                                                0,
                                                                4
                                                            );
                                                        const sessionToken =
                                                            splitApi.concat(
                                                                Date.now()
                                                            );
                                                        localStorage.setItem(
                                                            "session",
                                                            sessionToken
                                                        );
                                                    }}
                                                    onBlur={() => {
                                                        localStorage.removeItem(
                                                            "session"
                                                        );
                                                    }}
                                                    onChange={(e) => {
                                                        setPickupAddress(
                                                            e.target.value
                                                        );
                                                        getPickupAutocompleteResult();
                                                    }}
                                                    placeholder={
                                                        "Pickup Location *"
                                                    }
                                                    marginTop={10}
                                                    marginLeft={10}
                                                />
                                            )}

                                            {autocompleteResults.length >=
                                                1 && (
                                                <MenuOptions
                                                    onMouseLeave={() =>
                                                        setAutocompleteResults(
                                                            []
                                                        )
                                                    }
                                                >
                                                    {autocompleteResults.map(
                                                        (res) => (
                                                            <MenuOption
                                                                onClick={() => {
                                                                    getCoords({
                                                                        pickUp: true,
                                                                        location_id:
                                                                            res.location_id,
                                                                        context:
                                                                            "pickup",
                                                                    });
                                                                    setAutocompleteResults(
                                                                        []
                                                                    );
                                                                }}
                                                            >
                                                                {res.address}
                                                            </MenuOption>
                                                        )
                                                    )}
                                                </MenuOptions>
                                            )}
                                        </Column>
                                    </Row>

                                    <Row>
                                        <DashIcon name={"name"} size={24} />
                                        <PickupInput
                                            type="text"
                                            autocomplete="text"
                                            id="pickupName"
                                            className="pickupName"
                                            name={pickupName}
                                            value={pickupName}
                                            onChange={(e) =>
                                                setPickupName(e.target.value)
                                            }
                                            placeholder="Pickup Recipient's Name *"
                                            marginTop={10}
                                            marginLeft={10}
                                            required={true}
                                        />
                                    </Row>
                                    {/* <Row>
                    <DashIcon name={'home'} size={24} />
                    <PickupInput
                      type="text"
                      autocomplete="text"
                      id="pickupAddressDetails"
                      className="pickupAddressDetails"
                      name={pickupAddressDetails}
                      value={pickupAddressDetails}
                      onChange={(e) => setPickupAddressDetails(e.target.value)}
                      placeholder="Pickup Address Details"
                      marginTop={10}
                      marginLeft={10}
                      required
                    />
                  </Row> */}
                                    <Row>
                                        <DashIcon name={"email"} size={24} />
                                        <PickupInput
                                            type="text"
                                            autocomplete="text"
                                            id="pickupEmail"
                                            className="pickupEmail"
                                            name={pickupEmail}
                                            value={pickupEmail}
                                            onChange={(e) =>
                                                setPickupEmail(e.target.value)
                                            }
                                            placeholder="Pickup Email *"
                                            marginTop={10}
                                            marginLeft={10}
                                            required={true}
                                        />
                                    </Row>
                                    <Row>
                                        <DashIcon name={"phone"} size={24} />
                                        <PhoneInput
                                            type="text"
                                            id="pickupPhone"
                                            className="pickupPhone"
                                            name={pickupPhone}
                                            autocomplete="chrome-off"
                                            value={pickupPhone}
                                            onChange={(e) =>
                                                setPickupPhone(e.target.value)
                                            }
                                            placeholder="Pickup Recipient's Phone Number *"
                                            marginTop={10}
                                            marginLeft={10}
                                            required
                                            marginBottom={10}
                                        />
                                    </Row>
                                    <Row>
                                        <DashIcon name={"name"} size={24} />

                                        <MuiPickersUtilsProvider
                                            utils={MomentUtils}
                                        >
                                            <DateTimePicker
                                                style={{
                                                    width: "600px",
                                                    height: "40px",
                                                    marginLeft: "10px",
                                                    marginBottom: "24px",
                                                }}
                                                value={pickupSelectedDate}
                                                disablePast
                                                inputVariant="outlined"
                                                onChange={
                                                    handlePickupDateChange
                                                }
                                                label="Select Pickup Date and Time"
                                                showTodayButton
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Row>
                                </PickupContainer>
                            )}

                            <WaypointsScollContainer>
                                {waypointsStack.map((val, idx) => {
                                    const distance = parseInt(
                                        waypointsStack[idx].estimate.distance_km
                                    );
                                    return (
                                        <Fragment key={`waypoint-${idx}`}>
                                            <BoxContainer>
                                                <input
                                                    type={"hidden"}
                                                    value={"something"}
                                                />
                                                <WaypointInputs
                                                    key={`waypoint-${idx}`}
                                                    idx={idx}
                                                    handleCategoryChange={
                                                        handleCategoryChange
                                                    }
                                                    index={idx}
                                                    distance={distance}
                                                    orderCategories={
                                                        orderCategories
                                                    }
                                                    waypointsStack={
                                                        waypointsStack
                                                    }
                                                    removeWaypoint={
                                                        removeWaypoint
                                                    }
                                                    mapsMarkersStack={
                                                        mapsMarkersStack
                                                    }
                                                    handleWaypointChange={
                                                        handleWaypointChange
                                                    }
                                                    getCoords={getCoords}
                                                />
                                            </BoxContainer>
                                        </Fragment>
                                    );
                                })}
                            </WaypointsScollContainer>

                            {(() => {
                                if (
                                    typeof waypointsStack[
                                        waypointsStack.length - 1
                                    ].estimate.amount_quoted === "number"
                                ) {
                                    if (waypointInputError === false) {
                                        return (
                                            <PickupContainer
                                                onClick={addWaypoint}
                                            >
                                                <Row
                                                    marginTop={10}
                                                    marginBottom={10}
                                                >
                                                    <DashIcon
                                                        name={"add"}
                                                        size={24}
                                                        onClick={addWaypoint}
                                                    />
                                                    <Text
                                                        marginLeft={16}
                                                        fontSize={14}
                                                        fontWeight={"bold"}
                                                        textTransform={
                                                            "uppercase"
                                                        }
                                                    >
                                                        Add new dropoff
                                                    </Text>
                                                </Row>
                                            </PickupContainer>
                                        );
                                    }
                                }
                            })()}
                        </InnerWaypointContainer>

                        <FooterContainer>
                            <div
                                className={theme}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingLeft: "8px",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                        textAlign: "right",
                                        margin: "8px 16px 0px 0px",
                                    }}
                                >
                                    <Text
                                        fontSize={16}
                                        color={"#00c795"}
                                        textAlign={"right"}
                                        fontWeight={800}
                                    >
                                        {` ₦ ${finalEstimate.finalCost}`}{" "}
                                    </Text>
                                    <Text
                                        fontSize={16}
                                        textAlign={"right"}
                                        fontWeight={800}
                                    >
                                        {`${finalEstimate.finalDistance.toFixed(
                                            2
                                        )} km | ${
                                            finalEstimate.finalTime
                                        } min`}{" "}
                                    </Text>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        margin: "0px 16px 10px 0px",
                                    }}
                                >
                                    <CheckboxLabel>
                                        <Checkbox
                                            type="checkbox"
                                            checked={checked}
                                            onChange={() => {
                                                setChecked(!checked);
                                                if (!checked) {
                                                    confirmAlert({
                                                        title: "Route Optimization",
                                                        message:
                                                            "By clicking on this, you've confirmed that your delivery dropoffs will be changed to maximize your savings.",
                                                        buttons: [
                                                            {
                                                                label: "Okay",
                                                                onClick: () => {
                                                                    setRouteOpt(
                                                                        1
                                                                    );

                                                                    setMsg(
                                                                        "gotcha"
                                                                    );
                                                                },
                                                            },
                                                        ],
                                                    });
                                                } else {
                                                    confirmAlert({
                                                        title: "Route Optimization",
                                                        message:
                                                            "By clicking on this, you've opted out of having your delivery route optimized.",
                                                        buttons: [
                                                            {
                                                                label: "Okay",
                                                                onClick: () => {
                                                                    setRouteOpt(
                                                                        0
                                                                    );

                                                                    setMsg(
                                                                        "gotcha"
                                                                    );
                                                                },
                                                            },
                                                        ],
                                                    });
                                                }
                                            }}
                                        />
                                        Route Optimization
                                    </CheckboxLabel>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    margin: "8px 16px 0px 0px",
                                }}
                            >
                                <PaymentButtonContainer className={theme}>
                                    <PaymentButton
                                        className={btn_class}
                                        width={180}
                                        marginRight={5}
                                        onClick={() => {
                                            changeColor();
                                            setPaymentOption("cash");
                                        }}
                                    >
                                        <Icon
                                            verticalAlign={"bottom"}
                                            margin={"inherit"}
                                            src={Cash}
                                        />
                                        Cash
                                    </PaymentButton>
                                    <PaystackConsumer {...componentProps}>
                                        {({ initializePayment }) => (
                                            <PaymentButton
                                                className={wallet_class}
                                                width={180}
                                                marginLeft={5}
                                                onClick={() => {
                                                    handleWallet(
                                                        initializePayment
                                                    );
                                                    changeCardColor();
                                                    setPaymentOption("wallet");
                                                }}
                                            >
                                                <Icon
                                                    verticalAlign={"bottom"}
                                                    margin={"inherit"}
                                                    marginRight={5}
                                                    src={Wallet}
                                                />
                                                Wallet ₦ {balance}
                                            </PaymentButton>
                                        )}
                                    </PaystackConsumer>
                                </PaymentButtonContainer>

                                <Button
                                    width={370}
                                    height={40}
                                    borderRadius={10}
                                    marginTop={10}
                                    marginBottom={10}
                                    ref={btnRef}
                                    onClick={() => {
                                        if (paymentOption.length <= 1) {
                                            confirmAlert({
                                                title: "Missing Payment Option",
                                                message:
                                                    "Please select a payment option.",
                                                buttons: [
                                                    {
                                                        label: "Okay",
                                                        onClick: () =>
                                                            setPaymentOption(
                                                                ""
                                                            ),
                                                    },
                                                ],
                                            });
                                        } else {
                                            confirmOrder();
                                        }
                                    }}
                                >
                                    Request Delivery
                                </Button>
                            </div>
                        </FooterContainer>
                    </WaypointContainer>
                )}

                <MapContainer className="desktop-only">
                    <Mapbox
                        lat={center.lat}
                        lng={center.lng}
                        mapsMarkersStack={mapsMarkersStack}
                        center={[center.lat, center.lng]}
                    />
                </MapContainer>
            </BodyContainer>
        </ViewContainer>
    );
};

export default Dashboard;

// const PickupContainer = styled.div`
//   width: 440px;

//   box-shadow: inset 0px -1px 0px #e1e4eb;
// `;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
`;
// height: 100%;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const MapContainer = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
}
`;

const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: inset 0px -1px 0px #e1e4eb;
`;

const PaymentButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const WaypointsScollContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: min-content;
`;

const PaymentButton = styled.button`
    ${(props) => (props.width ? `width:${props.width}px` : "inherit")};
    height: ${(props) => props.height || 40}px;
    border-radius: ${(props) => props.borderRadius || 8}px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${(props) => props.fontSize || 14}px;
    font-family: Inter;
    font-weight: ${(props) => props.fontWeight || 900};
    background-color: transparent;
    ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}px;` : "")}
    ${(props) =>
        props.marginLeft ? `margin-left: ${props.marginLeft}px;` : ""}
    ${(props) =>
        props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : ""}
    ${(props) =>
        props.marginRight ? `margin-right: ${props.marginRight}px;` : ""};
`;

const ViewContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
`;

const FooterContainer = styled.div`
    bottom: -10px;
    color: #000;
    padding-bottom: 15px;
    position: fixed;
    padding-top: 10px;
    border-top: 1px solid #e1e4eb;

    flex-direction: row;
    width: 50%;
    justify-content: flex-end;
    display: flex;
`;

const WaypointContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.118854);
`;

const InnerWaypointContainer = styled.div`
    display: flex;
    flex-direction: column;

    padding: 40px 40px 0px 40px;
    height: calc(100% - 250px);
    overflow-y: scroll;
`;
// height: fit-content;

const PickupContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: min-content;
    box-shadow: inset 0 -1px 0 0 #e1e2e6;
`;
// width: 580px;
const PickupInput = styled.input`
    width: -webkit-fill-available;
    max-width: 580px;
    height: 40px;
    border-radius: 8px;
    border: solid 2px #00c795;
    font-family: "Inter";
    text-align: left;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginTop ? `margin-top:${props.marginTop}px` : "10px"};
    ${(props) =>
        props.marginLeft ? `margin-left:${props.marginLeft}px` : "10px"};
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) =>
        props.justifyContent ? `justify-content:${props.justifyContent}` : ""};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    ${(props) => (props.width ? `width:${props.width}` : "")};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

const PhoneInput = styled.input`
    width: 530px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: solid 2px #00c795;
    border-radius: 8px;
    font-size: 14px;
    font-family: "Inter";
    text-align: left;
    padding-left: 60px;
    padding-right: 10px;
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
    background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
    background-position: left;
    background-size: 50px;
    background-repeat: no-repeat;
`;

const MenuOptions = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 240px;
    align-items: center;
    border: 1px solid #e1e4eb;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.16);
    overflow-y: scroll;
`;

const MenuOption = styled.a`
    width: 580px;
    box-shadow: inset 0px -1px 0px #e1e4eb;
    min-height: 50px;
    overflow-wrap: anywhere;
    font-family: "Inter";
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    ${(props) => (props.bottom ? "bottom: 24px;" : "")}
    ${(props) => (props.bottom ? "position: absolute;" : "")}
`;

const Checkbox = styled.input`
    margin: 0px 10px 0px !important;
    border: solid 2px #00c795;

    cursor: pointer;
`;
const CheckboxLabel = styled.label`
    cursor: pointer;
    display: block;
    font-weight: bold;
    font-size: 16px;
    font-family: "Inter";
`;
