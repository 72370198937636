import React, { useState } from 'react';
import GlobalFonts from './fonts/font';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './components/themes/globalStyles';
import { lightTheme, darkTheme } from './components/themes/Themes';

import './App.css';

import Dashboard from './pages/dashboard/Dashboard';
import Orders from './pages/orders/Orders';
import New from './pages/orders/New';
import OrderDetails from './pages/order_details/OrderDetails';
import OrderDetailsV1 from './pages/order_details_v1/OrderDetails';
import Verification from './pages/verify/Verication';
import Register from './pages/verify/Register';
import HomePage from './pages/home/Home';
import Setting from './pages/settings/Setting';
import ApiAccess from './pages/api_access/ApiAccess';
import Wallet from './pages/wallet/Wallet';
import SubAccount from './pages/sub_accounts/SubAccounts';
import Tools from './pages/tools/Tools';
import Profile from './pages/profile/Profile';
import LandingPage from './pages/landing_page/LandingPage';
import {
  Switch,
  Route,
  Redirect,
  HashRouter as Router,
} from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import ResetPassword from './pages/reset_password/ResetPassword';
import { MenuContext } from './MenuContext';

function App() {
  const [sidemenu, setSideMenu] = useState(false);
  // if (window.location.protocol !== 'https:') {
  //   window.location.replace(
  //     `https:${window.location.href.substring(window.location.protocol.length)}`
  //   );
  // }

  return (
    <MenuContext.Provider value={{sidemenu, setSideMenu}}>
      <Router>
        <GlobalFonts />

        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/dashboard" component={HomePage} />
          <Route exact path="/profile" component={HomePage} />
          <Route path="/wallet" component={Wallet} />
          <Route path="/login" component={Verification} />
          <Route path="/register" component={Register} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route exact path="/orders" component={Orders} />
          <Route exact path="/new" component={New} />
          <Route exact path="/tools" component={Tools} />
          <Route exact path="/profile/accounts" component={SubAccount} />
          <Route exact path="/settings" component={Setting} />

          <Route
            path="/orders/v1/:_id"
            render={(props) => <OrderDetailsV1 {...props} />}
          />
          <Route
            path="/orders/:_id"
            render={(props) => <OrderDetails {...props} />}
          />
        </Switch>
      </Router>
    </MenuContext.Provider>
  );
}

export default App;
