import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { usePaystackPayment, PaystackConsumer } from "react-paystack";

import * as api from "../../api/index";

import FreshChat from "react-freshchat";
import Text from "../../components/molecules/text";
import Button from "../../components/molecules/button";
import Header from "../../components/organisms/header/index";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const ApiAccess = ({ history, match }) => {
    const theme = localStorage.getItem("theme") + "-mode";
    let api_token;
    const field = "user_id";
    const url = window.location.href;
    if (url.includes("?" + field + "=")) {
        const index = url.indexOf("=") + 1;
        const userId = url.slice(index, url.length);
        api_token = window.localStorage.getItem(`${userId}`);
    } else {
        api_token = localStorage.getItem("api_token");
    }

    if (!api_token) {
        history.push("/login");
    } else if (typeof api_token === "object") {
        if (api_token.message.includes("invalid")) {
            localStorage.clear();
            history.push("/login");
        }
    }

    const [user, setUser] = useState({});
    const [apiAccessBalance, setApiAccessBalance] = useState(false);
    const [apiAccessTopOff, setApiAccessTopOff] = useState(false);
    const [apiAccessDetails, setApiAccessDetails] = useState({});
    const [apiForm, setApiForm] = useState(false);
    const [appName, setAppName] = useState("");
    const [msg, setMsg] = useState("");
    const [appDescription, setAppDescription] = useState("");
    const [topOffAmount, setTopOffAmount] = useState("");
    const [webhookURL, setWebhookURL] = useState("");

    const getUser = () => {
        api.getUserDetails({
            api_token,
        }).then((result) => {
            if (result.error === "invalid_api_key") {
                localStorage.removeItem("api_token");
                history.push("/login");
            } else {
                setUser(result);
            }
        });
    };

    const getApiAccessDetails = () => {
        if (webhookURL) {
            const status_webhook_url = `${webhookURL}`;
            api.getApiAccess({
                api_token,
                status_webhook_url: status_webhook_url,
            }).then((result) => {
                console.log("ggetApiAccesset", result);
                if (result.id) {
                    setApiAccessDetails(result);
                    setWebhookURL(result.status_webhook_url);
                    setApiForm(false);
                    setApiAccessBalance(true);
                } else if (result.message.includes("signup")) {
                    setApiForm(true);
                    setApiAccessBalance(false);
                }
            });
        } else {
            api.getApiAccess({ api_token }).then((result) => {
                console.log("getApiAccesse", result);
                if (result.id) {
                    setApiAccessDetails(result);
                    setApiForm(false);
                    setWebhookURL(result.status_webhook_url);
                    setApiAccessBalance(true);
                } else if (result.message.includes("signup")) {
                    setApiForm(true);
                    setApiAccessBalance(false);
                }
            });
        }
    };

    const config = {
        reference: new Date().getTime(),
        email: user.email || "customer@email.com",
        amount: topOffAmount * 100,
        publicKey: process.env.REACT_APP_PAYSTACK,
        channels: ["card"],
    };
    // process.env.REACT_APP_PAYSTACK 'pk_test_13c6764234ac3499067d18546edb24312301c05c',
    const componentProps = {
        ...config,
        text: "Confirm",
        onSuccess: (response) => {
            validatePaystack(response);
        },
    };

    useEffect(() => {
        getUser();
        getApiAccessDetails();
    }, []);

    const signupApi = () => {
        if (appDescription.length <= 1 || appName.length <= 1) {
            confirmAlert({
                title: "App Name / Description Required",
                message: "Please put in an app name or an app description",
                buttons: [
                    {
                        label: "Okay",
                        onClick: () => {
                            setMsg("hello");
                        },
                    },
                ],
            });
        } else {
            api.signUpApiAcess({
                app_name: appName,
                app_description: appDescription,
                api_token,
            }).then((result) => {
                console.log("signup Api Access", result);
                setApiAccessDetails(result);
                setApiForm(false);
                setApiAccessBalance(true);
            });
        }
    };

    const validatePaystack = (response) => {
        api.validatePaystackTopoff({
            reference: response.reference,
            api_token,
        }).then((result) => {
            console.log("validatePaystackTopoff", result);
            setApiAccessTopOff(false);
            getApiAccessDetails();
        });
    };

    const updateWebhook = () => {
        api.updateDevAcount({
            webhookUrl: webhookURL,
            api_token,
        }).then((result) => {
            console.log("updateDevAccount", result);
            if (result.success === true) {
                confirmAlert({
                    title: "Webhook URL Added",
                    message: "Adding your Webhook URL was succesful.",
                    buttons: [
                        {
                            label: "Okay",
                            onClick: () => {
                                getApiAccessDetails();
                            },
                        },
                    ],
                });
            } else if (result.status === false) {
                confirmAlert({
                    title: "Error Adding Webhook URL",
                    message:
                        "There was an error adding your Webhook URL. Please try again",
                    buttons: [
                        {
                            label: "Okay",
                            onClick: () => setAppName(""),
                        },
                    ],
                });
            }
        });
    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            setWebhookURL("");
            updateWebhook();
        }
    };

    const renderBody = () => {
        return (
            apiAccessDetails &&
            apiAccessDetails.transactions.map(
                ({ id, created_at, amount, type, description }) => {
                    // let created = getTime({ timestamp: pickup_datetime });
                    return (
                        <tr
                            key={id}
                            style={{
                                height: "80px",
                                boxShadow: "inset 0 -1px 0 0 #e1e2e6",
                            }}
                        >
                            <td
                                style={{
                                    marginRight: "90px",
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "1.43",
                                }}
                            >
                                {id}
                            </td>
                            <td
                                style={{
                                    marginRight: "90px",
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "1.43",

                                    textTransform: "capitalize",
                                }}
                            >
                                {created_at}
                            </td>
                            <td
                                style={{
                                    marginRight: "90px",
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "1.43",
                                }}
                            >
                                {type}
                            </td>
                            <td
                                style={{
                                    marginRight: "90px",
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "1.43",
                                }}
                            >
                                {amount}
                            </td>

                            <td
                                style={{
                                    marginRight: "90px",
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "1.43",
                                }}
                            >
                                {description}
                            </td>
                        </tr>
                    );
                }
            )
        );
    };

    const renderHeader = () => {
        let headerElement = ["id", "date", "type", "amount", "description"];

        return headerElement.map((key, index) => {
            return (
                <th
                    style={{
                        paddingRight: "80px",
                    }}
                    key={index}
                >
                    {key.toUpperCase()}
                </th>
            );
        });
    };

    return (
        <ViewContainer className={theme}>
            <Header history={history} />
            {apiAccessBalance && (
                <BodyDiv>
                    <div style={{ height: "100px" }}>
                        <Text
                            fontSize={32}
                            marginTop={40}
                            marginBottom={40}
                            fontWeight={"bold"}
                        >
                            API Access
                        </Text>
                    </div>

                    <div
                        style={{
                            height: "100px",
                            boxShadow: "inset 0 -1px 0 0 #e1e2e6",
                        }}
                    >
                        <Text fontSize={14} marginTop={40} marginBottom={40}>
                            You can place an order straight from your website or
                            e-commerce platform. Access the complete{" "}
                            <a
                                href="https://gokada2.docs.apiary.io/#"
                                target="_blank"
                            >
                                documentation
                            </a>{" "}
                            and get started.
                        </Text>
                    </div>

                    <Fragment>
                        <ApiKeyContainer height={100}>
                            <Text
                                marginTop={20}
                                fontSize={14}
                                color={"#a1a7b3"}
                                fontWeight={"bold"}
                            >
                                API LIVE KEY
                            </Text>

                            <Row>
                                {(() => {
                                    if (apiAccessDetails.api_key) {
                                        return (
                                            <Text
                                                marginTop={20}
                                                textAlign={"left"}
                                                fontSize={14}
                                            >
                                                API Live Key:{" "}
                                                {apiAccessDetails.api_key}
                                            </Text>
                                        );
                                    } else {
                                        return (
                                            <Text
                                                textAlign={"center"}
                                                fontSize={14}
                                                marginTop={20}
                                            >
                                                API Live Key: To be given
                                            </Text>
                                        );
                                    }
                                })()}
                            </Row>
                        </ApiKeyContainer>

                        <ApiKeyContainer height={100}>
                            <Text
                                marginTop={20}
                                fontSize={14}
                                color={"#a1a7b3"}
                                fontWeight={"bold"}
                            >
                                API TEST KEY
                            </Text>

                            <Row>
                                {(() => {
                                    if (apiAccessDetails.test_api_key) {
                                        return (
                                            <Text
                                                marginTop={20}
                                                textAlign={"left"}
                                                fontSize={14}
                                            >
                                                API Test Key:{" "}
                                                {apiAccessDetails.test_api_key}
                                            </Text>
                                        );
                                    } else {
                                        return (
                                            <Text
                                                marginTop={20}
                                                textAlign={"center"}
                                                fontSize={14}
                                            >
                                                API Test Key: To be given
                                            </Text>
                                        );
                                    }
                                })()}
                            </Row>
                        </ApiKeyContainer>

                        <ApiKeyContainer height={140}>
                            <Text
                                marginTop={20}
                                fontSize={14}
                                color={"#a1a7b3"}
                                fontWeight={"bold"}
                            >
                                WEBHOOK URL
                            </Text>
                            <Text fontSize={14} fontWeight={"bold"}>
                                Please provide URL where you would like us to
                                send your order updates.
                            </Text>

                            <Row marginBottom={20} marginTop={20}>
                                <WebhookInput
                                    width={800}
                                    placeholder={webhookURL || "Webhook URL"}
                                    onKeyPress={handleKeyPress}
                                    value={webhookURL}
                                    onChange={(e) => {
                                        setWebhookURL(e.target.value);
                                    }}
                                />
                                <Button
                                    width={120}
                                    height={40}
                                    borderRadius={10}
                                    onClick={() => {
                                        updateWebhook();
                                    }}
                                >
                                    Update
                                </Button>
                            </Row>
                        </ApiKeyContainer>
                    </Fragment>
                </BodyDiv>
            )}

            {apiForm && (
                <BodyDiv width={660}>
                    <div style={{ height: "110px" }}>
                        <Text
                            fontSize={32}
                            marginTop={40}
                            marginBottom={40}
                            fontWeight={"bold"}
                        >
                            API Access Form
                        </Text>
                    </div>
                    <VerifyForm>
                        <Text
                            marginTop={20}
                            fontSize={14}
                            color={"#a1a7b3"}
                            fontWeight={"bold"}
                        >
                            App Information
                        </Text>
                        <Text fontSize={14} fontWeight={"bold"}>
                            Please provide the following information about
                            application:
                        </Text>
                        <WebhookInput
                            placeholder="App Name"
                            width={460}
                            marginTop={20}
                            border={"solid 2px #e1e2e6"}
                            value={appName}
                            onChange={(e) => {
                                setAppName(e.target.value);
                            }}
                        />
                        <WebhookInput
                            placeholder="App Description"
                            width={460}
                            border={"solid 2px #e1e2e6"}
                            marginTop={15}
                            value={appDescription}
                            onChange={(e) => {
                                setAppDescription(e.target.value);
                            }}
                        />{" "}
                        <Row marginTop={20} justifyContent={"center"}>
                            <Button
                                width={140}
                                height={40}
                                backgroundColor={"light-gray"}
                                color={"dark-gray"}
                                borderRadius={10}
                                onClick={() => history.goBack()}
                            >
                                Cancel
                            </Button>
                            <Button
                                marginLeft={8}
                                width={100}
                                height={40}
                                borderRadius={8}
                                onClick={signupApi}
                            >
                                Signup
                            </Button>
                        </Row>
                    </VerifyForm>
                </BodyDiv>
            )}
        </ViewContainer>
    );
};

export default ApiAccess;

const ViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100vw;
`;

const BodyDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0px 250px 20px 250px;
    ${(props) => (props.width ? `width:${props.width}px` : "940px")};
`;

const ApiKeyContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 -1px 0 0 #e1e2e6;
    width: 100%;
    margin-bottom: 20px;
    ${(props) => (props.height ? `height:${props.height}px` : "120px")};
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
    ${(props) =>
        props.justifyContent ? `justify-content:${props.justifyContent}` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) =>
        props.overFlowWrap ? `overflow-wrap:${props.overFlowWrap}` : ""};
`;

const BalanceContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
`;
const TransactionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
`;
const WebhookInput = styled.input`
    top: 416px;
    left: 308px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font-size: 16px;
    font-family: "Inter";
    text-align: left;
    padding-left: 10px;
    border: solid 2px #e1e2e6;
    margin-right: 20px;
    padding-right: 10px;
    ${(props) => (props.width ? `width:${props.width}px` : "800px")};
    ${(props) =>
        props.border ? `border:${props.border}` : "solid 2px #e1e2e6"};
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

const VerifyForm = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;
const Input = styled.input`
    top: 416px;
    left: 308px;
    width: 384px;
    height: 50px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #cccccc;
    border-radius: 10px;
    font-size: 16px;
    font-family: "Inter";
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;
