/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';

import * as api from '../../api/index';
import FreshChat from 'react-freshchat';
import Text from '../../components/molecules/text';
import Button from '../../components/molecules/button';
import AddressSearch from '../../components/molecules/address-search/AddressSearch';
import MapControl from '../../components/molecules/map-control/MapControl';
import OrderForm from '../../components/molecules/setting-form/OrderForm';
import Config from '../../Config';
import Header from '../../components/organisms/header/index';
import SideMenu from '../../components/organisms/menu/index';
import { confirmAlert } from 'react-confirm-alert';
import AreaCode from '../../assets/icons/pngs/areaCode.png';
import Loading from '../../assets/loading.gif';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useMediaQuery } from 'react-responsive';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: transparent;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100vw;
  height: 100vh;
`;

const MenuOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 64px;
  padding-right: 64px;
`;

const MenuOption = styled.a`
width: 95%
justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: 'Inter';
  font-weight: 400;
 font-size: 18px;
 cursor: pointer;
  ${(props) => (props.fontColor ? `color: ${props.fontColor}` : '#0e0e0f')};
  ${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight}` : '')};
  cursor: pointer;
  display: flex;
  ${(props) => (props.bottom ? 'bottom: 24px;' : '')}
  ${(props) => (props.bottom ? 'position: absolute;' : '')}
  border-top: 1px solid rgb(227, 229, 232);
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
`;

const Row = styled.div`
  margin-top: 22px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;

  width: 100%;
`;
const PhoneInput = styled.input`
  top: 416px;
  left: 308px;
  width: 340px;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e1e4eb;
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Inter';
  text-align: left;
  padding-left: 60px;
  padding-right: 10px;
  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
  background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
  background-position: left;
  background-size: 60px;
  background-repeat: no-repeat;
`;
const Input = styled.input`
  top: 416px;
  left: 308px;
  width: 384px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #cccccc;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'Inter';
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;

  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
`;

const VerifyForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
`;

const New = ({ history, match }) => {
  const theme = localStorage.getItem('theme') + '-mode';
  let api_token;
  let secondaryUser;
  const field = 'user_id';
  const url = window.location.href;
  if (url.includes('?' + field + '=')) {
    const index = url.indexOf('=') + 1;
    const userId = url.slice(index, url.length);
    secondaryUser = true;
    api_token = window.localStorage.getItem(`${userId}`);
  } else {
    api_token = localStorage.getItem('api_token');
  }

  if (!api_token) {
    console.log('dash was hit');
    // debugger;
    history.push('/login');
  } else if (typeof api_token === 'object') {
    if (api_token.message.includes('invalid')) {
      localStorage.clear();
      history.push('/login');
    }
  }

  const [user, setUser] = useState(null);
  const [subaccounts, setSubAccounts] = useState(false);
  const [addSubAccount, setSubAccount] = useState(false);
  const [settingsMenu, setSettingsMenu] = useState(true);
  const [mapAddress, setMapAddress] = useState([6.4478,3.4723]);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [coords, setCoords] = useState([6.4478,3.4723]);

  const getSubaccounts = () => {
    api.getApiAccess({ api_token }).then((result) => {
      // debugger;
      if (result) {
        console.log('getSubAccounts', result);
        setSubAccounts(result.sub_accounts);
      }
    });
  };

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });

  const getUser = () => {
    api
      .getUserDetails({
        api_token,
      })
      .then((result) => {
        // debugger;
        if (result.error === 'invalid_api_key') {
          console.log('this is from sub account');
          localStorage.removeItem('api_token');
          history.push('/login');
        } else {
          setUser(result);

          let business = result.business;
          if(business !== null && 'address_lat' in business) {
            setMapAddress([business.address_lat,business.address_lng]);
          }
        }
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  const logoutUser = () => {
    const field = 'user_id';
    const url = window.location.href;
    if (url.includes('?' + field + '=')) {
      const index = url.indexOf('=') + 1;
      const userId = url.slice(index, url.length);
      localStorage.removeItem(userId);
    } else {
      localStorage.removeItem('api_token');
    }

    history.push('/login');
  };

  return (
    <div className={theme}>
      <OuterContainer>
        <Header history={history} />
        <div style={{display: 'flex',width: '100%'}}>
          {!isTabletOrMobileDevice && <SideMenu history={history} />}
          <div className="main-content">
            <div className="inner-box">
              <div className="top-box left">
                <div className="back"
                  onClick={() => {
                      window.history.back();
                  }}
                >
                  Back to Orders
                </div>
              </div>
              <h1>New Order</h1>

              <div className="setting-group">
                {user && <OrderForm
                  api_token={api_token}
                  addressChanged = {(data) => {
                    setMapAddress([data.lat,data.lng]);
                    setCoords([data.lat,data.lng]);
                  }}
                  coords={coords}
                  user={user}
                  />}
                <div className='order-map-container'>
                  {user && mapAddress && <MapControl 
                    latitude={coords[0] ? coords[0] : mapAddress[0]} 
                    longitude={coords[1] ? coords[1] : mapAddress[1]}
                    draggable={true}
                    changeLocation={(data) => {
                      // console.log('my dd', data)
                      setCoords([data.lat,data.lng]);
                    }}
                  />}
                </div>
                {!user && (
                  <div style={{textAlign: 'center',width: '100%'}}>
                    <img src={Loading} width="100" height="100" />
                  </div>
                )}
              </div>


            </div>
          </div>
        </div>
      </OuterContainer>
    </div>
  );
};

const LoginInput = styled.input`
    width: 100%;
    height: 44px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #00c795;
    border-radius: 10px;
    font-size: 14px;
    font-family: "Inter";
    background: #F8F9FB;
    border: 1px solid #BCC1CC;
    border-radius: 8px;

    // background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
    // background-position: left;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    background-size: 60px;
    background-repeat: no-repeat;
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

export default New;
