import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  width: ${(props) => props.width || 50}px;
  height: ${(props) => props.height || 50}px;
  border-radius: ${(props) => props.borderRadius || 8}px;
  border: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: ${(props) => props.fontSize || 14}px;
  font-family: Inter;
  font-weight: 700;
  color: ${(props) => props.color || 'white'};
  background-color: ${(props) => props.backgroundColor || '#00c795'};
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}px;` : '')}
  ${(props) => (props.marginLeft ? `margin-left: ${props.marginLeft}px;` : '')}
    ${(props) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : ''}
    ${(props) =>
    props.marginRight ? `margin-right: ${props.marginRight}px;` : ''};
`;

export default Button;
