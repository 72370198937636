import React from 'react';
import styled from 'styled-components';

import Icon from '../../molecules/icon/index';
import Text from '../../molecules/text/index';

import GokadaLogo from '../../../assets/icons/pngs/gokada-logo/gLogo@3x.png';
import Instagram from '../../../assets/icons/pngs/Instagram.png';
import Facebook from '../../../assets/icons/pngs/Facebook.png';
import Twitter from '../../../assets/icons/pngs/twitter.png';

const FooterContainer = styled.div`
  max-height: 80px;
  width: 100%;
  bottom: 0;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: #f5f7fa;
  border-top: 1px solid black;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  grid-area: ${(props) => props.gridArea};
`;

const CopyrightContainer = styled.div`
  display: flex;
  background-color: #f5f7fa;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding-left: 5%;
`;

const DownloadContainer = styled.div`
  display: flex;
  background-color: #f5f7fa;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 5%;
`;

const Footer = ({ ...props }) => {
  return (
    <FooterContainer {...props}>
      <CopyrightContainer>
        <Icon src={GokadaLogo} height={40} width={40} />
        <Text marginLeft={10} fontSize={10} fontWeight={500}>
          Copyright 2020 Gokada. All rights reserved.
        </Text>
      </CopyrightContainer>

      <DownloadContainer>
        <Icon
          src={Facebook}
          height={40}
          cursor={'pointer'}
          width={40}
          onClick={() => window.open('https://www.facebook.com/gokadang/')}
        />
        <Icon
          src={Twitter}
          marginLeft={20}
          cursor={'pointer'}
          height={40}
          width={40}
          onClick={() => window.open('https://www.twitter.com/gokadang/')}
        />
        <Icon
          src={Instagram}
          cursor={'pointer'}
          marginLeft={20}
          height={40}
          width={40}
          onClick={() => window.open('https://www.instagram.com/gokadang/')}
        />
      </DownloadContainer>
    </FooterContainer>
  );
};

export default Footer;
