module.exports = {
  // BASE_URL: 'http://gokada.del/',
  REACT_APP_MAPBOX: 'pk.eyJ1IjoiZ29rYWRhIiwiYSI6ImNqdWpzaW53ZjFpZnE0M3BneWRjbGdiYTkifQ.xDUr4FsMXXUZIoGVV_xG5w',
  BASE_URL: 'https://love.gokada.ng/'
//   BASE_URL: 'https://winchester.gokada.ng/',
  // BASE_URL: 'http://ec2-52-91-247-70.compute-1.amazonaws.com/',
//   BASE_URL: 'http://gokada.local:8000/',
  // STAGING_URL: 'http://ec2-52-91-247-70.compute-1.amazonaws.com/nova/',
};

// BASE_URL: 'http://ec2-52-91-247-70.compute-1.amazonaws.com/ nova/', for staging
// BASE_URL: 'https://love.gokada.ng/', for production
