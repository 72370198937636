import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';

import * as api from '../../../api/index';
import FreshChat from 'react-freshchat';
import Text from '../../../components/molecules/text';
import Button from '../../../components/molecules/button';
import AddressSearch from '../../../components/molecules/address-search/AddressSearch';
import MapControl from '../../../components/molecules/map-control/MapControl';
import Config from "../../../Config";
import Header from '../../../components/organisms/header/index';
import SideMenu from '../../../components/organisms/menu/index';
import { confirmAlert } from 'react-confirm-alert';
import AreaCode from '../../../assets/icons/pngs/areaCode.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from "axios";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: transparent;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100vw;
  height: 100vh;
`;

const MenuOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 64px;
  padding-right: 64px;
`;

const MenuOption = styled.a`
width: 95%
justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: 'Inter';
  font-weight: 400;
 font-size: 18px;
 cursor: pointer;
  ${(props) => (props.fontColor ? `color: ${props.fontColor}` : '#0e0e0f')};
  ${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight}` : '')};
  cursor: pointer;
  display: flex;
  ${(props) => (props.bottom ? 'bottom: 24px;' : '')}
  ${(props) => (props.bottom ? 'position: absolute;' : '')}
  border-top: 1px solid rgb(227, 229, 232);
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
`;

const Row = styled.div`
  margin-top: 22px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;

  width: 100%;
`;
const PhoneInput = styled.input`
  top: 416px;
  left: 308px;
  width: 340px;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e1e4eb;
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Inter';
  text-align: left;
  padding-left: 60px;
  padding-right: 10px;
  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
  background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
  background-position: left;
  background-size: 60px;
  background-repeat: no-repeat;
`;
const Input = styled.input`
  top: 416px;
  left: 308px;
  width: 384px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #cccccc;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'Inter';
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;

  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
`;

const VerifyForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
`;

const BulkForm = ({ ...props }) => {
  const api_token = props.api_token;
  const theme = localStorage.getItem('theme') + '-mode';
  const [name, setName] = useState(props.entry ? props.entry['delivery_name'] : '');
  const [phone, setPhone] = useState(props.entry ? props.entry['delivery_phone'] : '');
  const [email, setEmail] = useState(props.entry ? props.entry['delivery_email'] : '');
  const [address, setAddress] = useState(props.entry ? {'address': props.entry['delivery_address'], 'lat': props.entry['delivery_latitude'], 'lng': props.entry['delivery_longitude']} : {'address':''});
  const [loaded, setLoaded] = useState(false);

  let cb2 = props.addressChanged;
  let cb3 = props.nextButton;
  let cb4 = props.prevButton;
  let cb5 = props.estButton;
  let cb6 = props.entryChanged;

  let payload = props.entry;

  useEffect(() => {
    setName(props.entry['delivery_name']);
    setPhone(props.entry['delivery_phone']);
    setEmail(props.entry['delivery_email']);
    setAddress({ 'address': props.entry['delivery_address'],'lat': props.entry['delivery_latitude'], 'lng': props.entry['delivery_longitude'] });
  }, [props.entry]);

  useEffect(() => {
    console.log(address);
      payload['delivery_name'] = name;
      payload['delivery_phone'] = phone;
      payload['delivery_email'] = email;
      payload['delivery_address'] = address.address;
      payload['delivery_latitude'] = address.lat;
      payload['delivery_longitude'] = address.lng;
      cb6(payload);
  }, [name,phone,email,address]);


  let placeOrder = function() {
    let data = {
      'dropoff_name': name,
      'dropoff_phone': phone ? phone : '',
      'dropoff_email': email,
      'dropoff_address': address ? address.address : '',
      'dropoff_lat': address ? address.lat : '',
      'dropoff_lng': address ? address.lng : ''
    }

    const headers = {
        Authorization: "Bearer " + api_token,
    };

    axios
      .post(Config.BASE_URL + "api/v1/user/order", data, {
          headers: headers,
      })
      .then((res) => {
        window.history.back();
      })
      .catch(function (error) {
        alert(error.response.data.message);
      });
  }

  return (
    <div className="setting-form order">

                <h3 className="order">Dropoff Details</h3>
                  
                  <div className="setting-row">
                    <div className="setting-element">
                      <span className="login-label">Recipient's Name</span>
                      <LoginInput
                          placeholder="Recipient Name"
                          marginTop={5}
                          value={name}
                          required
                          onChange={(e) => {
                            setName(e.target.value);
                            setLoaded(true);
                          }}
                          type={"text"}
                      />

                    </div>
                    <div className="setting-element">
                      <span className="login-label">Phone Number</span>
                      <LoginInput
                          placeholder="Phone Number"
                          marginTop={5}
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            setLoaded(true);
                          }}
                          type={"email"}
                      />
                    </div>
                  </div>

                  <div className="setting-row">
                    <div className="setting-element">
                      <span className="login-label">Email</span>

                      <LoginInput
                          placeholder="Email"
                          marginTop={5}
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setLoaded(true);
                          }}
                          type={"email"}
                      />

                    </div>
                  </div>

                  <div className="setting-row">
                    <div className="setting-element">
                      <span className="login-label">Delivery Address</span>
                      <AddressSearch
                      key={Math.random()}
                      defaultAddress={address ? address.address : null}
                      token={props.api_token}
                      parentCallback={(data) => {
                        setAddress(data);
                        setLoaded(true);
                        cb2(data);
                      }}
                      />
                    </div>
                  </div>


                <div className="footer-buttons">
                  <button className="prev-button" onClick={() => {
                    cb4(payload);
                  }}>
                    Previous
                  </button>

                  { 
                    (props.index + 1 >= props.entries.length) ? (
                        <button className="est-button" onClick={() => {
                          cb5();
                        }}>Get Estimate</button>
                    )  : (<button className="next-button" onClick={() => {
                    cb3(payload);
                  }}>
                    Next
                  </button>)
                 }

                  
                </div>



                </div>
  );
};

const LoginInput = styled.input`
    width: 100%;
    height: 44px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #00c795;
    border-radius: 10px;
    font-size: 14px;
    font-family: "Inter";
    background: #F8F9FB;
    border: 1px solid #BCC1CC;
    border-radius: 8px;

    // background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
    // background-position: left;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    background-size: 60px;
    background-repeat: no-repeat;
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

export default BulkForm;
