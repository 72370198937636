import React, { useState, Fragment, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import * as api from "../../api/index";

import Text from "../../components/molecules/text/index";
import DashIcon from "../../components/molecules/dash-icon";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import AreaCode from "../../assets/icons/pngs/areaCode.png";

import Select from "react-select";

const WaypointInputs = ({
    idx,
    waypointsStack,
    orderCategories,
    distance,
    handleWaypointChange,
    handleCategoryChange,
    handleDropoffLocation,
    ...props
}) => {
    const locationId = `location-${idx}`;
    const recipientNameId = `recipientName-${idx}`;
    const recipientAddressDetailsId = `dropoff_address_details-${idx}`;
    const recipientPhoneId = `recipientPhone-${idx}`;
    const recipientEmailId = `recipientEmail-${idx}`;
    const descriptionId = `description-${idx}`;
    const orderCategoryId = `order_category-${idx}`;
    const [autocomplete, setAutocomplete] = useState([]);
    const [waypointInputError, setWaypointInputError] = useState("");
    const [nameSubmessage, setNameSubmessage] = useState("");
    const [descriptionMessage, setDescriptionSubmessage] = useState("");
    const [phoneSubmessage, setPhoneSubmessage] = useState("");
    const [del, setDel] = useState("");
    const [openDropoff, setDropoff] = useState(true);
    const [category, setCategory] = useState({});

    const getDropoffAutoCompleteResults = ({ idx }) => {
        const api_token = localStorage.getItem("api_token");
        const sessionToken = localStorage.getItem("session");
        const context = "dropoff";
        api.autocomplete({
            query: waypointsStack[idx].dropoffLocation.address,
            session: sessionToken,
            api_token,
            context: context,
        }).then((results) => {
            setAutocomplete(results);
        });
    };

    async function validate({ name, phone, description }) {
        if (name) {
            //Check if name is
            // Check if description is:
            if (name.length < 1) {
                // atleast 8 characters long
                return await fixThis("name", "Recipient name is needed.");
            } else {
                setNameSubmessage("");
            }
        }
        if (phone) {
            //Check if phone number is nigerian number
            if (
                !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
                    phone
                )
            ) {
                return fixThis("phone", "Please provide a valid phone number");
            } else {
                setPhoneSubmessage("");
            }
        }
        if (description) {
            // Check if description is:
            if (description.length < 1) {
                // atleast 8 characters long
                return await fixThis(
                    "description",
                    "Parcel description needs to be 1 character long."
                );
            } else {
                setDescriptionSubmessage("");
            }
        }

        async function fixThis(type, subMessage) {
            switch (type) {
                case "name":
                    await setNameSubmessage("");
                    setNameSubmessage(subMessage);
                    break;
                case "phone":
                    await setPhoneSubmessage("");
                    setPhoneSubmessage((o) => [...o, subMessage]);
                    break;
                case "description":
                    await setDescriptionSubmessage("");
                    setDescriptionSubmessage((o) => [...o, subMessage]);
                    break;
                default:
                    break;
            }
            return false;
        }

        return true;
    }

    return (
        <DropoffContainer>
            {!openDropoff && (
                <Fragment>
                    <Row
                        marginTop={10}
                        marginBottom={10}
                        justifyContent={"space-between"}
                    >
                        <Row onClick={() => setDropoff(!openDropoff)}>
                            <DashIcon
                                name={"closed"}
                                size={24}
                                onClick={() => setDropoff(!openDropoff)}
                            />
                            <Text
                                textTransform={"uppercase"}
                                fontWeight={"bold"}
                                marginLeft={16}
                                fontSize={14}
                                cursor={"pointer"}
                                color={"#00C795"}
                                onClick={() => setDropoff(!openDropoff)}
                            >{`Dropoff #${idx + 1}`}</Text>

                            {(() => {
                                if (
                                    waypointsStack[idx].dropoffLocation.address
                                        .length >= 1
                                )
                                    return (
                                        <Text
                                            marginLeft={16}
                                            fontSize={14}
                                            fontWeight={"bold"}
                                            marginRight={18}
                                            textTransform={"uppercase"}
                                        >
                                            {
                                                waypointsStack[idx]
                                                    .dropoffLocation.address
                                            }
                                        </Text>
                                    );
                            })()}

                            {(() => {
                                if (idx >= 1)
                                    return (
                                        <Fragment>
                                            <DashIcon
                                                name={"remove"}
                                                size={24}
                                                onClick={() => {
                                                    confirmAlert({
                                                        title: "Remove Waypoint?",
                                                        message:
                                                            "Are you sure you would like to remove this waypoint?",
                                                        buttons: [
                                                            {
                                                                label: "Yes",
                                                                onClick: () =>
                                                                    props.removeWaypoint(
                                                                        {
                                                                            idx: idx,
                                                                        }
                                                                    ),
                                                            },
                                                            {
                                                                label: "No",
                                                                onClick: () =>
                                                                    setDel(
                                                                        "nope"
                                                                    ),
                                                            },
                                                        ],
                                                    });
                                                }}
                                            />
                                        </Fragment>
                                    );
                            })()}
                        </Row>

                        {/* <Row>
              <DashIcon name={'addressbook'} size={20} />
              <Text
                marginLeft={4}
                fontSize={10}
                fontWeight={'bold'}
                cursor={'pointer'}
                textTransform={'uppercase'}
              >
                {' '}
                Load Addresses
              </Text>
            </Row> */}
                    </Row>
                </Fragment>
            )}

            {openDropoff && (
                <Fragment>
                    <input type={"hidden"} value={"something"} />
                    <Row
                        marginTop={10}
                        marginBottom={10}
                        justifyContent={"space-between"}
                    >
                        <Row>
                            <DashIcon
                                name={"open"}
                                size={24}
                                onClick={() => setDropoff(!openDropoff)}
                            />
                            <Text
                                textTransform={"uppercase"}
                                fontWeight={"bold"}
                                marginLeft={16}
                                fontSize={14}
                                cursor={"pointer"}
                                color={"#00C795"}
                                onClick={() => setDropoff(!openDropoff)}
                            >{`Dropoff #${idx + 1}`}</Text>
                        </Row>
                        <Row>
                            {(() => {
                                if (idx >= 1)
                                    return (
                                        <Fragment>
                                            <DashIcon
                                                name={"remove"}
                                                size={24}
                                                onClick={() => {
                                                    confirmAlert({
                                                        title: "Remove Waypoint?",
                                                        message:
                                                            "Are you sure you would like to remove this waypoint?",
                                                        buttons: [
                                                            {
                                                                label: "Yes",
                                                                onClick: () =>
                                                                    props.removeWaypoint(
                                                                        {
                                                                            idx: idx,
                                                                        }
                                                                    ),
                                                            },
                                                            {
                                                                label: "No",
                                                                onClick: () =>
                                                                    setDel(
                                                                        "nope"
                                                                    ),
                                                            },
                                                        ],
                                                    });
                                                }}
                                            />
                                        </Fragment>
                                    );
                            })()}
                            {/* <DashIcon name={'addressbook'} size={20} />
              <Text
                marginLeft={4}
                fontSize={10}
                fontWeight={'bold'}
                cursor={'pointer'}
                textTransform={'uppercase'}
              >
                {' '}
                Load Addresses
              </Text> */}
                        </Row>
                    </Row>

                    <Text marginTop={3} fontSize={10} color={"red"}>
                        {nameSubmessage}
                    </Text>
                    <Text marginTop={3} fontSize={10} color={"red"}>
                        {phoneSubmessage}
                    </Text>
                    <Text marginTop={3} fontSize={10} color={"red"}>
                        {descriptionMessage}
                    </Text>

                    <Row>
                        <DashIcon name={"location"} size={24} />
                        <DropoffInput
                            marginTop={10}
                            marginLeft={10}
                            type="text"
                            autocomplete="text"
                            id={locationId}
                            className="dropoffLocation"
                            name={locationId}
                            data-idx={idx}
                            onFocus={() => {
                                const api_token =
                                    localStorage.getItem("api_token");
                                const splitApi = api_token.substring(0, 4);
                                const sessionToken = splitApi.concat(
                                    Date.now()
                                );
                                localStorage.setItem("session", sessionToken);
                            }}
                            onBlur={() => {
                                localStorage.removeItem("session");
                            }}
                            value={waypointsStack[idx].dropoffLocation.address}
                            onChange={(e) => {
                                if (idx === 0) {
                                    if (!props.mapsMarkersStack[0]) {
                                        confirmAlert({
                                            title: "Add Pickup Location",
                                            message:
                                                "Please add a pickup location before entering a dropoff waypoint",
                                            buttons: [
                                                {
                                                    label: "Okay",
                                                    onClick: () =>
                                                        setDel("nope"),
                                                },
                                            ],
                                        });
                                    } else {
                                        handleWaypointChange(e);
                                        getDropoffAutoCompleteResults({
                                            idx: idx,
                                        });
                                    }
                                } else {
                                    handleWaypointChange(e);
                                    getDropoffAutoCompleteResults({ idx: idx });
                                }
                            }}
                            placeholder="Drop-off Location *"
                        />
                    </Row>
                    {autocomplete.length >= 1 && (
                        <MenuOptions
                            onMouseLeave={() => setAutocomplete([])}
                            className="scroll"
                        >
                            {autocomplete.map((res) => (
                                <MenuOption
                                    onClick={() => {
                                        let pickupId =
                                            props.mapsMarkersStack[idx]
                                                .location_id;
                                        if (res.location_id === pickupId) {
                                            setWaypointInputError(
                                                "Dropoff address cannot be same as pickup"
                                            );
                                        } else {
                                            setWaypointInputError("");
                                        }
                                        props.getCoords({
                                            idx,
                                            dropOff: true,
                                            pickup: false,
                                            location_id: res.location_id,
                                            context: "dropoff",
                                        });
                                        setAutocomplete([]);
                                    }}
                                >
                                    {res.address}
                                </MenuOption>
                            ))}
                        </MenuOptions>
                    )}
                    <Row>
                        <DashIcon name={"name"} size={24} />
                        <DropoffInput
                            type="text"
                            autocomplete="text"
                            id={recipientNameId}
                            className="recipientName"
                            name={recipientNameId}
                            data-idx={idx}
                            value={waypointsStack[idx].recipientName}
                            onChange={handleWaypointChange}
                            onBlur={() => {
                                validate({
                                    name: waypointsStack[idx].recipientName,
                                });
                            }}
                            marginTop={10}
                            marginLeft={10}
                            placeholder="Dropoff Recipient's Full Name *"
                        />
                    </Row>
                    {/* <Row>
            <DashIcon name={'home'} size={24} />
            <DropoffInput
              type="text"
              id={recipientAddressDetailsId}
              autocomplete="off"
              className="dropoff_address_details"
              name={recipientAddressDetailsId}
              data-idx={idx}
              value={waypointsStack[idx].dropoff_address_details}
              onChange={handleWaypointChange}
              onBlur={() => {
                                validate({ name: waypointsStack[idx].recipientName });
              }}
              marginTop={10}
              marginLeft={10}
              placeholder="Dropoff Recipient's Address Details"
            />
          </Row> */}
                    <Row>
                        <DashIcon name={"email"} size={24} />
                        <DropoffInput
                            type="text"
                            autocomplete="text"
                            id={recipientEmailId}
                            className="recipientEmail"
                            name={recipientEmailId}
                            data-idx={idx}
                            value={waypointsStack[idx].recipientEmail}
                            onChange={handleWaypointChange}
                            onBlur={() =>
                                validate({
                                    phone: waypointsStack[idx].recipientEmail,
                                })
                            }
                            marginTop={10}
                            marginLeft={10}
                            placeholder="Dropoff Recipient's Email"
                        />
                    </Row>
                    <Row>
                        <DashIcon name={"phone"} size={24} />
                        <PhoneInput
                            type="text"
                            autocomplete="text"
                            id={recipientPhoneId}
                            className="recipientPhone"
                            name={recipientPhoneId}
                            data-idx={idx}
                            value={waypointsStack[idx].recipientPhone}
                            onChange={handleWaypointChange}
                            onBlur={() =>
                                validate({
                                    phone: waypointsStack[idx].recipientPhone,
                                })
                            }
                            marginTop={10}
                            marginLeft={10}
                            placeholder="Dropoff Recipient's Phone Number *"
                        />
                    </Row>
                    <Row>
                        <DashIcon name={"description"} size={24} />
                        <Select
                            id={orderCategoryId}
                            styles={customStyles}
                            options={orderCategories}
                            data-idx={idx}
                            menuPlacement="auto"
                            className="orderCatergory"
                            placeholder="Choose Category of Parcel *"
                            onChange={(e) => {
                                setCategory(e);
                                handleCategoryChange(e, idx);
                            }}
                        />
                    </Row>

                    <Row marginBottom={20}>
                        <DashIcon name={"description"} size={24} />
                        <DropoffInput
                            type="text"
                            autocomplete="text"
                            id={descriptionId}
                            className="description"
                            name={descriptionId}
                            data-idx={idx}
                            value={waypointsStack[idx].description}
                            onChange={handleWaypointChange}
                            onBlur={() =>
                                validate({
                                    description:
                                        waypointsStack[idx].description,
                                })
                            }
                            marginTop={10}
                            marginBottom={10}
                            marginLeft={10}
                            placeholder="Description of parcel *"
                        />
                    </Row>
                    {(() => {
                        if (distance) {
                            return (
                                <Text
                                    textAlign={"end"}
                                    marginBottom={8}
                                    fontWeight={600}
                                    fontSize={12}
                                >
                                    {`${distance} km`} |
                                    {` ${waypointsStack[idx].estimate.minutes} min`}{" "}
                                    |
                                    {` ₦${waypointsStack[idx].estimate.amount_quoted}`}
                                </Text>
                            );
                        }
                    })()}
                </Fragment>
            )}
        </DropoffContainer>
    );
};

WaypointInputs.propTypes = {
    idx: PropTypes.number,
    waypointsStack: PropTypes.array,
    handleWaypointChange: PropTypes.func,
    deleteWaypoint: PropTypes.func,
};

export default WaypointInputs;

const MenuOptions = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 240px;
    align-items: center;
    border: 1px solid #e1e4eb;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.16);
    overflow-y: scroll;
`;

const MenuOption = styled.a`
    width: -webkit-fill-available;
    box-shadow: inset 0px -1px 0px #e1e4eb;
    min-height: 50px;
    overflow-wrap: anywhere;
    font-family: "Inter";
    margin-left: 20px;
    font-size: 14px;
    color: black;
    cursor: pointer;
    display: flex;
    align-items: center;
    ${(props) => (props.bottom ? "bottom: 24px;" : "")}
    ${(props) => (props.bottom ? "position: absolute;" : "")}
`;

// for below height: 402px

//

const customStyles = {
    container: (provided, state) => ({
        ...provided,

        width: "610px",
    }),
    control: (provided, state) => ({
        ...provided,

        height: "46px",
        marginTop: "10px",
        border: "solid 2px #00c795",
        borderRadius: "8px",
        fontFamily: "Inter",
        marginLeft: "10px",
    }),
    menu: (provided, state) => ({
        ...provided,
        fontFamily: "Inter",
        padding: 10,
    }),
};

const PhoneInput = styled.input`
    width: 530px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: solid 2px #00c795;
    border-radius: 8px;
    font-size: 14px;
    font-family: "Inter";
    text-align: left;
    padding-left: 60px;
    padding-right: 10px;
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
    background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
    background-position: left;
    background-size: 50px;
    background-repeat: no-repeat;
`;
const DropoffContainer = styled.div`
    box-shadow: inset 0 -1px 0 0 #e1e2e6;
    min-height: min-content;
`;

const DropoffInput = styled.input`
    width: 580px;
    height: 40px;
    border-radius: 8px;
    border: solid 2px #00c795;
    font-family: "Inter";
    text-align: left;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginTop ? `margin-top:${props.marginTop}px` : "10px"};
    ${(props) =>
        props.marginLeft ? `margin-left:${props.marginLeft}px` : "10px"};
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) =>
        props.justifyContent ? `justify-content:${props.justifyContent}` : ""};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;
