import React, { Fragment } from "react";
import * as MapboxGL from "mapbox-gl";
import ReactMapboxGl, {
    Marker,
    GeoJSONLayer,
    Layer,
    Feature,
} from "react-mapbox-gl";
import equal from "fast-deep-equal";

import PickupMarker from "../../assets/icons/pngs/pickup.png";
import DropoffMarker from "../../assets/icons/pngs/dropoff.png";
import { lte } from "lodash/fp";
import { initial } from "lodash";
import Config from '../../Config';

const Map = ReactMapboxGl({
    accessToken: Config.REACT_APP_MAPBOX,
});

const linePaint: MapboxGL.LinePaint = {
    "line-color": "black",
    "line-width": {
        base: 1.5,
        stops: [
            [14, 5],
            [18, 20],
        ],
    },
    "line-dasharray": [0.1, 1.8],
};

const lineLayout: MapboxGL.LineLayout = {
    "line-cap": "round",
    "line-join": "round",
};

class Mapbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: this.props.lat,
            lng: this.props.lng,
            center: this.props.center,
            polylineCoordinate: [],
            initalLat: [3.363280699999999, 6.5441226],
            finalLat: [3.363280699999999, 6.5441226],
        };
        this.createPolylines = this.createPolylines.bind(this);
        // this.handleMapMounted = this.handleMapMounted.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!equal(this.props.mapsMarkersStack, prevProps.mapsMarkersStack)) {
            this.createPolylines();
        }
    }

    createPolylines() {
        let newPolylines = [];
        this.props.mapsMarkersStack.map((waypoint) => {
            let polyline = [parseFloat(waypoint.lng), parseFloat(waypoint.lat)];
            newPolylines.push(polyline);
        });

        if (this.props.mapsMarkersStack.length <= 1) {
            if (this.props.mapsMarkersStack[0]) {
                this.setState({
                    initialLat: [
                        this.props.mapsMarkersStack[0].lng,
                        this.props.mapsMarkersStack[0].lat,
                    ],
                    finalLat: [
                        this.props.mapsMarkersStack[0].lng,
                        this.props.mapsMarkersStack[0].lat,
                    ],
                });
            }
        } else if (this.props.mapsMarkersStack.length > 1) {
            const firstMarker = this.props.mapsMarkersStack[0];

            const lastMarker =
                this.props.mapsMarkersStack[
                    this.props.mapsMarkersStack.length - 1
                ];

            this.setState({
                initalLat: [
                    parseFloat(firstMarker.lng),
                    parseFloat(firstMarker.lat),
                ],
                finalLat: [
                    parseFloat(lastMarker.lng),
                    parseFloat(lastMarker.lat),
                ],
                polylineCoordinate: [...newPolylines],
            });
        }
    }

    render() {
        return (
            <Map
                style="mapbox://styles/mapbox/streets-v8"
                containerStyle={{
                    height: "100%",
                    width: "100%",
                }}
                center={this.state.center}
                fitBounds={[this.state.initalLat, this.state.finalLat]}
                fitBoundsOptions={{
                    minZoom: 0,
                    maxZoom: 16,
                    padding: {
                        top: 100,
                        bottom: 100,
                        left: 100,
                        right: 100,
                    },
                }}
                movingMethod={"easeTo"}
            >
                {this.props.mapsMarkersStack &&
                    this.props.mapsMarkersStack.map((waypoint) => {
                        if (waypoint.location.includes("pickup")) {
                            return (
                                <Fragment key={waypoint.location_id}>
                                    <Marker
                                        style={{ width: 20, height: 20 }}
                                        coordinates={[
                                            parseFloat(waypoint.lng),
                                            parseFloat(waypoint.lat),
                                        ]}
                                    >
                                        <img
                                            alt={""}
                                            src={PickupMarker}
                                            style={{ width: 40, height: 40 }}
                                        />
                                    </Marker>
                                </Fragment>
                            );
                        } else {
                            return (
                                <Fragment>
                                    <Marker
                                        style={{ width: 40, height: 40 }}
                                        coordinates={[
                                            parseFloat(waypoint.lng),
                                            parseFloat(waypoint.lat),
                                        ]}
                                    >
                                        <img
                                            src={DropoffMarker}
                                            style={{ width: 40, height: 40 }}
                                            alt={""}
                                        />
                                    </Marker>
                                </Fragment>
                            );
                        }
                    })}

                <GeoJSONLayer
                    data={{
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                geometry: {
                                    type: "LineString",
                                    coordinates: this.state.polylineCoordinate,
                                },
                            },
                        ],
                    }}
                    linePaint={linePaint}
                    lineLayout={lineLayout}
                />
            </Map>
        );
    }
}

export default Mapbox;
