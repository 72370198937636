import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import Config from '../../Config';

import Header from '../../components/organisms/header/index';
import Footer from '../../components/organisms/footer/index';

import Text from '../../components/molecules/text';
import Icon from '../../components/molecules/icon';

import FreshChat from 'react-freshchat';

import Avatar from '../../assets/icons/pngs/gokada-logo/User_Avatar.png';

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  height: 85vh;
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 95%;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  border-top: 1px solid rgb(227, 229, 232);
`;

const Setting = ({ title, onClick = (f) => f }) => {
  return (
    <Text
      fontSize={24}
      fontFamily={'Inter'}
      paddingTop={2}
      paddingBottom={8}
      marginLeft={0}
      cursor={'pointer'}
      fontColor={'#fe6665'}
      borderBottom={true}
      onClick={onClick}
    >
      {title}
    </Text>
  );
};

const Profile = ({ settings = [], history }) => {
  settings = [
    { title: 'Help', onClick: () => history.push('/help') },
    {
      title: 'Logout',
      onClick: () => {
        window.postMessage('logout', '*');
        // localStorage.removeItem('api_token');
        // history.push('/login');
      },
    },
  ];
  const [user, setUser] = useState({});
  const api_token = localStorage.getItem('api_token');

  if (!api_token) {
    history.push('/login');
  }

  useEffect(() => {
    fetch(Config.BASE_URL + 'api/v1/user', {
      method: 'GET',
      dataType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + api_token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setUser(responseData);
      });
  }, []);

  return (
    <Fragment>
      <Header history={history} />

      <ProfileContainer>
        <AvatarContainer>
          {/* Some logic can be added below to change user.avatar but for now have a stock image. Sorry certain assets can't be grabbed from Zeplin so used Google for this */}

          <Text
            marginTop={20}
            fontFamily={'Inter'}
            marginBottom={8}
            marginLeft={0}
            fontSize={48}
            textTransform={'capitalize'}
            fontWeight={700}
          >
            {' '}
            {user.name ? user.name : 'User'}{' '}
          </Text>
        </AvatarContainer>

        <SettingsContainer>
          {settings.map((setting, i) => (
            <Setting key={i} title={setting.title} onClick={setting.onClick} />
          ))}
        </SettingsContainer>
      </ProfileContainer>
      <Footer />
    </Fragment>
  );
};

export default Profile;
