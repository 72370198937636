import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import * as api from "../../../api/index";

import Icon from "../../molecules/icon/index";
import DashIcon from "./icon";
import Text from "../../molecules/text/index";

import LightGokadaBusiness from  "../../../assets/icons/pngs/gokada-business/gokada-logo-full@3x.png";
import Android from "../../../assets/icons/pngs/android-logo.png";
import Apple from "../../../assets/icons/pngs/apple-logo.png";
import Menu from '../../../assets/icons/svgs/menu.svg';

import SettingIcon from "../../../assets/icons/svgs/settings.svg";
import { MenuContext } from "../../../MenuContext";

const HeaderContainer = styled.div`
    display: flex;
    max-height: 80px;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: row;
    grid-area: ${(props) => props.gridArea};
`;

const InnerContainer = styled.div`
    align-items: space-between;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
`;

const DownloadContainer = styled.div`
    display: flex;

    justify-content: flex-start;
    flex-direction: row;
    padding-left: 25px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    align-items: center;
`;

const Header = ({ history, match, ...props }) => {
    const {setSideMenu} = useContext(MenuContext);
    const theme = localStorage.getItem("theme") + "-header-mode";
    let api_token;
    const field = "user_id";
    const url = window.location.href;
    if (url.includes("?" + field + "=")) {
        const index = url.indexOf("=") + 1;
        const userId = url.slice(index, url.length);

        api_token = window.localStorage.getItem(`${userId}`);
    } else {
        api_token = localStorage.getItem("api_token");
    }

    const [user, setUser] = useState(false);
    const [iconTheme, setTheme] = useState(
        localStorage.getItem("theme") || "light"
    );

    const getUser = () => {
        api.getUserDetails({
            api_token,
        }).then((result) => {
            if (result.error === "invalid_api_key") {
                // console.log("this is from sub account");
                localStorage.removeItem("api_token");
                if (history.location.pathname !== "/reset-password" && history.location.pathname !== "/login") {
                    history.push("/login");
                }
            } else {
                setUser(result);
            }
        });
    };

    useEffect(() => {
        // getUser();
    }, []);

    const openSideMenu = () => setSideMenu(true);
    const authScreen = history.location.pathname === '/register' || history.location.pathname === '/login'

    return (
        <HeaderContainer className={theme} {...props}>
            <DownloadContainer>
                {(() => {
                    if (iconTheme === "light") {
                        return (
                            <div className="dash">
                                {!authScreen && <img src={Menu} alt="menu" className="menu" onClick={() => openSideMenu()} />}
                                <DashIcon
                                    cursor={"pointer"}
                                    onClick={() => {
                                        if (false && !api_token) {
                                            window.open(
                                                "https://business.gokada.ng"
                                            );
                                        } else if (api_token) {
                                            if (
                                                history.location.search.length >= 1
                                            ) {
                                                history.push(
                                                    `/dashboard/${history.location.search}`
                                                );
                                            } else history.push("/dashboard");
                                        } else {
                                            history.push("/login");
                                        }
                                    }}
                                    name={"light-brand"}
                                    objectFit={"cover"}
                                    height={40}
                                    width={146}
                                />
                            </div>
                        );
                    } else {
                        return (
                            <DashIcon
                                cursor={"pointer"}
                                onClick={() => {
                                    if (!api_token) {
                                        window.open(
                                            "https://business.gokada.ng"
                                        );
                                    } else if (api_token) {
                                        if (
                                            history.location.search.length >= 1
                                        ) {
                                            history.push(
                                                `/dashboard/${history.location.search}`
                                            );
                                        } else history.push("/dashboard");
                                    }
                                }}
                                name={"dark-brand"}
                                objectFit={"cover"}
                                height={40}
                                width={146}
                            />
                        );
                    }
                })()}
            </DownloadContainer>

            {/* <DownloadContainer>
        {(() => {
          if (iconTheme === 'light') {
            return (
              <DashIcon
                name={'light'}
                height={44}
                width={88}
                onClick={() => {
                  localStorage.setItem('theme', 'dark');
                  setTheme('dark');
                  window.location.reload();
                }}
              />
            );
          } else {
            return (
              <DashIcon
                name={'dark'}
                height={44}
                width={88}
                onClick={() => {
                  localStorage.setItem('theme', 'light');
                  setTheme('light');
                  window.location.reload();
                }}
              />
            );
          }
        })()}
      </DownloadContainer> */}
        </HeaderContainer>
    );
};

export default Header;
