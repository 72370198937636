/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import {Route, Link, Routes, useLocation} from 'react-router-dom';
import styled from "styled-components";
import * as api from "../../../api/index";
import { useMediaQuery } from "react-responsive";
import Close from '../../../assets/icons/svgs/close.svg';
import DashIcon from "../header/icon";
import { MenuContext } from "../../../MenuContext";

const SideMenu = ({ history, match, ...props }) => {
    const [homeActive, setHomeActive] = useState(false);
    const [ordersActive, setOrdersActive] = useState(false);
    const [settingsActive, setSettingsActive] = useState(false);
    const [walletActive, setWalletActive] = useState(false);
    const location = useLocation();
    const {setSideMenu} = useContext(MenuContext);

    const isTabletOrMobileDevice = useMediaQuery({
        query: "(max-device-width: 1224px)",
    });

    const MenuContainer = isTabletOrMobileDevice ? BlockContainer : FlexContainer;

    useEffect(() => {
      if(location.pathname == "/dashboard") {
        setHomeActive(true);
        setOrdersActive(false);
        setSettingsActive(false);
        setWalletActive(false);
      } else if(location.pathname == "/orders") {
        setHomeActive(false);
        setOrdersActive(true);
        setWalletActive(false);
        setSettingsActive(false);
      } else if(location.pathname == "/settings") {
        setHomeActive(false);
        setOrdersActive(false);
        setWalletActive(false);
        setSettingsActive(true);
      } else if(location.pathname == "/wallet") {
        setHomeActive(false);
        setOrdersActive(false);
        setSettingsActive(false);
        setWalletActive(true);
      }
    }, [location]);

    let api_token;
    const field = "user_id";
    const url = window.location.href;
    if (url.includes("?" + field + "=")) {
        const index = url.indexOf("=") + 1;
        const userId = url.slice(index, url.length);

        api_token = window.localStorage.getItem(`${userId}`);
    } else {
        api_token = localStorage.getItem("api_token");
    }


    const logoutUser = () => {
      const field = 'user_id';
      const url = window.location.href;
      if (url.includes('?' + field + '=')) {
        const index = url.indexOf('=') + 1;
        const userId = url.slice(index, url.length);
        localStorage.removeItem(userId);
      } else {
        localStorage.removeItem('api_token');
      }

      history.push('/login');
    };

    const closeSideMenu = () => setSideMenu(false);

    return (
        <MenuContainer>
            {isTabletOrMobileDevice && <img src={Close} alt="close" className="close" onClick={() => closeSideMenu()} />}
            <ul className="side-menu">
                <li className={homeActive ? 'home active' : 'home'}

                    onClick={() => {
                        history.push(
                            "/dashboard"
                        );
                        closeSideMenu();
                    }}

                >Home
                </li>
                <li className={ordersActive ? 'orders active' : 'orders'}

                    onClick={() => {
                        history.push(
                            "/orders"
                        );
                        closeSideMenu();
                    }}

                >Orders</li>
                <li className={settingsActive ? 'settings active' : 'settings'}

                    onClick={() => {
                        history.push(
                            "/settings"
                        );
                        closeSideMenu();
                    }}

                >Settings</li>
                <li className={walletActive ? 'wallet active' : 'wallet'}

                    onClick={() => {
                        history.push(
                            "/wallet"
                        );
                        setSideMenu(false);
                    }}

                >Wallet</li>
                <li className="support"

                    onClick={() => {
                        window.location.href = "https://www.gokada.ng/support";
                    }}

                >Support</li>
                <li className="logout"

                    onClick={() => {
                        logoutUser()
                    }}

                >Logout</li>
            </ul>

        {isTabletOrMobileDevice && (<div className="menu-dash">
            <DashIcon
                cursor={"pointer"}
                onClick={() => {
                    if (false && !api_token) {
                        window.open(
                            "https://business.gokada.ng"
                        );
                    } else if (api_token) {
                        if (
                            history.location.search.length >= 1
                        ) {
                            history.push(
                                `/dashboard/${history.location.search}`
                            );
                        } else history.push("/dashboard");
                    } else {
                        history.push("/login");
                    }
                }}
                name={"light-brand"}
                objectFit={"cover"}
                height={40}
                width={146}
            />
            </div>)}
        </MenuContainer>
    );
};

const FlexContainer = styled.div`
    display: flex;
`;

const BlockContainer = styled.div`
    display: block;
    height: 100vh;
`;


export default SideMenu;
