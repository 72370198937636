import React, { useState, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import axios from "axios";
import Config from "../../../Config";

import * as MapboxGL from "mapbox-gl";
import ReactMapboxGl, {
    Marker,
    GeoJSONLayer,
    Layer,
    Feature,
} from "react-mapbox-gl";
import { ZoomControl } from "react-mapbox-gl";

const MapControl = ({ ...props }) => {

  let cb = props.changeLocation ? props.changeLocation : (() => {});

  const Map = ReactMapboxGl({
      accessToken: Config.REACT_APP_MAPBOX,
  });

  let entries = [
    {
      'delivery_longitude': props.longitude,
      'delivery_latitude': props.latitude
    }
  ];

  let index = 0;
  let state = {
      center: [props.longitude, props.latitude],
      initalLat: [props.longitude, props.latitude],
      finalLat: [props.longitude, props.latitude],
      markerPickup: [props.longitude, props.latitude]
  };

  return (
    <div className="setting-map">
      <Map
          style='mapbox://styles/mapbox/streets-v8'
          containerStyle={{
              height: "500px",
              width: "100%",
          }}
          center={[props.latitude,props.longitude]}
          fitBounds={[state.initalLat, state.finalLat]}
          fitBoundsOptions={{
              minZoom: 0,
              maxZoom: 16,
              zoom: 13,
              padding: {
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
              },
          }}
          movingMethod={"easeTo"}
      >
          <ZoomControl/>

          <Layer
              type="circle"
              id="marker"
              paint={{
              'circle-color': 'limegreen',
              'circle-stroke-width': 3,
              'circle-stroke-color': 'green',
              'circle-stroke-opacity': 1}}
            >
            <Feature
                      style={{ width: 20, height: 20 }}
                      draggable={props.draggable}
                      anchor="bottom"
                      onDragStart={(a) => {

                      }}
                      onDrag={(a) => {

                      }}
                      onDragEnd={(a) => {
                          entries[index]['delivery_latitude'] = a.lngLat.lat;
                          entries[index]['delivery_longitude'] = a.lngLat.lng;
                          cb({'lat': a.lngLat.lat, 'lng': a.lngLat.lng});
                      }}
                      coordinates={[
                          parseFloat(
                              (typeof entries[index] !== 'undefined' && entries[index]['delivery_longitude'] != '') ? entries[index]['delivery_longitude'] : "3.3898"
                          ),
                          parseFloat(
                              (typeof entries[index] !== 'undefined' && entries[index]['delivery_latitude'] != '') ? entries[index]['delivery_latitude'] : "6.5158"
                          ),
                      ]}
                  >
              </Feature>
          </Layer>
      </Map>
    </div>
  );
};

export default MapControl;
