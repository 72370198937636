import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';

import * as api from '../../../api/index';
import FreshChat from 'react-freshchat';
import Text from '../../../components/molecules/text';
import Button from '../../../components/molecules/button';
import AddressSearch from '../../../components/molecules/address-search/AddressSearch';
import MapControl from '../../../components/molecules/map-control/MapControl';
import Config from "../../../Config";
import Header from '../../../components/organisms/header/index';
import SideMenu from '../../../components/organisms/menu/index';
import { confirmAlert } from 'react-confirm-alert';
import AreaCode from '../../../assets/icons/pngs/areaCode.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { useMediaQuery } from 'react-responsive';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: transparent;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100vw;
  height: 100vh;
`;

const MenuOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 64px;
  padding-right: 64px;
`;

const MenuOption = styled.a`
width: 95%
justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: 'Inter';
  font-weight: 400;
 font-size: 18px;
 cursor: pointer;
  ${(props) => (props.fontColor ? `color: ${props.fontColor}` : '#0e0e0f')};
  ${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight}` : '')};
  cursor: pointer;
  display: flex;
  ${(props) => (props.bottom ? 'bottom: 24px;' : '')}
  ${(props) => (props.bottom ? 'position: absolute;' : '')}
  border-top: 1px solid rgb(227, 229, 232);
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
`;

const Row = styled.div`
  margin-top: 22px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;

  width: 100%;
`;
const PhoneInput = styled.input`
  top: 416px;
  left: 308px;
  width: 340px;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e1e4eb;
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Inter';
  text-align: left;
  padding-left: 60px;
  padding-right: 10px;
  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
  background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
  background-position: left;
  background-size: 60px;
  background-repeat: no-repeat;
`;
const Input = styled.input`
  top: 416px;
  left: 308px;
  width: 384px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #cccccc;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'Inter';
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;

  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
`;

const VerifyForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
`;

const SettingForm = ({ ...props }) => {
  const api_token = props.api_token;
  const theme = localStorage.getItem('theme') + '-mode';
  const [name, setName] = useState(props.user.business !== null ? props.user.business.name : null);
  const [id, setId] = useState(props.user.business !== null ? props.user.business.business_id  : null);
  const [email, setEmail] = useState(props.user.business !== null ? props.user.business.email  : null);
  const [phone, setPhone] = useState(props.user.business !== null ? props.user.business.phone_number : null);
  const [address, setAddress] = useState(props.user.business !== null ? props.user.business : null);

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
});
  let cb2 = props.addressChanged;

  let saveChanges = function() {
    let data = {
      'name': name,
      'id': id ? id : '',
      'phone': phone ? phone : '',
      'email': email,
      'address': address ? address.address : '',
      'address_lat': address ? address.lat : '',
      'address_lng': address ? address.lng : ''
    }

    const headers = {
        Authorization: "Bearer " + api_token,
    };

    axios
      .post(Config.BASE_URL + "api/v1/user", data, {
          headers: headers,
      })
      .then((res) => {
        console.log(res);
        toast.success('Successfully updated!')
      })
      .catch(function (error) {
        alert(error.response.data.message);
      });
  }

  return (
    <div className="setting-form">

      {
        address?.length > 0 ? (null) : (
          <p className="warning">
        <b>Notice:</b> A pickup address must be selected to create orders.
      </p>)
      }
      

      <div><Toaster/></div>
                  
                  <div className="setting-row">
                    <div className="setting-element">
                      <span className="login-label">Business Name</span>
                      <LoginInput
                          placeholder="Your Business Name"
                          marginTop={5}
                          value={name}
                          required
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          type={"text"}
                      />

                    </div>
                    <div className="setting-element">
                      <span className="login-label">Business ID</span>
                      <LoginInput
                          placeholder="ID"
                          marginTop={5}
                          value={id}
                          onChange={{}}
                          type={"text"}
                          disabled
                      />

                    </div>
                  </div>

                  <div className="setting-row">
                    <div className="setting-element">
                      <span className="login-label">Business Email</span>

                      <LoginInput
                          placeholder="Your Business Email"
                          marginTop={5}
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          type={"email"}
                      />

                    </div>
                    <div className="setting-element">
                      <span className="login-label">Phone Number</span>

                      <LoginInput
                          placeholder="Business Phone Number"
                          marginTop={5}
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                          type={"email"}
                      />

                    </div>
                  </div>

                  <div className="setting-row">
                    <div className="setting-element">
                      <span className="login-label">Business Address</span>
                      <AddressSearch
                      defaultAddress={address ? address.address : ''}
                      token={props.api_token}
                      parentCallback={(data) => {
                        setAddress(data);
                        cb2(data);
                      }}
                      />
                    </div>
                  </div>

                  {isTabletOrMobileDevice && props.user && props.mapAddress && <MapControl 
                  latitude={props.mapAddress[0]} 
                  longitude={props.mapAddress[1]}
                  draggable={false}
                  changeLocation={(data) => {
                    // setMapAddress([data.lat,data.lng]);
                      // let tmp = entries;
                      // tmp[index]['delivery_latitude'] = data.lat;
                      // tmp[index]['delivery_longitude'] = data.lng;
                      // tmp[index]['dropoff_lat'] = data.lat;
                      // tmp[index]['dropoff_lng'] = data.lng;
                      // setEntries(tmp);
                  }}
                />}

                  <Button
                    className='setting-form-button'
                      width={isTabletOrMobileDevice ? '100%' : 260}
                      height={44}
                      borderRadius={10}
                      marginTop={50}
                      marginBottom={0}
                      onClick={saveChanges}
                  >
                      Save Changes
                  </Button>


                </div>
  );
};

const LoginInput = styled.input`
    width: 100%;
    height: 44px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #00c795;
    border-radius: 10px;
    font-size: 14px;
    font-family: "Inter";
    background: #F8F9FB;
    border: 1px solid #BCC1CC;
    border-radius: 8px;

    // background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
    // background-position: left;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    background-size: 60px;
    background-repeat: no-repeat;
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

export default SettingForm;
