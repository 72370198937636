/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import * as api from "../../api/index";
import Header from "../../components/organisms/header/index";
import axios from "axios";

import Text from "../../components/molecules/text/index";
import DashIcon from "../../components/molecules/dash-icon";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Button from "../../components/molecules/button";
import Config from "../../Config";
import PickupMarker from "../../assets/icons/pngs/pickup.png";
import DropoffMarker from "../../assets/icons/pngs/dropoff.png";
import Pin from './pin';
import SideMenu from '../../components/organisms/menu/index';
import Loading from '../../assets/loading.gif';
import MapControl from '../../components/molecules/map-control/MapControl';
import BulkForm from '../../components/molecules/setting-form/BulkForm';
import TableAlt from '../orders/TableAlt';

import * as MapboxGL from "mapbox-gl";

import ReactMapboxGl, {
    Marker,
    GeoJSONLayer,
    Layer,
    Feature,
} from "react-mapbox-gl";
import { ZoomControl } from "react-mapbox-gl";
import { MenuContext } from "../../MenuContext";
import { useMediaQuery } from "react-responsive";

const Tools = ({ history, match, ...props }) => {

    // let injectedFile = typeof props.location.state != 'undefined' ? props.location.state.file : '';
    

    const Map = ReactMapboxGl({
        accessToken: Config.REACT_APP_MAPBOX,
    });

    const theme = localStorage.getItem("theme") + "-mode";
    let api_token;

    const {sidemenu} = useContext(MenuContext);
    const [hidden, setHidden] = useState(false);
    const [hidden2, setHidden2] = useState(false);
    const [hidden3, setHidden3] = useState(false);
    const [hidden4, setHidden4] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState("Loading");


    const [mapAddress, setMapAddress] = useState([6.4478,3.4723]);

    const isTabletOrMobileDevice = useMediaQuery({
        query: "(max-device-width: 1224px)",
    });



    const field = "user_id";
    const [msg, setMsg] = useState("");
    const url = window.location.href;
    if (url.includes("?" + field + "=")) {
        const index = url.indexOf("=") + 1;
        const userId = url.slice(index, url.length);
        api_token = window.localStorage.getItem(`${userId}`);
    } else {
        api_token = localStorage.getItem("api_token");
    }

    if (!api_token) {
        history.push("/login");
    } else if (typeof api_token === "object") {
        if (api_token.message.includes("invalid")) {
            localStorage.clear();
            history.push("/login");
        }
    }

    let state = {
        center: [3.4723, 6.4478],
        initalLat: [3.4723, 6.4478],
        finalLat: [3.4723, 6.4478],
        markerPickup: [3.4725, 6.4468]
    };

    let [user, setUser] = useState(null);
    let [entries, setEntries] = useState([]);
    let [entry, setEntry] = useState({});
    let [estimates, setEstimates] = useState([]);
    let [index, setIndex] = useState(0);
    let [confirmed, setConfirmed] = useState(false);
    let [dateRequested, setDateRequested] = useState("");

    let lastEntryTmp = {};

    const [selectedFile, setSelectedFile] = useState(typeof props.location.state != 'undefined' ? props.location.state.file : null);

    const getUser = () => {
      api
        .getUserDetails({
          api_token,
        })
        .then((result) => {
          // debugger;
          if (result.error === 'invalid_api_key') {
            console.log('this is from sub account');
            localStorage.removeItem('api_token');
            history.push('/login');
          } else {
            setUser(result);

            let business = result.business;
            if(business !== null && 'address_lat' in business) {
              setMapAddress([business.address_lat,business.address_lng]);
            }
          }
        });
    };

    useEffect(() => {
      getUser();

      var now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      now.setMilliseconds(null);
      now.setSeconds(null);
      setDateRequested(now.toISOString().slice(0, -1));

      if(typeof props.location.state != 'undefined' && props.location.state.mode == 'standalone') {
        setEntries(props.location.state.entries);

        console.log(props.location.state.entries);

        setHidden4(true);
        setHidden(true);
        setHidden3(true);
        setIndex(0);
      }

    }, [props.location.state]);

    useEffect(() => {
      if(entries.length > 0) {
        setMapAddress([entries[index]['delivery_latitude'], entries[index]['delivery_longitude']]);
      }
    }, [entries, index]);

    useEffect(() => {
        if(user) {
            if(user.wallet && user.wallet.balance == 0) {
              history.push(
                  "/wallet"
              );
            } else if(user.business && user.business.address == '') {
              history.push(
                  "/settings"
              );
            }
        }
    }, [history]);

    useEffect(() => {
      if(entries.length > 0) {
        setEntry(entries[index]);
        setMapAddress([entries[index]['delivery_latitude'], entries[index]['delivery_longitude']]);
      }
    }, [entries, index]);

    useEffect(() => {
        if(selectedFile) {
            uploadFiles();
        }
    }, [selectedFile])



    const onFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const orderRiders = () => {

        setConfirmed(true);

        const response = window.confirm("Are You Sure ?");
        if(response) {

            setLoadingMessage('Sending Orders');

            setHidden4(false);
            setHidden2(false);

            const data = new FormData();
            for(let i = 0; i < entries.length; i++) {
                entries[i]['date_requested'] = document.getElementById('date-requested').value;
            }
            data.append("entries", JSON.stringify(entries));

            const headers = {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + api_token,
            };

            axios
                .post(Config.BASE_URL + "api/v2/developer/bulk_upload_step_three", data, {
                    headers: headers,
                })
                .then((res) => {
                    if (res.data) {
                        setHidden4(true);
                        setHidden2(true);
                        setHidden3(true);
                        setEstimates(res.data.estimates);
                    }
                })
                .catch(function (error) {
                    alert('duplicate orders being created');
                    setHidden4(true);
                    setHidden2(true);
                    // if (error.response) {
                    //   // The request was made and the server responded with a status code
                    //   // that falls out of the range of 2xx
                    //   console.log(error.response.data);
                    //   console.log(error.response.status);
                    //   console.log(error.response.headers);
                    // } else if (error.request) {
                    //   // The request was made but no response was received
                    //   // `error.request` is an instance of XMLHttpRequest in the browser 
                    //   // and an instance of http.ClientRequest in node.js
                    //   console.log(error.request);
                    // } else {
                    //   // Something happened in setting up the request that triggered an Error
                    //   console.log('Error', error.message);
                    // }
                   
                  });       
        }
    }

    const nextEntry = () => {

        if(typeof entries[index] !== 'undefined' && entries[index]['delivery_longitude'] == '') {
            window.alert('Please confirm the location');
            return;
        }


        if(index + 1 < entries.length)
            index = index + 1;

        setIndex(index);

        let entriesTmp = entries;
        // entriesTmp[index - 1] = lastEntryTmp;
        // console.log(lastEntryTmp);

        setEntries([
          ...entriesTmp
        ]);
    }

    const prevEntry = () => {
        if(index - 1 >= 0)
            index = index - 1;

        setIndex(index)

        setEntries([
          ...entries
        ]);
    }

    const stepTwo = () => {
        setHidden4(false);
        setHidden(false);

        const data = new FormData();
        data.append("entries", JSON.stringify(entries));

        console.log(entries);

        const headers = {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + api_token,
        };

        // setHidden3(true);
        // setHidden4(false);

        setLoadingMessage('Fetching Estimates');

        axios
            .post(Config.BASE_URL + "api/v2/developer/bulk_upload_step_two", data, {
                headers: headers,
            })
            .then((res) => {
                // setHidden4(true);
                // setHidden(true);
                // setHidden3(true);
                // setIndex(0);
                // setEntries(res.data);
                if (res.data) {
                    setHidden4(true);
                    setHidden2(true);
                    setHidden3(true);

                    let estimates = [];
                    for(let i = 0; i < res.data.estimates.length; i++) {
                        // console.log(res.data.estimates[i]);
                    }
                    // console.log(res.data.estimates);
                    setEstimates(res.data.estimates);
                //     setMsg("File Successfully uploaded");
                //     if (history.location.search.length >= 1) {
                //         history.push(`orders/${history.location.search}`);
                //     } else history.push(`/orders`);
                }
                 // else setMsg("Error uploading file. Please try again.");
            });

        // setTimeout(function() {

            // setHidden4(true);
            // setHidden2(true);
            // setHidden3(true);

            // setEstimates([
            //     {'a':'b'},{'a':'c'}
            // ]);


        // }, 1000);
        // setHidden(false);
        // setHidden2(true);
        // setHidden3(true);

        // setEstimates([
        //     {'a':'b'},{'a':'c'}
        // ]);
    }

    const uploadFiles = () => {

        if(!selectedFile) {
            window.alert("Please select a file to upload");
            return;
        }

        const file = new FormData();
        file.append("file", selectedFile);
        file.append("step", "1");

        const headers = {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + api_token,
        };

        setHidden3(true);
        setHidden4(false);
        setLoadingMessage('Uploading');

        axios
            .post(Config.BASE_URL + "api/v2/developer/bulk_upload", file, {
                headers: headers,
            })
            .then((res) => {
                setHidden4(true);
                setHidden(true);
                setHidden3(true);
                setIndex(0);
                setEntries(res.data);
                // if (res.data.success === true) {
                //     setMsg("File Successfully uploaded");
                //     if (history.location.search.length >= 1) {
                //         history.push(`orders/${history.location.search}`);
                //     } else history.push(`/orders`);
                // } else setMsg("Error uploading file. Please try again.");
            });
    };
    return (
        <ViewContainer className={theme}>
            {sidemenu ? (<SideMenu history={history} />) : (
                <OuterContainer>
                    <Header history={history} />

                    <div style={{display: 'flex',width: '100%'}}>
                    {!isTabletOrMobileDevice && <SideMenu history={history} />}
                    <div className="main-content">
                        <div className="inner-box">

            <div style={{ }}>
                
                <div className="top-box left">
                <div className="back"
                    onClick={() => {
                        window.history.back();
                    }}
                >
                Back to Home
                </div>
                </div>
                <h1>Bulk Upload</h1>     

                <ConfirmContainer height={140}  hidden={hidden4}>
                    <center>
                        <h3>
                            {loadingMessage}
                        </h3>
                        <img src="loading.gif" width="100" />
                    </center>
                </ConfirmContainer>
                <UploadContainer height={140}  hidden={hidden3}>
                    <Text
                        marginTop={20}
                        fontSize={18}
                        color={"#a1a7b3"}
                        fontWeight={"bold"}
                    >
                        Bulk Upload
                    </Text>
                    <Text fontSize={14} fontWeight={"bold"}>
                        Please provide the file you would like to upload.
                    </Text>
                    {msg.length >= 1 && (
                        <Text
                            fontSize={14}
                            color={"#0e0e0f"}
                            fontWeight={"bold"}
                        >
                            {msg}
                        </Text>
                    )}

                    <Row marginBottom={20} marginTop={20}>
                        <UploadInput
                            width={100}
                            type="file"
                            name="file"
                            onChange={onFileChange}
                        />
                        {selectedFile && (
                            <Text
                                fontSize={14}
                                color={"#0e0e0f"}
                                marginRight={16}
                                fontWeight={"bold"}
                            >
                                {selectedFile.name}
                            </Text>
                        )}
                        <Button
                            width={120}
                            height={40}
                            borderRadius={10}
                            onClick={uploadFiles}
                        >
                            Upload
                        </Button>
                    </Row>
                </UploadContainer>

                <ConfirmContainer hidden={!hidden}>

                    <h2>
                    Order Details {index + 1} / {entries.length} &nbsp;&nbsp;
                    </h2>

                    <div className="setting-group">

                    {entries.length > 0 && entry && <BulkForm
                        entryChanged={(data) => {
                        lastEntryTmp = data;
                        
                        }}
                        api_token={api_token}
                        nextButton={nextEntry}
                        prevButton={prevEntry}
                        estButton={() => {
                        stepTwo();
                        }}
                        addressChanged = {(data) => {
                        setMapAddress([data.lat,data.lng]);
                        }}
                        index={index}
                        entries={entries}
                        entry={entry}
                        />}
                    <div className="top-margin" />
                    {user && mapAddress && <MapControl 
                        latitude={mapAddress[0]} 
                        longitude={mapAddress[1]}
                        draggable={true}
                        changeLocation={(data) => {
                            let tmp = entries;
                            tmp[index]['delivery_latitude'] = data.lat;
                            tmp[index]['delivery_longitude'] = data.lng;
                            tmp[index]['dropoff_lat'] = data.lat;
                            tmp[index]['dropoff_lng'] = data.lng;
                            setEntries(tmp);
                        }}
                    />}
                    {!user && (
                        <div style={{textAlign: 'center',width: '100%'}}>
                        <img src={Loading} width="100" height="100" />
                        </div>
                    )}
                    </div>

                </ConfirmContainer>


                <ConfirmContainer hidden={!hidden2}>
                    <h1>
                        {
                            (confirmed) ? "Confirmed Order" : "Your Estimate"
                        }
                    </h1>
                    
                        {user &&  estimates.length > 0 && (
                        <div style={{textAlign: 'center',width: '100%'}}>
                        {estimates.map((row,index) => (
                            <div>
                            <TableAlt user={user} index={index} data2={row} data={row.order_units} hideCheckbox={true} key={Math.random()} />
                            <br />
                            </div>
                        ))}
                        </div>
                        )}

                    <br />

                    {
                        (confirmed) ? (

                            <Attention> Please use the Gokada SuperApp to manage the orders!</Attention>

                        ) : (

                            <div className="pickup-field">
                                <label>Pickup Date and Time</label> <br/>
                                <input 
                                    type="datetime-local" 
                                    id="date-requested" 
                                    name="date_requested"
                                    className="pickup-input"
                                    value={dateRequested}
                                    onChange={(a) => {
                                        setDateRequested(a.value);
                                    }}
                                    /> <br/><br/>
                                    <button className="est-button mod" onClick={orderRiders}>Schedule Order</button>
                            </div>
                        )
                    }

                    
                </ConfirmContainer>

            </div>


                        </div>
                    </div>
                    </div>
                </OuterContainer>

            )}
        </ViewContainer>
    );
};

export default Tools;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100vw;
  height: 100vh;
`;

const ViewContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
`;

const Th = styled.th`
    width: 100%;
`;

const Tr2 = styled.tr`
    font-weight: bold;
    background: #f1f1f1;
`;

const Thead = styled.thead`
    background: #eaeaea;
    padding: 5px;
    display: block;
`;

const Tbody = styled.tbody`
    padding: 5px;
    display: block;
`;

const BodyDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0px 250px 20px 250px;
`;

const Header3 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;

const Button2 = styled.button`
    width: 100px;
    padding: 10px;
    display: inline-block;
`;

const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 -1px 0 0 #e1e2e6;
    width: 100%;
    margin-bottom: 20px;
    ${(props) => (props.height ? `height:${props.height}px` : "120px")};
    ${(props) => (props.hidden ? `display:none` : "")};
`;

const ConfirmContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    ${(props) => (props.height ? `height:${props.height}px` : "120px")};
    ${(props) => (props.hidden ? `display:none` : "")};
`;

const MapContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 500px;
    margin-bottom: 20px;
    ${(props) => (props.height ? `height:${props.height}px` : "120px")};
    ${(props) => (props.hidden ? `display:none` : "")};
    background: #f1f1f1 !important;
    border: 2px dashed #ccc !important;
    margin-top: 20px;
    padding: 20px;
`;

const BigDiv = styled.div`
    padding: 10px;
    border-bottom: 1px dashed #999;
`;

const Attention = styled.div`
    color: orange;
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
`;


const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
    ${(props) =>
        props.justifyContent ? `justify-content:${props.justifyContent}` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) =>
        props.overFlowWrap ? `overflow-wrap:${props.overFlowWrap}` : ""};
`;

const UploadInput = styled.input`
    top: 416px;
    left: 308px;

    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font-size: 16px;
    font-family: "Inter";
    text-align: left;
    padding-left: 10px;
    margin-right: 16px;
    padding-right: 10px;
    ${(props) => (props.width ? `width:${props.width}px` : "800px")};
    ${(props) =>
        props.border ? `border:${props.border}` : "solid 2px #e1e2e6"};
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;
