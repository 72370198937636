import { dropWhile } from "lodash";
import Config from "../Config";

// API calls below for the login -> verify -> registration flow
/**
 * remove contact interest
 * @param {String} device_id os type
 * @param {string} device_type shoudl always be "web"
 * @param {string} device_token a unique id
 * @param {String} email user's email
 * @param {String} password user's password
 * @returns {api_token}
 */
export const userLogin = ({
    device_id,
    device_type,
    device_token,
    email,
    password,
}) => {
    return new Promise(async (resolve, reject) => {
        // await fetch(Config.BASE_URL + "api/v1/user/login", {
        await fetch(Config.BASE_URL + "api/v3/user/login", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                device_id,
                device_type,
                device_token,
                email,
                password,
                'platform': 'firefox',
                'platform_version': '2.5'
            }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Logging In Error:", err);
                reject(err);
            });
    });
};

/**
 * @param {String} email user's email
 * @returns {Boolean} status
 */
export const passwordReset = ({ email }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v3/user/forgot_password", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email,
            }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Logging In Error:", err);
                reject(err);
            });
    });
};

/**
 * verifies user with ussd_code and verification token once call has been
 * @param {String} verify_token verification token
 * @returns {api_token}
 */

export const verifyUser = (obj) => {
    console.log(obj);
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v3/user/verify_otp", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + obj.api_token,
            },
            body: JSON.stringify({ 'code': obj.code, 'type': obj.type }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Verifying User Error:", err);
                reject(err);
            });
    });
};

/**
 * signs in the user
 * @param {String} name user's full name // todo: validate full name with a space
 * @param {String} email user's email address
 * @param {String} api_token api token recieved
 * @returns {api_token}
 */

export const signUpUser = ({ email, phone_number, password, first_name, last_name, device_id, device_type, country_code = "234", is_business = true, ref }) => {
    console.log({ email, phone_number, password, first_name, last_name, device_id, device_type, country_code, ref });
    let payload = {
        email,
        phone_number,
        password,
        first_name,
        last_name,
        device_id,
        device_type,
        country_code,
        is_business
    }
    if (ref?.length) {
        payload = {
            ...payload,
            ref,
        }
    }
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v3/user/signup", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Signing Up User Error:", err);
                reject(err);
            });
    });
};

export const sendUserOTP = (obj) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v3/user/send_otp", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + obj.api_token,
            },
            body: JSON.stringify({ type: obj.type }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Signing Up User Error:", err);
                reject(err);
            });
    });
};

export const updateUser = (obj) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v4/user/update", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + obj.api_token,
            },
            body: JSON.stringify({ id: obj.id, phone_number: obj.phone_number }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("User Update Error:", err);
                reject(err);
            });
    });
};

export const affRegister = (obj) => {
    console.log(obj.data);
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v3/user/onboard", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(obj.data),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                reject(err);
            });
    });
};

/**
 * get's all of the user's orders
 * @param {String} api_token api token recieved
 * @returns {api_token}
 */

export const getAllOrders = ({ api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v1/user/orders?all=1", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Getting All Orders Error:", err);
                reject(err);
            });
    });
};

/**
 * get's the status of the user's order
 * @param {String} api_token api token recieved
 * @returns {api_token}
 */

export const getOrderStatus = ({ ORDER_ID, api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + `api/v2/order/status/${ORDER_ID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Getting Order Status Error:", err);
                reject(err);
            });
    });
};

export const getOrderStatusV3 = ({ ORDER_ID, api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + `api/v3/order/details/${ORDER_ID}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Getting Order Status Error:", err);
                reject(err);
            });
    });
};

/**
 * get's the status of the user's order
 * @param {String} api_token api token recieved
 *  @param {String} order_id api token recieved
 * @returns {api_token}
 */

export const cancelOrder = ({ order_id, api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + `api/v1/user/cancel`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
            body: JSON.stringify({ order_id }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Cancelling Order Error:", err);
                reject(err);
            });
    });
};

export const orderAction = ({ api_token, order_id, action }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + `api/v3/order/action`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
            body: JSON.stringify({ order_id, action }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Cancelling Order Error:", err);
                reject(err);
            });
    });
};

export const autocomplete = ({ query, session, api_token, context }) => {
    const lat = "6.435275500000001";
    const lng = "3.4147874";
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v1/location/search?q=", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
            body: JSON.stringify({ q: query, session, lat, lng, context }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Autocomplete Error:", err);
                reject(err);
            });
    });
};

/**
 * get's the status of the user's order
 * @param {String} api_token api token recieved
 * @returns {api_token}
 */

export const getLatLng = ({ location_id, api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + `api/v1/location/search/${location_id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Getting Lat and Lng Error:", err);
                reject(err);
            });
    });
};

/**
 * get's the estimate of the user's order
 * @param {String} api_token api token recieved
 * @returns {api_token}
 */

export const getEstimate = ({ pickupId, dropoffId, api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v1/order/estimate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
            body: JSON.stringify({
                pickup_location_id: pickupId,
                dropoff_location_id: dropoffId,
            }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Getting Estimate Error:", err);
                reject(err);
            });
    });
};

export const getEstimateV3 = ({ params, api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v3/business/estimate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
            body: JSON.stringify(params),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Getting Estimate Error:", err);
                reject(err);
            });
    });
};

/**
 * posts the confirmation of the user's order
 * @param {String} api_token api token recieved
 * @returns {api_token}
 */

export const confirmOrder = ({ params, api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v2/order/confirm", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
            body: JSON.stringify(params),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Confirming Order Error:", err);
                reject(err);
            });
    });
};

export const confirmOrderV3 = ({ params, api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v3/business/send", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
            body: JSON.stringify(params),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Confirming Order Error:", err);
                reject(err);
            });
    });
};

// Get user's api access
export const getApiAccess = ({ api_token, status_webhook_url }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v2/developer", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Getting API Access Details:", err);
                reject(err);
            });
    });
};

//sigup for API ACCESS api/v2/developer/signup
// app_name, app_description
export const signUpApiAcess = ({ app_name, app_description, api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v2/developer/signup", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
            body: JSON.stringify({
                app_name,
                app_description,
            }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Signup API Access Error:", err);
                reject(err);
            });
    });
};

export const generateTransaction = ({ wallet_id, amount, api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v3/user/wallets/" + wallet_id + "/generate_gateway_transaction?amount=" + amount + "&gateway=paystack", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
            // body: JSON.stringify({}),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Signup API Access Error:", err);
                reject(err);
            });
    });
};

//api/v2/developer/topoff_validate
// To validate paystack topoff for Gokada

export const validatePaystackTopoff = ({ wallet_id, gateway, gateway_reference, api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v3/user/wallets/"+wallet_id+"/deposit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
            body: JSON.stringify({
                gateway,
                gateway_reference
            }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Validating Paystack Top Off Error:", err);
                reject(err);
            });
    });
};

// get user details
export const getUserDetails = ({ api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v1/user", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Getting User Details Error:", err);
                reject(err);
            });
    });
};

//api/v2/developer/topoff_validate
// To validate paystack topoff for Gokada

export const optimizeRoute = ({ estimate_ids, api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v2/order/optimize", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
            body: JSON.stringify({
                estimate_ids,
            }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Optimizing Route Error:", err);
                reject(err);
            });
    });
};

//api/v2/developer/topoff_validate
// To validate paystack topoff for Gokada

export const getWaypointOrderCategories = ({ api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v1/settings", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Getting Order Categories Error:", err);
                reject(err);
            });
    });
};

//subaccounts api logic
export const addSubAccount = ({ name, email, phone_number, api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v2/developer/add_account", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
            body: JSON.stringify({
                name,
                email,
                phone_number,
            }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Creating Sub Account Error:", err);
                reject(err);
            });
    });
};

export const getSubAccounts = ({ api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v2/developer/accounts", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Getting All Sub Accounts Error:", err);
                reject(err);
            });
    });
};

export const loginSubAccount = ({ user_id, api_token }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v2/developer/subaccount/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
            body: JSON.stringify({
                sub_account_user_id: user_id,
            }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Logging Into Sub Accounts Error:", err);
                reject(err);
            });
    });
};

// update webhooks

export const updateDevAcount = ({ webhookUrl, api_token }) => {
    let status_webhook_url;
    if (webhookUrl.includes("http://") || webhookUrl.includes("https://")) {
        status_webhook_url = webhookUrl;
    } else {
        status_webhook_url = `https://${webhookUrl}`;
    }

    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v2/developer/update", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token,
            },
            body: JSON.stringify({
                status_webhook_url,
            }),
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Updating Accounts Error:", err);
                reject(err);
            });
    });
};

export const bulkUpload = ({ api_token, file }) => {
    return new Promise(async (resolve, reject) => {
        await fetch(Config.BASE_URL + "api/v2/developer/bulk_upload", {
            method: "POST",
            headers: {
                "Content-Type": false,
                Authorization: "Bearer " + api_token,
            },
            body: file,
        })
            .then((r) => {
                resolve(r.json());
            })
            .catch((err) => {
                console.log("Uploading Bulk Files Error:", err);
                reject(err);
            });
    });
};
