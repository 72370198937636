import React from "react";
import Loading from '../../assets/loading.gif';

const LandingPage = ({ history, ...props }) => {
  const params = (new URL(document.location)).searchParams;
  const refParam = params.get("ref")

  if(params.get("ref") != null) {
    // history.push(`/register?ref=${refParam}`);
    history.push({
      pathname: '/register',
      state: {ref: refParam}
    })

  } else {
    history.push("/login");
  }

  return (
    <div style={{textAlign: 'center',width: '100%',marginTop: '100px'}}>
      <img src={Loading} width="100" height="100" />
    </div>
  );
};

export default LandingPage;
