import { createGlobalStyle } from 'styled-components';

import InterWoff from './Inter-Regular.woff';
import InterWoff2 from './Inter-Regular.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Inter';
        src: local('Inter'), local('Inter'),
        url(${InterWoff2}) format('woff2'),
        url(${InterWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
`;
