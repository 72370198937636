import React from 'react';
import styled from 'styled-components';

import LightSVG from '../../../assets/icons/svgs/switch-light.svg';
import DarkSVG from '../../../assets/icons/svgs/switch-dark.svg';
import DarkGokada from '../../../assets/icons/svgs/gokada-brand-dark.svg';
import LightGokada from '../../../assets/icons/svgs/gokada-logo-full.svg';

const getIcon = (name) => {
  switch (name) {
    case 'light':
      return LightSVG;
    case 'dark':
      return DarkSVG;
    case 'dark-brand':
      return DarkGokada;
    case 'light-brand':
      return LightGokada;

    default:
      break;
  }
};

const IconContainer = styled.img`
  width: ${(props) => props.width || 24}px;
  height: ${(props) => props.height || 24}px;
  object-fit: fill;
  cursor: pointer;
  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) => (props.cursor ? `cursor:${props.cursor}` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
  ${(props) => (props.gridArea ? `grid-area: ${props.gridArea};` : '')};
`;
const DashIcon = ({ gridArea, name, src, size, onClick, ...props }) => {
  const svg = getIcon(name);
  return (
    <IconContainer
      gridArea={gridArea}
      src={src || svg}
      size={size}
      onClick={onClick}
      {...props}
    />
  );
};

export default DashIcon;
