import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import * as api from "../../api/index";

import Text from "../../components/molecules/text";
import Header from "../../components/organisms/header/index";
import DashIcon from "../../components/molecules/dash-icon";
import useInterval from "../../components/hooks/polling";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Mapbox from "./Mapbox";

const OrderDetailsV1 = ({ history, ...props }) => {
    let api_token;
    const field = "user_id";
    const url = window.location.href;
    if (url.includes("?" + field + "=")) {
        const index = url.indexOf("=") + 1;
        const userId = url.slice(index, url.length);
        api_token = window.localStorage.getItem(`${userId}`);
    } else {
        api_token = localStorage.getItem("api_token");
    }

    if (!api_token) {
        history.push("/login");
    } else if (typeof api_token === "object") {
        if (api_token.message.includes("invalid")) {
            localStorage.clear();
            history.push("/login");
        }
    }

    const [center, setCenter] = useState({
        lat: "" || 3.363280699999999,
        lng: "" || 6.5441226,
    });
    const [user, setUser] = useState({});
    const [order, setOrder] = useState({});
    const [driver, setDriver] = useState({});
    const [waypoints, setWaypoints] = useState([]);
    const [trackingLink, setTrackingLink] = useState({});
    const [confirmMsg, setConfirmMsg] = useState("");
    const [status, setStatus] = useState("");
    const [firstWaypoint, setFirst] = useState({});
    const [lastWaypoint, setLast] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [inProgressIndex, setInProgressIndex] = useState("");
    const [driverArrived, setDriverArrived] = useState(false);
    const [driverArrivedIdx, setDriverArrivedIdx] = useState(false);

    const getUser = () => {
        api.getUserDetails({
            api_token,
        }).then((result) => {
            if (result.error === "invalid_api_key") {
                localStorage.removeItem("api_token");
                history.push("/login");
            } else {
                setUser(result);
            }
        });
    };

    const getOrderDetails = () => {
        api.getOrderStatus({ api_token, ORDER_ID: props.match.params._id })
            .then((result) => {
                console.log("order details", result);
                setOrder(result.order);
                setDriver(result.driver);
                setStatus(result.order.status);

                getWaypoints({ waypointsArray: result.order.waypoints });
                checkInProgress({ waypointsArray: result.order.waypoints });
                setTrackingLink(result.order.tracking_links);
            })
            .catch((err) => {
                console.log("Getting order status error:", err);
            });
    };

    const checkInProgress = ({ waypointsArray }) => {
        for (let i = 0; i <= waypointsArray.length; i++) {
            if (waypointsArray[i].status === "in-progress") {
                setInProgress(true);
                setInProgressIndex(i + 1);

                for (let k = 1; k <= waypointsArray.length; k++) {
                    if (waypointsArray[k]) {
                        if (waypointsArray[k].status === "in-progress") {
                            setInProgressIndex(i + 1);
                            setInProgress(true);
                            setDriverArrived(false);
                            break;
                        }
                    }
                }
                break;
            } else if (waypointsArray[i].status === "waypoint-arrived") {
                setDriverArrivedIdx(i + 1);
                setInProgress(false);
                setDriverArrived(true);

                for (let k = 1; k <= waypointsArray.length; k++) {
                    if (waypointsArray[k]) {
                        if (waypointsArray[k].status === "in-progress") {
                            setDriverArrivedIdx(i + 1);
                            setInProgress(false);
                            setDriverArrived(true);
                            break;
                        }
                    }
                }
                break;
            } else if (waypointsArray[i].status === "waypoint-complete")
                for (let k = 1; k < waypointsArray.length; k++) {
                    if (waypointsArray[k]) {
                        if (waypointsArray[k].status === "waypoint-arrived") {
                            setDriverArrivedIdx(k + 1);
                            setInProgress(false);
                            setDriverArrived(true);
                            break;
                        }
                        if (waypointsArray[k].status === "in-progress") {
                            setInProgressIndex(k + 1);
                            setInProgress(true);
                            setDriverArrived(false);
                            break;
                        } else if (
                            waypointsArray[k].status === "waypoint-complete"
                        ) {
                            setInProgress(false);
                            setDriverArrived(false);
                            break;
                        }
                    } else {
                        setInProgress(false);
                        setDriverArrived(false);
                    }
                }

            break;
        }
    };

    const getWaypoints = ({ waypointsArray }) => {
        const length = waypointsArray.length - 1;
        let waypointsCollection = [];
        if (waypointsArray.length >= 1) {
            waypointsCollection.push({
                location: "pickup",
                lat: waypointsArray[0].pickup_lat,
                lng: waypointsArray[0].pickup_lng,
            });
            waypointsCollection.push({
                location: "dropoff",
                lat: waypointsArray[0].dropoff_lat,
                lng: waypointsArray[0].dropoff_lng,
            });
            for (let i = 1; i <= waypointsArray.length - 1; i++) {
                waypointsCollection.push({
                    location: "dropoff",
                    lat: waypointsArray[i].dropoff_lat,
                    lng: waypointsArray[i].dropoff_lng,
                });
            }
            setFirst(waypointsCollection[0]);
            setLast(waypointsCollection[length]);
            setWaypoints(waypointsCollection);
            return waypointsCollection;
        }
    };

    const cancelOrder = () => {
        const order_id = order.order_id;
        api.cancelOrder({ api_token, order_id }).then((result) => {
            console.log("cancelOrder", result);
            if (result.success === true) {
                confirmAlert({
                    title: "Order Cancelled",
                    message: "This order has been successully cancelled.",
                    buttons: [
                        {
                            label: "Okay",
                            onClick: () => getOrderDetails(),
                        },
                    ],
                });
            } else if (result.success === false) {
                confirmAlert({
                    title: "Order Cancellation Failed",
                    message:
                        "This order has failed to cancel. Please try cancelling again.",
                    buttons: [
                        {
                            label: "Okay",
                            onClick: () => setConfirmMsg("order cancel failed"),
                        },
                    ],
                });
            }
        });
    };

    useEffect(() => {
        getUser();
        getOrderDetails();
    }, []);

    useInterval(() => {
        getOrderDetails();
    }, 5000);

    return (
        <ViewContainer>
            <Header history={history} />

            <BodyContainer>
                <OrderContainer>
                    {(() => {
                        if (status) {
                            switch (status) {
                                case "driver-dropoff-complete":
                                case "driver-assigned":
                                case "driver-pickup-arrived":
                                case "driver-pickup-complete":
                                    return (
                                        <InnerWaypointContainer>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    height: "128px",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                    boxShadow:
                                                        "inset 0 -1px 0 0 #e1e2e6",
                                                }}
                                            >
                                                {(() => {
                                                    if (status) {
                                                        switch (status) {
                                                            case "driver-assigned":
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            flexDirection:
                                                                                "column",
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            fontSize={
                                                                                32
                                                                            }
                                                                            color={
                                                                                "#0e0e0f"
                                                                            }
                                                                            fontWeight={
                                                                                "bold"
                                                                            }
                                                                        >
                                                                            Driver
                                                                            Assigned
                                                                        </Text>

                                                                        <Row
                                                                            marginTop={
                                                                                8
                                                                            }
                                                                            marginBottom={
                                                                                8
                                                                            }
                                                                            alignItems={
                                                                                "center"
                                                                            }
                                                                        >
                                                                            <Text
                                                                                fontSize={
                                                                                    32
                                                                                }
                                                                                textTransform={
                                                                                    "uppercase"
                                                                                }
                                                                                color={
                                                                                    "#a1a7b3"
                                                                                }
                                                                                fontWeight={
                                                                                    "bold"
                                                                                }
                                                                            >
                                                                                otp
                                                                            </Text>
                                                                            <Text
                                                                                fontSize={
                                                                                    32
                                                                                }
                                                                                marginLeft={
                                                                                    8
                                                                                }
                                                                                color={
                                                                                    "#0e0e0f"
                                                                                }
                                                                                fontWeight={
                                                                                    "bold"
                                                                                }
                                                                            >
                                                                                {
                                                                                    order.otp
                                                                                }
                                                                            </Text>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            case "driver-pickup-arrived":
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            flexDirection:
                                                                                "column",
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            fontSize={
                                                                                32
                                                                            }
                                                                            color={
                                                                                "#0e0e0f"
                                                                            }
                                                                            fontWeight={
                                                                                "bold"
                                                                            }
                                                                        >
                                                                            Driver
                                                                            Pickup
                                                                            Arrived
                                                                        </Text>

                                                                        <Row
                                                                            marginTop={
                                                                                8
                                                                            }
                                                                            marginBottom={
                                                                                8
                                                                            }
                                                                            alignItems={
                                                                                "center"
                                                                            }
                                                                        >
                                                                            <Text
                                                                                fontSize={
                                                                                    32
                                                                                }
                                                                                textTransform={
                                                                                    "uppercase"
                                                                                }
                                                                                color={
                                                                                    "#a1a7b3"
                                                                                }
                                                                                fontWeight={
                                                                                    "bold"
                                                                                }
                                                                            >
                                                                                otp
                                                                            </Text>
                                                                            <Text
                                                                                fontSize={
                                                                                    32
                                                                                }
                                                                                marginLeft={
                                                                                    8
                                                                                }
                                                                                color={
                                                                                    "#0e0e0f"
                                                                                }
                                                                                fontWeight={
                                                                                    "bold"
                                                                                }
                                                                            >
                                                                                {
                                                                                    order.otp
                                                                                }
                                                                            </Text>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            case "driver-dropoff-complete":
                                                            case "driver-pickup-complete":
                                                                if (
                                                                    inProgress
                                                                ) {
                                                                    return (
                                                                        <Text
                                                                            fontSize={
                                                                                32
                                                                            }
                                                                            color={
                                                                                "#0e0e0f"
                                                                            }
                                                                            fontWeight={
                                                                                "bold"
                                                                            }
                                                                        >
                                                                            En
                                                                            route:
                                                                            Waypoint
                                                                            #
                                                                            {
                                                                                inProgressIndex
                                                                            }
                                                                        </Text>
                                                                    );
                                                                } else if (
                                                                    driverArrived
                                                                ) {
                                                                    return (
                                                                        <Text
                                                                            fontSize={
                                                                                32
                                                                            }
                                                                            color={
                                                                                "#0e0e0f"
                                                                            }
                                                                            fontWeight={
                                                                                "bold"
                                                                            }
                                                                        >
                                                                            Driver
                                                                            Arrived:
                                                                            Waypoint
                                                                            #
                                                                            {
                                                                                driverArrivedIdx
                                                                            }
                                                                        </Text>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <Text
                                                                            fontSize={
                                                                                32
                                                                            }
                                                                            color={
                                                                                "#0e0e0f"
                                                                            }
                                                                            fontWeight={
                                                                                "bold"
                                                                            }
                                                                        >
                                                                            Completed
                                                                        </Text>
                                                                    );
                                                                }
                                                            default:
                                                                break;
                                                        }
                                                    }
                                                })()}

                                                <ColumnContainer>
                                                    {driver &&
                                                        driver.time_estimate >
                                                            0 && (
                                                            <Row
                                                                marginTop={8}
                                                                marginBottom={8}
                                                                alignItems={
                                                                    "center"
                                                                }
                                                                justifyContent={
                                                                    "space-between"
                                                                }
                                                            >
                                                                <Text
                                                                    fontSize={
                                                                        32
                                                                    }
                                                                    textTransform={
                                                                        "uppercase"
                                                                    }
                                                                    color={
                                                                        "#a1a7b3"
                                                                    }
                                                                    fontWeight={
                                                                        "bold"
                                                                    }
                                                                >
                                                                    Est
                                                                </Text>
                                                                <Text
                                                                    fontSize={
                                                                        32
                                                                    }
                                                                    marginLeft={
                                                                        8
                                                                    }
                                                                    color={
                                                                        "#0e0e0f"
                                                                    }
                                                                    textTransform={
                                                                        "uppercase"
                                                                    }
                                                                    fontWeight={
                                                                        "bold"
                                                                    }
                                                                >
                                                                    {
                                                                        driver.time_estimate
                                                                    }{" "}
                                                                    min
                                                                </Text>
                                                            </Row>
                                                        )}
                                                    {/* {status === 'driver-assigned' && (
                            <Row
                              background={'red 0% 0% no-repeat padding-box;'}
                              marginTop={8}
                              borderRadius={'8px'}
                              padding={'8px'}
                              marginBottom={8}
                              cursor={'pointer'}
                              marginLeft={8}
                              marginRight={8}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              onClick={cancelOrder}
                            >
                              <Text
                                fontSize={20}
                                color={'white'}
                                textTransform={'uppercase'}
                                fontWeight={'bold'}
                              >
                                Cancel Order
                              </Text>{' '}
                            </Row>
                          )} */}
                                                </ColumnContainer>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    height: "100%",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                            "space-evenly",
                                                    }}
                                                >
                                                    <Row
                                                        marginTop={20}
                                                        justifyContent={
                                                            "space-between"
                                                        }
                                                    >
                                                        <Row
                                                            alignItems={
                                                                "center"
                                                            }
                                                            justifyContent={
                                                                "space-between"
                                                            }
                                                        >
                                                            <Text
                                                                fontSize={16}
                                                                textTransform={
                                                                    "uppercase"
                                                                }
                                                                color={
                                                                    "#a1a7b3"
                                                                }
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                            >
                                                                Payment
                                                            </Text>
                                                            <Text
                                                                fontSize={16}
                                                                marginLeft={8}
                                                                textTransform={
                                                                    "uppercase"
                                                                }
                                                                color={
                                                                    "#0e0e0f"
                                                                }
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                            >
                                                                {
                                                                    order.payment_method
                                                                }
                                                            </Text>
                                                        </Row>

                                                        <Row
                                                            alignItems={
                                                                "center"
                                                            }
                                                            justifyContent={
                                                                "space-between"
                                                            }
                                                        >
                                                            <Text
                                                                fontSize={16}
                                                                textTransform={
                                                                    "uppercase"
                                                                }
                                                                color={
                                                                    "#a1a7b3"
                                                                }
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                            >
                                                                Distance
                                                            </Text>
                                                            <Text
                                                                fontSize={16}
                                                                marginLeft={8}
                                                                textTransform={
                                                                    "uppercase"
                                                                }
                                                                color={
                                                                    "#0e0e0f"
                                                                }
                                                                fontWeight={
                                                                    "bold"
                                                                }
                                                            >
                                                                {
                                                                    order.distance_km
                                                                }{" "}
                                                                km
                                                            </Text>
                                                        </Row>
                                                    </Row>
                                                    {order.waypoints && (
                                                        <Row
                                                            marginTop={20}
                                                            marginBottom={8}
                                                            justifyContent={
                                                                "space-between"
                                                            }
                                                        >
                                                            <Row>
                                                                <DashIcon
                                                                    name={
                                                                        "location"
                                                                    }
                                                                    size={24}
                                                                />
                                                                <Text
                                                                    textTransform={
                                                                        "uppercase"
                                                                    }
                                                                    marginLeft={
                                                                        16
                                                                    }
                                                                    fontSize={
                                                                        14
                                                                    }
                                                                    fontWeight={
                                                                        900
                                                                    }
                                                                >
                                                                    Pickup
                                                                </Text>
                                                                <Text
                                                                    fontSize={
                                                                        14
                                                                    }
                                                                    marginLeft={
                                                                        6
                                                                    }
                                                                >
                                                                    {
                                                                        order
                                                                            .waypoints[0]
                                                                            .pickup_location
                                                                    }
                                                                </Text>
                                                            </Row>
                                                            <DashIcon
                                                                marginLeft={10}
                                                                size={24}
                                                                onClick={() =>
                                                                    window.open(
                                                                        trackingLink.pickup
                                                                    )
                                                                }
                                                                name={"right"}
                                                            />
                                                        </Row>
                                                    )}
                                                    {order.waypoints &&
                                                        order.waypoints.map(
                                                            (
                                                                waypoint,
                                                                index
                                                            ) => {
                                                                const idNum =
                                                                    index + 1;
                                                                return (
                                                                    <Fragment>
                                                                        <div
                                                                            key={
                                                                                waypoint.id
                                                                            }
                                                                            style={{
                                                                                flexDirection:
                                                                                    "row",
                                                                                display:
                                                                                    "flex",
                                                                                marginTop:
                                                                                    "8px",
                                                                                marginBottom:
                                                                                    "8px",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                            }}
                                                                        >
                                                                            <Row>
                                                                                <DashIcon
                                                                                    name={
                                                                                        "location"
                                                                                    }
                                                                                    size={
                                                                                        24
                                                                                    }
                                                                                />
                                                                                <Text
                                                                                    fontSize={
                                                                                        14
                                                                                    }
                                                                                    textTransform={
                                                                                        "uppercase"
                                                                                    }
                                                                                    fontWeight={
                                                                                        900
                                                                                    }
                                                                                    marginLeft={
                                                                                        16
                                                                                    }
                                                                                >
                                                                                    Dropoff
                                                                                    #
                                                                                    {
                                                                                        idNum
                                                                                    }{" "}
                                                                                </Text>
                                                                                <Text
                                                                                    fontSize={
                                                                                        14
                                                                                    }
                                                                                    fontWeight={
                                                                                        500
                                                                                    }
                                                                                    marginLeft={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        waypoint.dropoff_location
                                                                                    }
                                                                                </Text>
                                                                            </Row>

                                                                            {(() => {
                                                                                if (
                                                                                    waypoint.status ===
                                                                                    "waypoint-complete"
                                                                                ) {
                                                                                    return (
                                                                                        <Fragment>
                                                                                            <Text
                                                                                                fontSize={
                                                                                                    14
                                                                                                }
                                                                                                marginLeft={
                                                                                                    8
                                                                                                }
                                                                                                color={
                                                                                                    "#0e0e0f"
                                                                                                }
                                                                                                textTransform={
                                                                                                    "uppercase"
                                                                                                }
                                                                                                fontWeight={
                                                                                                    "bold"
                                                                                                }
                                                                                            >
                                                                                                Done
                                                                                            </Text>
                                                                                        </Fragment>
                                                                                    );
                                                                                } else {
                                                                                    return (
                                                                                        <Fragment>
                                                                                            {" "}
                                                                                            <DashIcon
                                                                                                marginLeft={
                                                                                                    10
                                                                                                }
                                                                                                size={
                                                                                                    24
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    window.open(
                                                                                                        trackingLink
                                                                                                            .deliveries[
                                                                                                            index
                                                                                                        ]
                                                                                                    )
                                                                                                }
                                                                                                name={
                                                                                                    "right"
                                                                                                }
                                                                                            />
                                                                                        </Fragment>
                                                                                    );
                                                                                }
                                                                            })()}
                                                                        </div>
                                                                    </Fragment>
                                                                );
                                                            }
                                                        )}
                                                </div>

                                                {(() => {
                                                    if (
                                                        order.status ===
                                                            "driver-dropoff-complete" &&
                                                        !driver
                                                    ) {
                                                        return (
                                                            <Fragment>
                                                                <DriverContainer>
                                                                    <Text
                                                                        fontSize={
                                                                            24
                                                                        }
                                                                        color={
                                                                            "#0e0e0f"
                                                                        }
                                                                        textTransform={
                                                                            "uppercase"
                                                                        }
                                                                        fontWeight={
                                                                            "bold"
                                                                        }
                                                                        cursor={
                                                                            "pointer"
                                                                        }
                                                                        onClick={() =>
                                                                            window.open(
                                                                                trackingLink.pickup
                                                                            )
                                                                        }
                                                                    >
                                                                        Click
                                                                        here to
                                                                        rate
                                                                        your
                                                                        driver
                                                                    </Text>
                                                                    {driver && (
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                flexDirection:
                                                                                    "row",
                                                                                alignItems:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            <Avatar
                                                                                src={
                                                                                    driver.avatar_src
                                                                                }
                                                                                size={
                                                                                    80
                                                                                }
                                                                            />

                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    flexDirection:
                                                                                        "column",

                                                                                    marginLeft:
                                                                                        "8px",
                                                                                }}
                                                                            >
                                                                                <Text
                                                                                    fontSize={
                                                                                        14
                                                                                    }
                                                                                    color={
                                                                                        "#0e0e0f"
                                                                                    }
                                                                                    fontWeight={
                                                                                        900
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        driver.name
                                                                                    }
                                                                                </Text>
                                                                                <Text
                                                                                    fontSize={
                                                                                        14
                                                                                    }
                                                                                    color={
                                                                                        "#0e0e0f"
                                                                                    }
                                                                                    fontWeight={
                                                                                        "normal"
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        driver.pilot_id
                                                                                    }
                                                                                </Text>
                                                                                <Row>
                                                                                    <DashIcon
                                                                                        name={
                                                                                            "call"
                                                                                        }
                                                                                        size={
                                                                                            16
                                                                                        }
                                                                                    />{" "}
                                                                                    <Text
                                                                                        fontSize={
                                                                                            14
                                                                                        }
                                                                                        color={
                                                                                            "#0e0e0f"
                                                                                        }
                                                                                        fontWeight={
                                                                                            "normal"
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            driver.phone_number
                                                                                        }
                                                                                    </Text>
                                                                                </Row>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </DriverContainer>
                                                            </Fragment>
                                                        );
                                                    }
                                                })()}

                                                {driver && (
                                                    <DriverContainer>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "row",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <Avatar
                                                                src={
                                                                    driver.avatar_src
                                                                }
                                                                size={80}
                                                            />

                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    flexDirection:
                                                                        "column",

                                                                    marginLeft:
                                                                        "8px",
                                                                }}
                                                            >
                                                                <Text
                                                                    fontSize={
                                                                        14
                                                                    }
                                                                    color={
                                                                        "#0e0e0f"
                                                                    }
                                                                    fontWeight={
                                                                        900
                                                                    }
                                                                >
                                                                    {
                                                                        driver.name
                                                                    }
                                                                </Text>
                                                                <Text
                                                                    fontSize={
                                                                        14
                                                                    }
                                                                    color={
                                                                        "#0e0e0f"
                                                                    }
                                                                    fontWeight={
                                                                        "normal"
                                                                    }
                                                                >
                                                                    {
                                                                        driver.pilot_id
                                                                    }
                                                                </Text>
                                                                <Row>
                                                                    <DashIcon
                                                                        name={
                                                                            "call"
                                                                        }
                                                                        size={
                                                                            16
                                                                        }
                                                                    />{" "}
                                                                    <Text
                                                                        fontSize={
                                                                            14
                                                                        }
                                                                        color={
                                                                            "#0e0e0f"
                                                                        }
                                                                        fontWeight={
                                                                            "normal"
                                                                        }
                                                                    >
                                                                        {
                                                                            driver.phone_number
                                                                        }
                                                                    </Text>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </DriverContainer>
                                                )}
                                            </div>
                                        </InnerWaypointContainer>
                                    );

                                case "pending-driver":
                                    return (
                                        <InnerWaypointContainer
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    height: "100%",
                                                    marginTop: "24px",
                                                    marginBottom: "24px",
                                                }}
                                            >
                                                {(() => {
                                                    if (order.cancelled === 0) {
                                                        return (
                                                            <Fragment>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "row",
                                                                        alignItems:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <div className="dash-icon-container">
                                                                        <DashIcon
                                                                            name="searching"
                                                                            size={
                                                                                300
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <Text
                                                                        fontSize={
                                                                            32
                                                                        }
                                                                        textAlign={
                                                                            "center"
                                                                        }
                                                                        color={
                                                                            "#0e0e0f"
                                                                        }
                                                                        fontWeight={
                                                                            900
                                                                        }
                                                                        position={
                                                                            "absolute"
                                                                        }
                                                                    >
                                                                        Searching
                                                                        for a
                                                                        driver...
                                                                    </Text>
                                                                </div>

                                                                <Row
                                                                    border={
                                                                        "2px solid #00c795"
                                                                    }
                                                                    marginTop={
                                                                        8
                                                                    }
                                                                    marginBottom={
                                                                        8
                                                                    }
                                                                    borderRadius={
                                                                        "8px"
                                                                    }
                                                                    padding={
                                                                        "8px"
                                                                    }
                                                                    marginLeft={
                                                                        8
                                                                    }
                                                                    marginRight={
                                                                        8
                                                                    }
                                                                    alignItems={
                                                                        "center"
                                                                    }
                                                                    justifyContent={
                                                                        "space-between"
                                                                    }
                                                                >
                                                                    <Text
                                                                        fontSize={
                                                                            32
                                                                        }
                                                                        textTransform={
                                                                            "uppercase"
                                                                        }
                                                                        color={
                                                                            "#a1a7b3"
                                                                        }
                                                                        fontWeight={
                                                                            "bold"
                                                                        }
                                                                    >
                                                                        otp
                                                                    </Text>
                                                                    <Text
                                                                        fontSize={
                                                                            32
                                                                        }
                                                                        marginLeft={
                                                                            8
                                                                        }
                                                                        color={
                                                                            "#0e0e0f"
                                                                        }
                                                                        fontWeight={
                                                                            "bold"
                                                                        }
                                                                    >
                                                                        {
                                                                            order.otp
                                                                        }
                                                                    </Text>{" "}
                                                                </Row>
                                                                <Row
                                                                    background={
                                                                        "red 0% 0% no-repeat padding-box;"
                                                                    }
                                                                    marginTop={
                                                                        8
                                                                    }
                                                                    borderRadius={
                                                                        "8px"
                                                                    }
                                                                    padding={
                                                                        "8px"
                                                                    }
                                                                    marginBottom={
                                                                        8
                                                                    }
                                                                    cursor={
                                                                        "pointer"
                                                                    }
                                                                    marginLeft={
                                                                        8
                                                                    }
                                                                    marginRight={
                                                                        8
                                                                    }
                                                                    alignItems={
                                                                        "center"
                                                                    }
                                                                    justifyContent={
                                                                        "space-between"
                                                                    }
                                                                    onClick={
                                                                        cancelOrder
                                                                    }
                                                                >
                                                                    <Text
                                                                        fontSize={
                                                                            20
                                                                        }
                                                                        color={
                                                                            "white"
                                                                        }
                                                                        textTransform={
                                                                            "uppercase"
                                                                        }
                                                                        fontWeight={
                                                                            "bold"
                                                                        }
                                                                    >
                                                                        Cancel
                                                                        Order
                                                                    </Text>{" "}
                                                                </Row>
                                                            </Fragment>
                                                        );
                                                    } else {
                                                        return (
                                                            <Fragment>
                                                                {/* <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div className="dash-icon-container">
                                    <DashIcon name="searching" size={300} />
                                  </div>
                                  <Text
                                    fontSize={32}
                                    textAlign={'center'}
                                    color={'#0e0e0f'}
                                    fontWeight={900}
                                    position={'absolute'}
                                  >
                                    No driver found...
                                  </Text>
                                </div>
                                <Row
                                  background={
                                    'red 0% 0% no-repeat padding-box;'
                                  }
                                  marginTop={8}
                                  borderRadius={'8px'}
                                  padding={'8px'}
                                  marginBottom={8}
                                  cursor={'pointer'}
                                  marginLeft={8}
                                  marginRight={8}
                                  alignItems={'center'}
                                  justifyContent={'space-between'}
                                >
                                  <Text
                                    fontSize={20}
                                    color={'white'}
                                    textTransform={'uppercase'}
                                    fontWeight={'bold'}
                                  >
                                    Cancelled
                                  </Text>{' '}
                                </Row> */}

                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                        justifyContent:
                                                                            "space-evenly",
                                                                    }}
                                                                >
                                                                    <Row
                                                                        alignItems={
                                                                            "center"
                                                                        }
                                                                        justifyContent={
                                                                            "space-between"
                                                                        }
                                                                    >
                                                                        <Text
                                                                            fontSize={
                                                                                20
                                                                            }
                                                                            textTransform={
                                                                                "uppercase"
                                                                            }
                                                                            color={
                                                                                "#0e0e0f"
                                                                            }
                                                                            fontWeight={
                                                                                "bold"
                                                                            }
                                                                        >
                                                                            Order{" "}
                                                                            {
                                                                                order.order_txn_id
                                                                            }
                                                                        </Text>

                                                                        <Row
                                                                            background={
                                                                                "red 0% 0% no-repeat padding-box;"
                                                                            }
                                                                            marginTop={
                                                                                8
                                                                            }
                                                                            borderRadius={
                                                                                "8px"
                                                                            }
                                                                            padding={
                                                                                "8px"
                                                                            }
                                                                            marginBottom={
                                                                                8
                                                                            }
                                                                            cursor={
                                                                                "pointer"
                                                                            }
                                                                            marginLeft={
                                                                                8
                                                                            }
                                                                            marginRight={
                                                                                8
                                                                            }
                                                                            alignItems={
                                                                                "center"
                                                                            }
                                                                            justifyContent={
                                                                                "space-between"
                                                                            }
                                                                        >
                                                                            <Text
                                                                                fontSize={
                                                                                    20
                                                                                }
                                                                                color={
                                                                                    "white"
                                                                                }
                                                                                textTransform={
                                                                                    "uppercase"
                                                                                }
                                                                                fontWeight={
                                                                                    "bold"
                                                                                }
                                                                            >
                                                                                Cancelled
                                                                            </Text>{" "}
                                                                        </Row>
                                                                    </Row>

                                                                    <Row
                                                                        marginTop={
                                                                            20
                                                                        }
                                                                        justifyContent={
                                                                            "space-between"
                                                                        }
                                                                    >
                                                                        <Row
                                                                            alignItems={
                                                                                "center"
                                                                            }
                                                                            justifyContent={
                                                                                "space-between"
                                                                            }
                                                                        >
                                                                            <Text
                                                                                fontSize={
                                                                                    16
                                                                                }
                                                                                textTransform={
                                                                                    "uppercase"
                                                                                }
                                                                                color={
                                                                                    "#a1a7b3"
                                                                                }
                                                                                fontWeight={
                                                                                    "bold"
                                                                                }
                                                                            >
                                                                                Payment
                                                                            </Text>
                                                                            <Text
                                                                                fontSize={
                                                                                    16
                                                                                }
                                                                                marginLeft={
                                                                                    8
                                                                                }
                                                                                textTransform={
                                                                                    "uppercase"
                                                                                }
                                                                                color={
                                                                                    "#0e0e0f"
                                                                                }
                                                                                fontWeight={
                                                                                    "bold"
                                                                                }
                                                                            >
                                                                                {
                                                                                    order.payment_method
                                                                                }
                                                                            </Text>
                                                                        </Row>

                                                                        <Row
                                                                            alignItems={
                                                                                "center"
                                                                            }
                                                                            justifyContent={
                                                                                "space-between"
                                                                            }
                                                                        >
                                                                            <Text
                                                                                fontSize={
                                                                                    16
                                                                                }
                                                                                textTransform={
                                                                                    "uppercase"
                                                                                }
                                                                                color={
                                                                                    "#a1a7b3"
                                                                                }
                                                                                fontWeight={
                                                                                    "bold"
                                                                                }
                                                                            >
                                                                                Distance
                                                                            </Text>
                                                                            <Text
                                                                                fontSize={
                                                                                    16
                                                                                }
                                                                                marginLeft={
                                                                                    8
                                                                                }
                                                                                textTransform={
                                                                                    "uppercase"
                                                                                }
                                                                                color={
                                                                                    "#0e0e0f"
                                                                                }
                                                                                fontWeight={
                                                                                    "bold"
                                                                                }
                                                                            >
                                                                                {
                                                                                    order.distance_km
                                                                                }{" "}
                                                                                km
                                                                            </Text>
                                                                        </Row>
                                                                    </Row>
                                                                    {order.waypoints && (
                                                                        <Row
                                                                            marginTop={
                                                                                20
                                                                            }
                                                                            marginBottom={
                                                                                8
                                                                            }
                                                                            justifyContent={
                                                                                "space-between"
                                                                            }
                                                                        >
                                                                            <Row>
                                                                                <DashIcon
                                                                                    name={
                                                                                        "location"
                                                                                    }
                                                                                    size={
                                                                                        24
                                                                                    }
                                                                                />
                                                                                <Text
                                                                                    textTransform={
                                                                                        "uppercase"
                                                                                    }
                                                                                    marginLeft={
                                                                                        16
                                                                                    }
                                                                                    fontSize={
                                                                                        14
                                                                                    }
                                                                                    fontWeight={
                                                                                        900
                                                                                    }
                                                                                >
                                                                                    Pickup
                                                                                </Text>
                                                                                <Text
                                                                                    fontSize={
                                                                                        14
                                                                                    }
                                                                                    marginLeft={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        order
                                                                                            .waypoints[0]
                                                                                            .pickup_location
                                                                                    }
                                                                                </Text>
                                                                            </Row>
                                                                            <DashIcon
                                                                                marginLeft={
                                                                                    10
                                                                                }
                                                                                size={
                                                                                    24
                                                                                }
                                                                                onClick={() =>
                                                                                    window.open(
                                                                                        trackingLink.pickup
                                                                                    )
                                                                                }
                                                                                name={
                                                                                    "right"
                                                                                }
                                                                            />
                                                                        </Row>
                                                                    )}
                                                                    {order.waypoints &&
                                                                        order.waypoints.map(
                                                                            (
                                                                                waypoint,
                                                                                index
                                                                            ) => {
                                                                                const idNum =
                                                                                    index +
                                                                                    1;
                                                                                return (
                                                                                    <Fragment>
                                                                                        <div
                                                                                            key={
                                                                                                waypoint.id
                                                                                            }
                                                                                            style={{
                                                                                                flexDirection:
                                                                                                    "row",
                                                                                                display:
                                                                                                    "flex",
                                                                                                marginTop:
                                                                                                    "8px",
                                                                                                marginBottom:
                                                                                                    "8px",
                                                                                                justifyContent:
                                                                                                    "space-between",
                                                                                            }}
                                                                                        >
                                                                                            <Row>
                                                                                                <DashIcon
                                                                                                    name={
                                                                                                        "location"
                                                                                                    }
                                                                                                    size={
                                                                                                        24
                                                                                                    }
                                                                                                />
                                                                                                <Text
                                                                                                    fontSize={
                                                                                                        14
                                                                                                    }
                                                                                                    textTransform={
                                                                                                        "uppercase"
                                                                                                    }
                                                                                                    fontWeight={
                                                                                                        900
                                                                                                    }
                                                                                                    marginLeft={
                                                                                                        16
                                                                                                    }
                                                                                                >
                                                                                                    Dropoff
                                                                                                    #
                                                                                                    {
                                                                                                        idNum
                                                                                                    }{" "}
                                                                                                </Text>
                                                                                                <Text
                                                                                                    fontSize={
                                                                                                        14
                                                                                                    }
                                                                                                    fontWeight={
                                                                                                        500
                                                                                                    }
                                                                                                    marginLeft={
                                                                                                        6
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        waypoint.dropoff_location
                                                                                                    }
                                                                                                </Text>
                                                                                            </Row>

                                                                                            {(() => {
                                                                                                if (
                                                                                                    waypoint.status ===
                                                                                                    "waypoint-complete"
                                                                                                ) {
                                                                                                    return (
                                                                                                        <Fragment>
                                                                                                            <Text
                                                                                                                fontSize={
                                                                                                                    14
                                                                                                                }
                                                                                                                marginLeft={
                                                                                                                    8
                                                                                                                }
                                                                                                                color={
                                                                                                                    "#0e0e0f"
                                                                                                                }
                                                                                                                textTransform={
                                                                                                                    "uppercase"
                                                                                                                }
                                                                                                                fontWeight={
                                                                                                                    "bold"
                                                                                                                }
                                                                                                            >
                                                                                                                Done
                                                                                                            </Text>
                                                                                                        </Fragment>
                                                                                                    );
                                                                                                } else {
                                                                                                    return (
                                                                                                        <Fragment>
                                                                                                            {" "}
                                                                                                            <DashIcon
                                                                                                                marginLeft={
                                                                                                                    10
                                                                                                                }
                                                                                                                size={
                                                                                                                    24
                                                                                                                }
                                                                                                                onClick={() =>
                                                                                                                    window.open(
                                                                                                                        trackingLink
                                                                                                                            .deliveries[
                                                                                                                            index
                                                                                                                        ]
                                                                                                                    )
                                                                                                                }
                                                                                                                name={
                                                                                                                    "right"
                                                                                                                }
                                                                                                            />
                                                                                                        </Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })()}
                                                                                        </div>
                                                                                    </Fragment>
                                                                                );
                                                                            }
                                                                        )}
                                                                </div>
                                                            </Fragment>
                                                        );
                                                    }
                                                })()}
                                            </div>
                                        </InnerWaypointContainer>
                                    );
                                default:
                                    break;
                            }
                        }
                    })()}
                </OrderContainer>

                <MapContainer className="desktop-only">
                    <Mapbox
                        lat={center.lat}
                        lng={center.lng}
                        firstWaypoint={firstWaypoint}
                        lastWaypoint={lastWaypoint}
                        waypoints={waypoints}
                        driver={driver}
                    />
                </MapContainer>
            </BodyContainer>
        </ViewContainer>
    );
};

export default OrderDetailsV1;

const ViewContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
`;

const InnerWaypointContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0px 40px 0px 40px;
    ${(props) =>
        props.justifyContent ? `justify-content:${props.justifyContent}` : ""};
    ${(props) => (props.alignItems ? `align-items:${props.alignItems}` : "")};
`;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
`;

const MapContainer = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
}
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const OrderContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.118854);
    background: #ffffff;
`;

const DriverContainer = styled.div`
    display: flex;
    flex-direction: row;
    box-shadow: inset 0 1px 0 0 #e1e2e6;
    justify-content: space-between;
    align-items: center;
    height: 160px;
`;

const Row = styled.div`
    ${(props) => (props.width ? `width:${props.width}` : "")};
    display: flex;
    flex-direction: row;
    align-items: center;
    ${(props) =>
        props.borderRadius ? `border-radius:${props.borderRadius}` : ""};
    ${(props) => (props.padding ? `padding:${props.padding}` : "")};
    ${(props) => (props.background ? `background:${props.background}` : "")};
    ${(props) => (props.border ? `border:${props.border}` : "")};
    ${(props) => (props.cursor ? `cursor:${props.cursor}` : "")};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) =>
        props.paddingRight ? `padding-right:${props.paddingRight}px` : ""};
    ${(props) =>
        props.paddingRight ? `padding-left:${props.paddingRight}px` : ""};
    ${(props) =>
        props.paddingRight ? `padding-top:${props.paddingRight}px` : ""};
    ${(props) =>
        props.paddingBottom ? `padding-bottom:${props.paddingBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
    ${(props) =>
        props.justifyContent
            ? `justify-content:${props.justifyContent}`
            : "space-between"};
`;

const Avatar = styled.img`
    width: ${(props) => props.size || 48}px;
    height: ${(props) => props.size || 48}px;
    position: ${(props) => props.position};
    top: ${(props) => props.top};
    border-radius: ${(props) => props.borderRadius || 50}%;
    object-fit: cover;
    ${(props) => (props.cursor ? `cursor:${props.cursor}` : "")};
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
    ${(props) => (props.gridArea ? `grid-area: ${props.gridArea};` : "")};
    ${(props) =>
        props.gridColumn ? `grid-column: span ${props.gridColumn};` : ""};
`;
