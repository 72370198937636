import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.img`
  width: ${(props) => props.width || 24}px;
  height: ${(props) => props.height || 24}px;
  object-fit: ${(props) => props.objectFit || 'contain'};
  cursor: ${(props) => props.cursor || ''};

  ${(props) =>
    props.verticalAlign ? `vertical-align:${props.verticalAlign}` : ''};
  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) => (props.margin ? `margin:${props.margin}` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
  ${(props) => (props.gridArea ? `grid-area: ${props.gridArea};` : '')};
  ${(props) => (props.color ? `color: ${props.color};` : '')}
`;
const Icon = ({ gridArea, src, svg, ...props }) => {
  return <IconContainer gridArea={gridArea} src={src || svg} {...props} />;
};

export default Icon;
