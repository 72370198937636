import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";

import * as api from "../../api/index";

import Text from "../../components/molecules/text";
import Header from "../../components/organisms/header/index";
import Button from "../../components/molecules/button";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import AreaCode from "../../assets/icons/pngs/areaCode.png";
import { stubTrue } from "lodash";
import useInterval from "../../components/hooks/polling";

const SubAccount = ({ history, match }) => {
    const theme = localStorage.getItem("theme") + "-mode";
    let api_token;
    var field = "user_id";
    var url = window.location.href;
    if (url.includes("?" + field + "=")) {
        const index = url.indexOf("=") + 1;
        const userId = url.slice(index, url.length);
        api_token = window.localStorage.getItem(`${userId}`);
    } else {
        api_token = localStorage.getItem("api_token");
    }

    const [user, setUser] = useState({});
    const [msg, setMsg] = useState("");
    const [subAccounts, setSubAccounts] = useState([]);

    const [newSubAccountName, setNewSubAccountName] = useState("");
    const [newSubAccountEmail, setNewSubAccountEmail] = useState("");
    const [newSubAccountPhone, setNewSubAccountPhone] = useState("");
    const [addSubAccount, setAddAccount] = useState(false);
    const [accountsMenu, setAccountsMenu] = useState(true);

    if (!api_token) {
        history.push("/login");
    } else if (typeof api_token === "object") {
        if (api_token.message.includes("invalid")) {
            localStorage.clear();
            history.push("/login");
        }
    }

    const getUser = () => {
        api.getUserDetails({
            api_token,
        }).then((result) => {
            if (result.error === "invalid_api_key") {
                localStorage.removeItem("api_token");
                history.push("/login");
            } else {
                setUser(result);
            }
        });
    };

    const getAllAccounts = () => {
        api.getApiAccess({ api_token }).then((result) => {
            if (result) {
                console.log("getSubAccounts", result.sub_accounts);
                setSubAccounts(result.sub_accounts);
            }
        });
    };

    const addNewSubAccount = () => {
        api.addSubAccount({
            name: newSubAccountName,
            email: newSubAccountEmail,
            phone_number: newSubAccountPhone,
            api_token,
        }).then((result) => {
            if (result.success === true) {
                setAddAccount(!addSubAccount);
                setAccountsMenu(!accountsMenu);
            } else if (result.success === false) {
                console.log("Adding Sub Acocunt issue,", result);
                confirmAlert({
                    title: "Error Saving Sub Account",
                    message:
                        "There was an error saving this sub account. Please try again.",
                    buttons: [
                        {
                            label: "Okay",
                            onClick: () =>
                                setMsg("Please provide a valid phone number."),
                        },
                    ],
                });
            } else if (result.message.includes("lastname")) {
                console.log("Adding Sub Acocunt issue,", result);
                confirmAlert({
                    title: "Last Name Requried",
                    message: "Please add a last name and try again.",
                    buttons: [
                        {
                            label: "Okay",
                            onClick: () =>
                                setMsg("Please provide a valid phone number."),
                        },
                    ],
                });
            } else if (result.message.includes("phone")) {
                console.log("Adding Sub Acocunt issue,", result);
                confirmAlert({
                    title: "Phone Number already in use",
                    message:
                        "This phone number already exists. Please use another one",
                    buttons: [
                        {
                            label: "Okay",
                            onClick: () =>
                                setMsg("Please provide a valid phone number."),
                        },
                    ],
                });
            }
        });
    };
    const logoutUser = () => {
        localStorage.removeItem("api_token");
        history.push("/login");
    };

    const loginToSubAccount = ({ user_id }) => {
        api.loginSubAccount({
            user_id,
            api_token,
        }).then((result) => {
            console.log("loginSubAccount", result);
            if (result.user_id) {
                console.log("widhow.loca", window.location);
                const linkUrl = `${window.location.origin}/#/dashboard?user_id=${result.user_id}`;
                window.localStorage.setItem(
                    `${result.user_id}`,
                    result.api_token
                );
                window.open(linkUrl);
            } else if (!result.user_id) {
                confirmAlert({
                    title: "Error logging into sub-account",
                    message:
                        "There was an error logging into this sub account. Please try again.",
                    buttons: [
                        {
                            label: "Okay",
                            onClick: () => setMsg(""),
                        },
                    ],
                });
            }
        });
    };
    useEffect(() => {
        getAllAccounts();
        getUser();
    }, []);

    // FIX    : sajjads hacky fix for now
    // REASON : we were calling this API 1-2x a second when it was in useEffect
    // getAllAccounts();
    useInterval(() => {
        getAllAccounts();
    }, 5000);

    const renderHeader = () => {
        let headerElement = ["id", "name", "email", "phone", "total orders"];

        return headerElement.map((key, index) => {
            return (
                <th
                    style={{
                        paddingRight: "80px",
                    }}
                    key={index}
                >
                    {key.toUpperCase()}
                </th>
            );
        });
    };

    const renderBody = () => {
        return (
            subAccounts &&
            subAccounts.map(
                ({
                    user_id,
                    name,
                    email,
                    phone_number,
                    total_orders_created,
                }) => {
                    return (
                        <tr
                            key={user_id}
                            style={{
                                height: "80px",
                                boxShadow: "inset 0 -1px 0 0 #e1e2e6",
                            }}
                        >
                            <td
                                style={{
                                    marginRight: "90px",
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "1.43",

                                    fontWeight: "bold",
                                }}
                            >
                                {user_id}
                            </td>
                            <td
                                style={{
                                    textTransform: "capitalize",
                                    marginRight: "90px",
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "1.43",
                                }}
                            >
                                {name}
                            </td>
                            <td
                                style={{
                                    marginRight: "90px",
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "1.43",
                                }}
                            >{`${email}`}</td>
                            <td
                                style={{
                                    marginRight: "90px",
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "1.43",
                                }}
                            >{`${phone_number}`}</td>
                            <td
                                style={{
                                    marginRight: "100px",
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "1.43",
                                }}
                            >{`${total_orders_created}`}</td>
                            <td>
                                <OrderButton
                                    onClick={() => {
                                        loginToSubAccount({ user_id });
                                    }}
                                >
                                    Login
                                </OrderButton>
                            </td>
                        </tr>
                    );
                }
            )
        );
    };

    return (
        <ViewContainer className={theme}>
            <Header history={history} />
            <BodyDiv>
                {accountsMenu && (
                    <SubaccountsContainer>
                        <div
                            style={{
                                height: "120px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Text
                                fontSize={32}
                                marginTop={40}
                                marginBottom={40}
                                fontWeight={"bold"}
                            >
                                Sub Accounts
                            </Text>
                            <Button
                                width={175}
                                height={40}
                                borderRadius={8}
                                marginLeft={16}
                                onClick={() => {
                                    setAccountsMenu(!accountsMenu);
                                    setAddAccount(!addSubAccount);
                                }}
                            >
                                Add Sub Account
                            </Button>
                        </div>

                        {subAccounts.length >= 1 && (
                            <Fragment>
                                <table id="accounts">
                                    <thead>
                                        <tr
                                            style={{
                                                height: "60px",
                                                fontFamily: "Inter",
                                                textAlign: "left",
                                                color: "#a1a7b3",
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                boxShadow:
                                                    "inset 0 -1px 0 0 #e1e2e6",
                                            }}
                                        >
                                            {renderHeader()}
                                        </tr>
                                    </thead>
                                    <tbody>{renderBody()}</tbody>
                                </table>
                            </Fragment>
                        )}

                        {subAccounts.length < 1 && (
                            <table id="accounts">
                                <thead>
                                    <tr
                                        style={{
                                            height: "60px",
                                            fontFamily: "Inter",
                                            textAlign: "left",
                                            color: "#a1a7b3",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            boxShadow:
                                                "inset 0 -1px 0 0 #e1e2e6",
                                        }}
                                    >
                                        {renderHeader()}
                                    </tr>
                                </thead>

                                <Text marginTop={16}>
                                    No subaccounts for this profile.
                                </Text>
                            </table>
                        )}
                    </SubaccountsContainer>
                )}

                {addSubAccount && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0px 250px 0px 250px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Text
                                fontSize={24}
                                marginTop={40}
                                marginBottom={8}
                                color={"#a1a7b3"}
                                fontWeight={"bold"}
                            >
                                New Sub-Account
                            </Text>
                            <Text
                                fontSize={14}
                                color={"#0e0e0f"}
                                fontWeight={"bold"}
                                marginBottom={16}
                            >
                                Please provide the following information about
                                application:
                            </Text>
                        </div>

                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <Input
                                placeholder="Sub Account Name"
                                marginTop={15}
                                value={newSubAccountName}
                                onChange={(e) => {
                                    setNewSubAccountName(e.target.value);
                                }}
                            />
                            <Input
                                placeholder="Sub Account Email"
                                marginTop={15}
                                value={newSubAccountEmail}
                                onChange={(e) => {
                                    setNewSubAccountEmail(e.target.value);
                                }}
                            />
                            <PhoneInput
                                type="text"
                                name={newSubAccountPhone}
                                autocomplete="new-password"
                                value={newSubAccountPhone}
                                onChange={(e) =>
                                    setNewSubAccountPhone(e.target.value)
                                }
                                placeholder="Sub Account's Phone Number"
                                marginTop={10}
                                marginBottom={20}
                            />
                            <Row marginTop={20} justifyContent={"flex-end"}>
                                <Button
                                    width={140}
                                    height={40}
                                    backgroundColor={"light-gray"}
                                    color={"dark-gray"}
                                    borderRadius={10}
                                    onClick={() => {
                                        setAddAccount(!addSubAccount);
                                        setAccountsMenu(!accountsMenu);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    marginLeft={8}
                                    width={140}
                                    height={40}
                                    borderRadius={8}
                                    onClick={addNewSubAccount}
                                >
                                    Confirm
                                </Button>
                            </Row>
                        </div>
                    </div>
                )}
            </BodyDiv>

            {/* {user.email ? (
          <FreshChat
            token={process.env.REACT_APP_FRESHCHAT}
            email={'info@gokada.ng'}
            onInit={(widget) => {
              widget.user.setProperties({
                email: user.email,
                first_name: user.name,
                // last_name: user.lastName,
                // phone: user.phoneNumber,
              });
            }}
          />
        ) : null}  */}
        </ViewContainer>
    );
};

export default SubAccount;

const OrderButton = styled.button`
    width: ${(props) => props.width || 120}px;
    height: ${(props) => props.height || 40}px;
    border-radius: ${(props) => props.borderRadius || 8}px;
    border: none;
    cursor: pointer;
    align-items: center;
    font-size: ${(props) => props.fontSize || 14}px;
    font-family: Inter;
    font-weight: ${(props) => props.fontWeight || 900};
    color: white;
    margin-left: 8px;
    background-color: #00c795;
    ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}px;` : "")}
    ${(props) =>
        props.marginLeft ? `margin-left: ${props.marginLeft}px;` : ""}
    ${(props) =>
        props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : ""}
    ${(props) =>
        props.marginRight ? `margin-right: ${props.marginRight}px;` : ""};
`;

const ViewContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
`;

const BodyDiv = styled.div`
    display: flex;
    align-items: center;
`;

const SubaccountsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 250px 20px 250px;
    width: 940px;
`;

const Input = styled.input`
    width: 580px;
    height: 40px;
    border-radius: 8px;
    border: solid 2px #00c795;
    font-family: "Inter";
    text-align: left;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginTop ? `margin-top:${props.marginTop}px` : "10px"};
    ${(props) =>
        props.marginLeft ? `margin-left:${props.marginLeft}px` : "10px"};
`;
const PhoneInput = styled.input`
    width: 530px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: solid 2px #00c795;
    border-radius: 8px;
    font-size: 14px;
    font-family: "Inter";
    text-align: left;
    padding-left: 60px;
    padding-right: 10px;
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
    background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
    background-position: left;
    background-size: 50px;
    background-repeat: no-repeat;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
    ${(props) =>
        props.justifyContent ? `justify-content:${props.justifyContent}` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) =>
        props.overFlowWrap ? `overflow-wrap:${props.overFlowWrap}` : ""};
`;
