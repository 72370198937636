import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MaterialUiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  container: {
    textTransform: (props) => props.textTransform,
    marginLeft: (props) => props.marginLeft,
    marginRight: (props) => props.marginRight,
    marginBottom: (props) => props.marginBottom,
    marginTop: (props) => props.marginTop,
    gridArea: (props) => props.gridArea,
    fontWeight: (props) => props.fontWeight,
    width: (props) => props.width,
    fontFamily: (props) => props.fontFamily || 'Inter',
    textAlign: (props) => props.textAlign,
    color: (props) => props.color,
    position: (props) => props.position,
    textOverflow: 'ellipsis',
    fontSize: (props) => props.fontSize,
    cursor: (props) => props.cursor,
    lineHeight: (props) => `${props.lineHeight}px` || '1',

    alignItems: (props) => props.alignItems || 'center',
  },
});

const Text = ({ children, onClick, ...props }) => {
  const classes = useStyles(props);

  return (
    <MaterialUiTypography
      className={classes.container}
      variant="body1"
      fontSize="24"
      onClick={onClick}
    >
      {children}
    </MaterialUiTypography>
  );
};

export default Text;
