import React, { useState, useEffect, useRef, Fragment } from "react";
import styled from "styled-components";
import * as api from "../../api/index";
import { uuid } from "uuidv4";

import Button from "../../components/molecules/button";
import Text from "../../components/molecules/text";

import Header from "../../components/organisms/header/index";
import Footer from "../../components/organisms/footer/index";

import AreaCode from "../../assets/icons/pngs/areaCode.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import useInterval from "../../components/hooks/polling";

import { useMediaQuery } from "react-responsive";
import './../../App.css'

const ResetPassword = ({ history }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-device-width: 1224px)",
    });
    const isTabletOrMobileDevice = useMediaQuery({
        query: "(max-device-width: 1224px)",
    });

    const [resetForm, setResetForm] = useState(true);
    const [verifiedToken, setVerifiedToken] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fullName, setFullName] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [phoneMessage, setPhoneSubmessage] = useState("");

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    // const handleLogin = () => {
    //     localStorage.clear();
    //     sessionStorage.clear();
    //     const device_id = uuid();
    //     const device_type = "web";

    //     if (phoneNumber.length >= 12) {
    //         confirmAlert({
    //             title: "Phone Number Too Long",
    //             message:
    //                 "Please provide a valid phone number that is less than 12 digits",
    //             buttons: [
    //                 {
    //                     label: "Okay",
    //                     onClick: () =>
    //                         setPhoneSubmessage("Phone number is too long"),
    //                 },
    //             ],
    //         });
    //     } else if (
    //         !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
    //             phoneNumber
    //         )
    //     ) {
    //         confirmAlert({
    //             title: "Phone Number Required",
    //             message:
    //                 "Please provide a phone number to successfully login / register.",
    //             buttons: [
    //                 {
    //                     label: "Okay",
    //                     onClick: () =>
    //                         setPhoneSubmessage(
    //                             "Please provide a valid phone number."
    //                         ),
    //                 },
    //             ],
    //         });
    //     } else {
    //         let splicedNumber;
    //         if (phoneNumber[0] === "0") {
    //             splicedNumber = phoneNumber.substring(1);
    //         } else {
    //             splicedNumber = phoneNumber;
    //         }

    //         api.userLogin({
    //             device_id,
    //             device_type,
    //             phone_number: splicedNumber,
    //         })
    //             .then((result) => {
    //                 console.log("loginUser", result);
    //                 if (result.new_user === true) {
    //                     setUssdCode(result.ussd_code);
    //                     setVerifiedToken(result.verify_token);
    //                     setLoginForm(false);
    //                     setVerifyView(true);
    //                 } else if (result.error === "invalid_input") {
    //                     confirmAlert({
    //                         title: "Valid Phone Number Required",
    //                         message:
    //                             "Please provide a valid phone number to successfully login / register.",
    //                         buttons: [
    //                             {
    //                                 label: "Okay",
    //                                 onClick: () =>
    //                                     setPhoneSubmessage(
    //                                         "Please provide a valid phone number."
    //                                     ),
    //                             },
    //                         ],
    //                     });
    //                 } else if (result.new_user === false) {
    //                     setUssdCode(result.ussd_code);
    //                     setVerifiedToken(result.verify_token);
    //                     setLoginForm(false);
    //                     setVerifyView(true);
    //                     // setRegisterForm(true);
    //                     // history.push('/')
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log("Login Error:", err);
    //             });
    //     }
    // };

    const handleReset = () => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email == "" || !re.test(String(email).toLowerCase())) {
            confirmAlert({
                title: "Error",
                message: "Please enter a valid email address",
                buttons: [
                    {
                        label: "Okay",
                        onClick: () => {},
                    },
                ],
            });
            return;
        }

        api.passwordReset({
            email
        })
            .then((result) => {
                // if (result.new_user === true) {
                //     setUssdCode(result.ussd_code);
                //     setVerifiedToken(result.verify_token);
                //     setVerifyForm(result.verify_token);
                //     setLoginForm(false);
                //     setVerifyForm(true);
                // } else if (result.new_user === false) {
                //     setUssdCode(result.ussd_code);
                //     setVerifiedToken(result.verify_token);
                //     setVerifyForm(result.verify_token);
                //     setLoginForm(false);
                //     setVerifyForm(true);
                // }
                if (result.success) {
                    setResetForm(false);
                } else {
                    confirmAlert({
                        title: "Error",
                        message: result.message,
                        buttons: [
                            {
                                label: "Okay",
                                onClick: () => {},
                            },
                        ],
                    });
                }
            })
            .catch((err) => {
                console.log("Login Error:", err);
                // debugger;
            });
    };

    return (
        <Fragment>
            <div style={{ width: "100vw", height: "100vh" }}>
                {isDesktopOrLaptop && (
                    <Container>
                         <Header gridArea="header" history={history} />

                        <MainContainer gridArea="main">
                            {resetForm && (
                                <InnerContainer>
                                    <div style={{ height: "80px",textAlign: 'center' }} className="login-container">
                                        <div className="login-header">
                                            Reset Password
                                        </div>
                                        <div className="login-header-sub">
                                            Recover your account
                                        </div>
                                    </div>

                                    <VerifyForm marginTop={0}>
                                        <LoginInput
                                            placeholder="Email address"
                                            marginTop={15}
                                            value={email}
                                            onChange={handleEmailChange}
                                            autoFocus
                                        />

                                        <Text
                                            marginTop={3}
                                            fontSize={10}
                                            color={"red"}
                                        >
                                            {phoneMessage}
                                        </Text>
                                        <Button
                                            width={327}
                                            height={44}
                                            borderRadius={10}
                                            marginTop={10}
                                            marginBottom={24}
                                            onClick={handleReset}
                                        >
                                            Reset Password
                                        </Button>
                                    </VerifyForm>
                                </InnerContainer>
                            )}
                            {!resetForm && (
                                <InnerContainer>
                                    <div style={{ height: "120px" }}>
                                        <Text
                                            fontSize={32}
                                            marginTop={40}
                                            marginBottom={40}
                                            fontWeight={"bold"}
                                        >
                                            A reset link has been sent to your email.
                                        </Text>
                                    </div>

                                    <LoginLink
                                        onClick={() => {
                                            history.push('/login');
                                        }}
                                        justify="center"
                                        fontSize="18"
                                        
                                    >
                                        Back to Login
                                    </LoginLink>
                                </InnerContainer>
                            )}
                        </MainContainer>
                    </Container>
                )}

                {isTabletOrMobileDevice && (
                    <MobileContainer>
                        <Header gridArea="header" history={history} />

                        <MainContainer>
                            {resetForm && (
                                <InnerContainer>
                                    <div style={{ height: "120px" }}>
                                        <Text
                                            fontSize={32}
                                            marginTop={40}
                                            marginBottom={40}
                                            fontWeight={"bold"}
                                        >
                                            Reset Password
                                        </Text>
                                    </div>

                                    <VerifyForm marginTop={60}>
                                        <Text fontSize={16} fontWeight={700}>
                                            Please enter your email address:
                                        </Text>

                                        <LoginInput
                                            placeholder="Email address"
                                            marginTop={15}
                                            value={email}
                                            onChange={handleEmailChange}
                                        />

                                        <Text
                                            marginTop={3}
                                            fontSize={10}
                                            color={"red"}
                                        >
                                            {phoneMessage}
                                        </Text>
                                        <Button
                                            width={480}
                                            height={48}
                                            borderRadius={10}
                                            marginTop={10}
                                            marginBottom={24}
                                            onClick={handleReset}
                                        >
                                            Login
                                        </Button>
                                        <LoginLink
                                            onClick={() => {
                                                history.push('/login');
                                            }}
                                        >
                                            Reset
                                        </LoginLink>
                                    </VerifyForm>
                                </InnerContainer>
                            )}
                            {!resetForm && (
                                <InnerContainer>
                                    <div style={{ height: "120px" }}>
                                        <Text
                                            fontSize={32}
                                            marginTop={40}
                                            marginBottom={40}
                                            marginRight={30}
                                            marginLeft={30}
                                            fontWeight={"bold"}
                                            textAlign={"center"}
                                        >
                                            A reset link has been sent to your email.
                                        </Text>
                                    </div>

                                    <LoginLink
                                        onClick={() => {
                                            history.push('/login');
                                        }}
                                        justify="center"
                                        fontSize="18"
                                        paddingTop="50"
                                    >
                                        Back to Login
                                    </LoginLink>
                                </InnerContainer>
                            )}
                        </MainContainer>

                        <Footer gridArea="footer" />
                    </MobileContainer>
                )}
            </div>
        </Fragment>
    );
};

export default ResetPassword;

const Container = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
`;

const MobileContainer = styled.div`
    display: flex;
    height: 1223px;
    width: fit-content;
    flex-direction: column;
`;

const MainContainer = styled.div`
    height: 100%;
    grid-area: ${(props) => props.gridArea};
    padding-bottom: 100px;
`;

const PhoneButton = styled.button`
    width: ${(props) => props.width || 50}px;
    height: ${(props) => props.height || 50}px;
    border-radius: ${(props) => props.borderRadius || 8}px;
    justify-content: center;
    border: 2px solid #00c795;
    cursor: pointer;
    align-items: center;
    background-color: transparent;
    font-size: ${(props) => props.fontSize || 18}px;
    font-family: "Inter";
    font-weight: ${(props) => props.fontWeight || 900};
    color: #000000;
    ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}px;` : "")}
    ${(props) =>
        props.marginLeft ? `margin-left: ${props.marginLeft}px;` : ""}
    ${(props) =>
        props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : ""}
    ${(props) =>
        props.marginRight ? `margin-right: ${props.marginRight}px;` : ""};
`;

const InnerContainer = styled.div`
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
    flex: 1;
`;

const VerifyForm = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 327px;
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

const LoginInput = styled.input`
    width: 100%;
    height: 44px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #00c795;
    border-radius: 10px;
    font-size: 14px;
    font-family: "Inter";
    background: #F8F9FB;
    border: 1px solid #BCC1CC;
    border-radius: 8px;

    // background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
    // background-position: left;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    background-size: 60px;
    background-repeat: no-repeat;
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

const LoginLink = styled.a`
    width: 95%;
    justify-content: end;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    cursor: pointer;
    display: flex;
    margin-top: 10px;
    ${(props) => (props.justify ? `justify-content: ${props.justify}` : "")};
    ${(props) => (props.fontSize ? `font-size: ${props.fontSize}px` : "")};
    ${(props) => (props.paddingTop ? `padding-top: ${props.paddingTop}px` : "")};
`;
