import React from 'react';
import styled from 'styled-components';

import addPNG from './png/add.png';

const ActionButtonContainer = styled.div`
  background-color: #00c795;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 2px 2px 2px;
  cursor: pointer;
`;

const ActionButtonIcon = styled.img`
  width: 100%;
  height: auto;
`;

const ActionButton = ({ onClick }) => {
  return (
    <ActionButtonContainer onClick={onClick}>
      <ActionButtonIcon src={addPNG} />
    </ActionButtonContainer>
  );
};

export default ActionButton;
