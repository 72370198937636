import React, { useState, useEffect, useRef, Fragment } from "react";
import styled from "styled-components";
import * as api from "../../api/index";

import { uuid } from "uuidv4";

import Button from "../../components/molecules/button";
import Text from "../../components/molecules/text";

import Header from "../../components/organisms/header/index";
import Footer from "../../components/organisms/footer/index";

import AreaCode from "../../assets/icons/pngs/areaCode.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import useInterval from "../../components/hooks/polling";
import './../../App.css'

import { useMediaQuery } from "react-responsive";
import Loading from '../../assets/loading.gif';

const Register = ({ history }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-device-width: 1224px)",
    });
    const isTabletOrMobileDevice = useMediaQuery({
        query: "(max-device-width: 1224px)",
    });

    const [loginForm, setLoginForm] = useState(true);
    const [registerForm, setRegisterForm] = useState(false);
    const [verfiyView, setVerifyView] = useState(false);
    const [verifiedToken, setVerifiedToken] = useState("");
    const [loaded, setLoaded] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [fullName, setFullName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [tos, setTos] = useState(false);
    const [code, setCode] = useState("");
    const [ussdCode, setUssdCode] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [emailMessage, setEmailSubmessage] = useState("");
    const [lowercaseMessage, setLowercaseSubmessage] = useState("");
    const [uppercaseMessage, setUppercaseSubmessage] = useState("");
    const [digitMessage, setDigitSubmessage] = useState("");
    const [nameMessage, setNameSubmessage] = useState("");
    const [phoneMessage, setPhoneSubmessage] = useState("");
    const [confirmMessage, setConfirmSubmessage] = useState("");
    const [newPhoneNumber, setNewPhoneNumber] = useState("");
    const [newPhoneNumberError, setNewPhoneNumberError] = useState("");
    const [updateView, setUpdateView] = useState(false);
    const [userId, setUserId] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const formattedPhoneNumber = phoneNumber.length === 10 ? `0${phoneNumber}` : phoneNumber;

    const theme = localStorage.getItem("theme") + "-mode";
    let api_token;
    const field = "user_id";
    const url = window.location.href;
    if (url.includes("?" + field + "=")) {
        const index = url.indexOf("=") + 1;
        const userId = url.slice(index, url.length);
        api_token = window.localStorage.getItem(`${userId}`);
    } else {
        api_token = localStorage.getItem("api_token");
    }

    // useEffect(() => {
    //     if(apiToken != '') {
    //         alert(apiToken)
    //         setApiKey(apiToken)
    //     }
    // }, []);

    let getUser = function() {
        if(api_token == null) {
            setLoaded(true);
            return;
        }
        api
        .getUserDetails({ api_token })
        .then((result) => {
            if(!result.phone_verified) {
                setPhoneNumber(result?.phone_number || '');
                setUserId(result.user_id)
                clearForm2()
                setLoaded(true);
            } else {
                history.push("/settings");
            }
        });
    }
    const refParam = history.location.state?.ref || undefined;
    useEffect(() => {
        getUser()
    }, [getUser]);

    useEffect(() => {
        if (newPhoneNumber.length === 11 && newPhoneNumber.startsWith("0") && newPhoneNumberError) {
            setNewPhoneNumberError(false)
        }
    }, [newPhoneNumber, newPhoneNumberError])

    const getOS = () => {
        let userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
            windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
            iosPlatforms = ["iPhone", "iPad", "iPod"],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = "Mac OS";
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = "iOS";
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = "Windows";
        } else if (/Android/.test(userAgent)) {
            os = "Android";
        } else if (!os && /Linux/.test(platform)) {
            os = "Linux";
        }

        return `${os} - ${platform}`;
    };

    const clearForm = () => {
        setEmail("");
        setPhoneNumber("");
        setFirstName("");
        setLastName("");
        setPassword("");
        setPasswordConfirm("");
    };

    const clearForm2 = () => {
        setLoginForm(false);
        setVerifyView(true);
    };

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordConfirm = () => {
        setShowPasswordConfirm(!showPasswordConfirm);
    };

    const handleNumberChange = (e) => {
        let telephone = e.target.value;

        // if value is not blank, then test the regex
        if (telephone === "" || /^[0-9\b]+$/.test(telephone)) {
            setPhoneNumber(telephone);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlefullNameChange = (e) => {
        setFullName(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handlePasswordChangeConfirm = (e) => {
        setPasswordConfirm(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };

    const handleNewPhoneChange = (e) => {
        setNewPhoneNumber(e.target.value);
    };

    const sendOtpCode = (token) => {
        api.sendUserOTP({ 'api_token': token ?? api_token, 'type': 'phone' }).then(
            (result) => {
                if (result.success === true) {
                    confirmAlert({
                        title: "Verify Phone",
                        message:
                            "A text message has been sent to you. Please enter the code sent.",
                        buttons: [
                            {
                                label: "Ok",
                                onClick: function() {
                                    clearForm2()
                                    setLoaded(true);
                                }
                            },
                        ],
                    });
                    // history.push("/dashboard");
                }
            }
        );
    }
    const handleSignUp = () => {
        // user/send_otp
        // setLoginForm(false);
        // setVerifyView(true);
        // return;

        if(!tos) {
            confirmAlert({
                title: "Terms of Service",
                message: "Please Agree to Terms of Service",
                buttons: [
                    {
                        label: "Ok",
                    },
                ],
            });
            return;
        }

        if (fullName.length <= 4) {
            confirmAlert({
                title: "Name Required",
                message: "Please fill in a full name",
                buttons: [
                    {
                        label: "Ok",
                    },
                ],
            });
        } else if (!/^.+@.+\.[a-z\.]+$/.test(email)) {
            confirmAlert({
                title: "Valid Email Required ",
                message:
                    "Please provide a valid email address to successfully register.",
                buttons: [
                    {
                        label: "Ok",
                        onClick: () =>
                            setEmailSubmessage(
                                "Please provide a valid email address."
                            ),
                    },
                ],
            });
        } else if (!validate({ phone: phoneNumber })) {
            confirmAlert({
                title: "Valid Phone Number Required ",
                message:
                    "Please provide a valid phone number to successfully register.",
                buttons: [
                    {
                        label: "Ok",
                        onClick: () =>
                            setEmailSubmessage(
                                "Please provide a valid phone number."
                            ),
                    },
                ],
            });
        } else if (!validate({ password: password })) {
            confirmAlert({
                title: "Password Required",
                message:
                    "Please fill in the password fields to secure your account",
                buttons: [
                    {
                        label: "Ok",
                    },
                ],
            });
        } else {
            setLoaded(false);

            const device_id = getOS();
            const device_type = "web";

            let name = fullName.split(' ');

            api.signUpUser({
                email,
                phone_number: phoneNumber,
                password,
                first_name: name[0] ?? '',
                last_name: name[1] ?? '',
                device_id,
                device_type,
                country_code: '234',
                is_business: true,
                ref: refParam
            }).then((res) => {
                if (res.error) {
                    confirmAlert({
                        title: "Error",
                        message: res.message,
                        buttons: [
                            {
                                label: "Ok",
                            },
                        ],
                    });
                    setLoaded(true);
                } else if (res.api_token && !res.phone_verified) {
                    localStorage.setItem("theme", "light");
                    localStorage.setItem("api_token", res.api_token);
                    setApiKey(res.api_token);
                    sendOtpCode(res.api_token)
                } else if (res.api_token) {
                    localStorage.setItem("theme", "light");
                    setLoaded(true);
                    localStorage.setItem("api_token", res.api_token);
                    setApiKey(res.api_token);
                    history.push("/settings");
                }
            });
        }
    };

    const handleVerify = () => {
        setLoaded(false);
        api.verifyUser({
            code: code,
            type: 'phone',
            api_token: api_token
        })
            .then((result) => {
                if(result.success) {
                    history.push("/settings");
                } else {
                    confirmAlert({
                        title: "Error",
                        message: "Invalid Code",
                        buttons: [
                            {
                                label: "Ok",
                            },
                        ],
                    });
                    setLoaded(true);
                }
            })
            .catch((err) => {
                setLoaded(true);
                console.log("Verify Error:", err);
            });
    };

    const handleUpdate = () => {
        if (!newPhoneNumber.startsWith("0") || newPhoneNumber.length !== 11) {
            setNewPhoneNumberError(true)
        } else {
            setLoaded(false);
            api.updateUser({
                id: userId,
                phone_number: newPhoneNumber.slice(1),
                api_token: api_token
            }).then((result) => {
                if (result.error) {
                    confirmAlert({
                        title: "Error",
                        message: result.message,
                        buttons: [
                            {
                                label: "Ok",
                            },
                        ],
                    });
                } else {
                    setUpdateView(false);
                    setPhoneNumber(newPhoneNumber)
                    sendOtpCode(api_token);
                }
            }).catch((error) => {
                setLoaded(true);
                console.log("Update Error:", error);
            }).finally(() => setLoaded(true))
        }
    }

    const goToUpdateView = () => {
        setVerifyView(false);
        setUpdateView(true)
    }

    async function validate({ phone, email, password, passwordConfirm }) {
        if (phone) {
            //Check if phone number is nigerian number
            if (
                !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
                    phone
                ) &&
                phone.length
            ) {
                await fixThis("phone", "Please provide a valid phone number");
            } else {
                setPhoneSubmessage("");
            }
        }

        if (email) {
            //Check if email is email@domain.com format
            if (!/^.+@.+\.[a-z\.]+$/.test(email) && email.length) {
                await fixThis(
                    "email",
                    "Please provide a valid email address (johndoe@gmail.com)"
                );
            } else {
                setEmailSubmessage("");
            }
        }

        if (password && !passwordConfirm) {
            if (!/^.*[a-z]/.test(password) && password.length) {
                await fixThis(
                    "lowercase",
                    "The password must contain at least one lowercase letter"
                );
            } else {
                setLowercaseSubmessage("");
            }

            if (!/^.*[A-Z]/.test(password) && password.length) {
                await fixThis(
                    "uppercase",
                    "The password must contain at least one uppercase letter"
                );
            } else {
                setUppercaseSubmessage("");
            }

            if (!/^.*[0-9]/.test(password) && password.length) {
                await fixThis(
                    "digit",
                    "The password must contain at least one digit"
                );
            } else {
                setDigitSubmessage("");
            }
        }

        if (passwordConfirm) {
            if (passwordConfirm !== password && passwordConfirm.length) {
                await fixThis("passwordConfirm", "Your passwords do not match");
            } else {
                setConfirmSubmessage("");
            }
        }

        async function fixThis(type, subMessage) {
            switch (type) {
                case "name":
                    await setNameSubmessage("");
                    setNameSubmessage(subMessage);
                    break;
                case "phone":
                    await setPhoneSubmessage("");
                    setPhoneSubmessage((o) => [...o, subMessage]);
                    break;
                case "email":
                    await setEmailSubmessage("");
                    setEmailSubmessage(subMessage);
                    break;
                case "lowercase":
                    await setLowercaseSubmessage("");
                    setLowercaseSubmessage(subMessage);
                    break;
                case "uppercase":
                    await setUppercaseSubmessage("");
                    setUppercaseSubmessage(subMessage);
                    break;
                case "digit":
                    await setDigitSubmessage("");
                    setDigitSubmessage(subMessage);
                    break;
                case "passwordConfirm":
                    await setConfirmSubmessage("");
                    setConfirmSubmessage(subMessage);
                    break;
                default:
                    break;
            }
            return false;
        }

        return true;
    }

    return (
        <Fragment>
            <div style={{ width: "100vw", height: "100vh" }}>

                {isDesktopOrLaptop && (
                    <Container>
                        <Header gridArea="header" history={history} />

                        <MainContainer gridArea="main">

                            {!loaded && (
                                <div style={{textAlign: 'center',width: '100%',marginTop: '100px'}}>
                                  <img src={Loading} width="100" height="100" />
                                </div>
                            )}

                            {loaded && loginForm && (
                                <InnerContainer>
                                    <div style={{ height: "40px" }} className="login-container">
                                        <div className="login-header">
                                            Register
                                        </div>
                                    </div>

                                    <VerifyForm marginTop={30}>

                                        <span className="login-label">Full Name</span>
                                        <LoginInput
                                            placeholder="Full Name"
                                            marginTop={5}
                                            marginBottom={15}
                                            value={fullName}
                                            onChange={handlefullNameChange}
                                            autoFocus
                                        />

                                        <span className="login-label">Email</span>
                                        <LoginInput
                                            placeholder="Your email"
                                            marginTop={5}
                                            marginBottom={15}
                                            value={email}
                                            onChange={handleEmailChange}
                                        />

                                        <span className="login-label">Phone Number</span>
                                        <LoginInput
                                            placeholder="Phone Number"
                                            marginTop={5}
                                            marginBottom={15}
                                            value={phoneNumber}
                                            onChange={handlePhoneChange}
                                        />

                                        <span className="login-label">Password</span>

                                        <div style={{'position': 'relative','width': '100%'}}>
                                            <LoginInput
                                                placeholder="*******"
                                                marginTop={5}
                                            marginBottom={15}
                                                value={password}
                                                onChange={handlePasswordChange}
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                            />
                                            <div
                                            onClick={togglePassword}
                                            className={'show-icon ' + ((showPassword) ? 'active' : '')}>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "center"
                                            }}
                                        >
                                        </div>


                                        <div style={{'position': 'relative','width': '100%',
                                            marginTop: '25px',marginBottom: '25px',display: 'flex', justifyContent:'flex-start',alignItems: 'center'}}>
                                            <input type="checkbox" 
                                                checked={tos}
                                                onClick={() => {
                                                    setTos(!tos)
                                                }}
                                            /> &nbsp; I agree to <ResetLink3
                                                justify={"center"}
                                                onClick={() => {
                                                    window.open("https://www.gokada.ng/terms-of-service", '_blank').focus();
                                                }}
                                            >
                                                Terms of Service
                                            </ResetLink3>
                                        </div>

                                        

                                        <Button
                                            width={327}
                                            height={44}
                                            borderRadius={10}
                                            marginTop={0}
                                            marginBottom={0}
                                            onClick={handleSignUp}
                                        >
                                            Register
                                        </Button>

                                        <div
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "center",
                                                marginTop: "28px"
                                            }}
                                        >
                                            Already have an account? &nbsp;&nbsp;
                                            <ResetLink2
                                                justify={"center"}
                                                onClick={() => {
                                                    localStorage.removeItem('api_token');
                                                    history.push(
                                                        "/login"
                                                    );
                                                }}
                                            >
                                                Log In
                                            </ResetLink2>
                                        </div>
                                    </VerifyForm>
                                </InnerContainer>
                            )}

                            {loaded && verfiyView && !updateView && (
                                <InnerContainer>
                                    <div style={{ height: "40px" }} className="login-container">
                                        <div className="login-header">
                                            Verify
                                        </div>
                                    </div>

                                    <VerifyForm marginTop={30}>

                                        <div className="login-label" style={{marginBottom:'30px', alignItems: 'center'}}>
                                            Please enter the OTP sent to your phone number.
                                            <p style={{color: '#', textAlign: 'center'}}>{newPhoneNumber || formattedPhoneNumber}</p>
                                        </div>


                                        <ResetLink3
                                                justify={"center"}
                                                marginBottom={15}
                                                onClick={() => {
                                                   sendOtpCode() 
                                                }}
                                            >
                                                Resend OTP Code
                                            </ResetLink3>

                                        <span className="login-label">OTP Code</span>
                                        <LoginInput
                                            placeholder="Code"
                                            marginTop={5}
                                            marginBottom={15}
                                            value={code}
                                            onChange={handleCodeChange}
                                            autoFocus
                                        />

                                        <Button
                                            width={327}
                                            height={44}
                                            borderRadius={10}
                                            marginTop={0}
                                            marginBottom={0}
                                            onClick={handleVerify}
                                        >
                                            Verify Code
                                        </Button>


                                    </VerifyForm>

                                    <div>
                                        <p style={{fontSize: 14}}>Incorrect phone number? <ResetLink3 display="inline" onClick={goToUpdateView}>Update it</ResetLink3></p>
                                    </div>

                                    
                                </InnerContainer>
                            )}

                            {loaded && updateView && (
                                <InnerContainer>
                                <div style={{ height: "40px" }} className="login-container">
                                    <div className="login-header">
                                        Update
                                    </div>
                                </div>

                                <VerifyForm marginTop={30}>

                                    <div className="login-label" style={{marginBottom:'30px', alignItems: 'center'}}>
                                        Please enter your phone number.
                                    </div>

                                    <span className="login-label">Phone Number</span>
                                    <LoginInput
                                        placeholder="Phone number"
                                        marginTop={5}
                                        marginBottom={newPhoneNumberError ? 3 : 15}
                                        value={newPhoneNumber}
                                        onChange={handleNewPhoneChange}
                                        autoFocus
                                    />
                                    {newPhoneNumberError && <span className="error-label" style={{ marginBottom: '12px'}}>This phone number is not valid</span>}

                                    <Button
                                        width={327}
                                        height={44}
                                        borderRadius={10}
                                        marginTop={0}
                                        marginBottom={0}
                                        onClick={handleUpdate}
                                    >Update
                                    </Button>


                                </VerifyForm>

                                
                            </InnerContainer>
                            )}

                        </MainContainer>

                    </Container>
                )}
                {isTabletOrMobileDevice && (
                    <MobileContainer>
                        <Header gridArea="header" history={history} />

                        <MainContainer gridArea="main">

                            {!loaded && (
                                <div style={{textAlign: 'center',width: '100%',marginTop: '100px'}}>
                                  <img src={Loading} width="100" height="100" />
                                </div>
                            )}

                            {loaded && loginForm && (
                                <InnerContainer>
                                    <div style={{ height: "40px" }} className="login-container">
                                        <div className="login-header">
                                            Register
                                        </div>
                                    </div>

                                    <VerifyForm marginTop={30}>

                                        <span className="login-label">Full Name</span>
                                        <LoginInput
                                            placeholder="Full Name"
                                            marginTop={5}
                                            marginBottom={15}
                                            value={fullName}
                                            onChange={handlefullNameChange}
                                            autoFocus
                                        />

                                        <span className="login-label">Email</span>
                                        <LoginInput
                                            placeholder="Your email"
                                            marginTop={5}
                                            marginBottom={15}
                                            value={email}
                                            onChange={handleEmailChange}
                                        />

                                        <span className="login-label">Phone Number</span>
                                        <LoginInput
                                            placeholder="Phone Number"
                                            marginTop={5}
                                            marginBottom={15}
                                            value={phoneNumber}
                                            onChange={handlePhoneChange}
                                        />

                                        <span className="login-label">Password</span>

                                        <div style={{'position': 'relative','width': '100%'}}>
                                            <LoginInput
                                                placeholder="*******"
                                                marginTop={5}
                                            marginBottom={15}
                                                value={password}
                                                onChange={handlePasswordChange}
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                            />
                                            <div
                                            onClick={togglePassword}
                                            className={'show-icon ' + ((showPassword) ? 'active' : '')}>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "center"
                                            }}
                                        >
                                        </div>


                                        <div style={{'position': 'relative','width': '100%',
                                            marginTop: '25px',marginBottom: '25px',display: 'flex', justifyContent:'flex-start',alignItems: 'center'}}>
                                            <input type="checkbox" 
                                                checked={tos}
                                                onClick={() => {
                                                    setTos(!tos)
                                                }}
                                            /> &nbsp; I agree to <ResetLink3
                                                justify={"center"}
                                                onClick={() => {
                                                    window.open("https://www.gokada.ng/terms-of-service", '_blank').focus();
                                                }}
                                            >
                                                Terms of Service
                                            </ResetLink3>
                                        </div>

                                        

                                        <Button
                                            width={327}
                                            height={44}
                                            borderRadius={10}
                                            marginTop={0}
                                            marginBottom={0}
                                            onClick={handleSignUp}
                                        >
                                            Register
                                        </Button>

                                        <div
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "center",
                                                marginTop: "28px"
                                            }}
                                        >
                                            Already have an account? &nbsp;&nbsp;
                                            <ResetLink2
                                                justify={"center"}
                                                onClick={() => {
                                                    localStorage.removeItem('api_token');
                                                    history.push(
                                                        "/login"
                                                    );
                                                }}
                                            >
                                                Log In
                                            </ResetLink2>
                                        </div>

                                    </VerifyForm>
                                </InnerContainer>
                            )}

                            {loaded && verfiyView && !updateView && (
                                <InnerContainer>
                                    <div style={{ height: "40px" }} className="login-container">
                                        <div className="login-header">
                                            Verify
                                        </div>
                                    </div>

                                    <VerifyForm marginTop={30}>

                                        <div className="login-label" style={{marginBottom:'30px', alignItems: 'center'}}>
                                            Please enter the OTP sent to your phone number.
                                            <p style={{color: '#', textAlign: 'center'}}>{newPhoneNumber || formattedPhoneNumber}</p>
                                        </div>


                                        <ResetLink3
                                                justify={"center"}
                                                marginBottom={15}
                                                onClick={() => {
                                                   sendOtpCode() 
                                                }}
                                            >
                                                Resend OTP Code
                                            </ResetLink3>

                                        <span className="login-label">OTP Code</span>
                                        <LoginInput
                                            placeholder="Code"
                                            marginTop={5}
                                            marginBottom={15}
                                            value={code}
                                            onChange={handleCodeChange}
                                            autoFocus
                                        />

                                        <Button
                                            width={327}
                                            height={44}
                                            borderRadius={10}
                                            marginTop={0}
                                            marginBottom={0}
                                            onClick={handleVerify}
                                        >
                                            Verify Code
                                        </Button>


                                    </VerifyForm>

                                    <div>
                                        <p style={{fontSize: 14}}>Incorrect phone number? <ResetLink3 display="inline" onClick={goToUpdateView}>Update it</ResetLink3></p>
                                    </div>

                                    
                                </InnerContainer>
                            )}

                            {loaded && updateView && (
                                <InnerContainer>
                                <div style={{ height: "40px" }} className="login-container">
                                    <div className="login-header">
                                        Update
                                    </div>
                                </div>

                                <VerifyForm marginTop={30}>

                                    <div className="login-label" style={{marginBottom:'30px', alignItems: 'center'}}>
                                        Please enter your phone number.
                                    </div>

                                    <span className="login-label">Phone Number</span>
                                    <LoginInput
                                        placeholder="Phone number"
                                        marginTop={5}
                                        marginBottom={newPhoneNumberError ? 3 : 15}
                                        value={newPhoneNumber}
                                        onChange={handleNewPhoneChange}
                                        autoFocus
                                    />
                                    {newPhoneNumberError && <span className="error-label" style={{ marginBottom: '12px'}}>This phone number is not valid</span>}

                                    <Button
                                        width={327}
                                        height={44}
                                        borderRadius={10}
                                        marginTop={0}
                                        marginBottom={0}
                                        onClick={handleUpdate}
                                    >Update
                                    </Button>


                                </VerifyForm>

                                
                            </InnerContainer>
                            )}
                                                        
                        </MainContainer>

                    </MobileContainer>
                )}
            </div>
        </Fragment>
    );
};

export default Register;

const Container = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
`;

const MobileContainer = styled.div`
    display: flex;
    height: 1223px;
    width: fit-content;
    flex-direction: column;
`;

const MainContainer = styled.div`
    height: 100%;
    grid-area: ${(props) => props.gridArea};
    padding-bottom: 100px;
`;

const PhoneButton = styled.button`
    width: ${(props) => props.width || 50}px;
    height: ${(props) => props.height || 50}px;
    border-radius: ${(props) => props.borderRadius || 8}px;
    justify-content: center;
    border: 2px solid #00c795;
    cursor: pointer;
    align-items: center;
    background-color: transparent;
    font-size: ${(props) => props.fontSize || 18}px;
    font-family: "Inter";
    font-weight: ${(props) => props.fontWeight || 900};
    color: #000000;
    ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}px;` : "")}
    ${(props) =>
        props.marginLeft ? `margin-left: ${props.marginLeft}px;` : ""}
    ${(props) =>
        props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : ""}
    ${(props) =>
        props.marginRight ? `margin-right: ${props.marginRight}px;` : ""};
`;

const InnerContainer = styled.div`
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
    flex: 1;
    ${(props) => (props.height ? `height:${props.height};` : "")}
    ${(props) => (props.padding ? `padding:${props.padding}` : "")}
`;

const VerifyForm = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 327px;
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

const Input = styled.input`
    top: 416px;
    left: 308px;
    width: 460px;
    height: 44px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #00c795;
    border-radius: 10px;
    font-size: 16px;
    font-family: "Inter";
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;
const LoginInput = styled.input`
    width: 100%;
    height: 44px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #00c795;
    border-radius: 10px;
    font-size: 14px;
    font-family: "Inter";
    background: #F8F9FB;
    border: 1px solid #BCC1CC;
    border-radius: 8px;

    // background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
    // background-position: left;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    background-size: 60px;
    background-repeat: no-repeat;
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

const ResetLink = styled.a`
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    cursor: pointer;
    display: flex;
    margin-top: 28px;
    margin-bottom: 28px;
    color: #4545E5;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    ${(props) => (props.justify ? `justify-content:${props.justify};` : "")}
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px;` : ""}
`;

const ResetLink2 = styled.a`
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    cursor: pointer;
    display: flex;
    color: #4545E5;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    ${(props) => (props.justify ? `justify-content:${props.justify};` : "")}
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px;` : ""}
`;
const ResetLink3 = styled.a`
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    cursor: pointer;
    color: #4545E5;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
    ${(props) => (props.display ? `display:${props.display}` : "display: flex")};
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

const Checkbox = styled.input`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #cccccc;
    border-radius: 10px;
    font-size: 18px;
    font-family: "SF-Pro-Display-Regular";
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

const Row = styled.div`
    margin-top: 5px;
    display: flex;
    justify-content: start;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
`;
