import React, { Fragment } from 'react';
import * as MapboxGL from 'mapbox-gl';
import ReactMapboxGl, {
  Marker,
  GeoJSONLayer,
  Layer,
  Feature,
} from 'react-mapbox-gl';
import equal from 'fast-deep-equal';

import PickupMarker from '../../assets/icons/pngs/pickup.png';
import DropoffMarker from '../../assets/icons/pngs/dropoff.png';
import { lte } from 'lodash/fp';
import { initial } from 'lodash';

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX,
});

class Mapbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: this.props.lat,
      lng: this.props.lng,
      center: this.props.center,
      polylineCoordinate: [],

      initalLat: [3.363280699999999, 6.5441226],
      finalLat: [3.363280699999999, 6.5441226],
      // initalLat: [this.props.firstWaypoint.lng, this.props.firstWaypoint.lat],
      // finalLat: [this.props.lastWaypoint.lng, this.props.lastWaypoint.lat],
    };
    this.handleMap = this.handleMap.bind(this);
  }

  componentDidMount() {
    this.setState({
      initalLat: [3.363280699999999, 6.5441226],
      finalLat: [3.363280699999999, 6.5441226],
    });
  }

  componentDidUpdate(prevProps) {
    if (equal(this.props.waypoints, prevProps.waypoints)) {
    } else {
      this.handleMap();
    }
  }
  handleMap() {
    this.setState({
      initalLat: [
        parseFloat(this.props.firstWaypoint.lng),
        parseFloat(this.props.firstWaypoint.lat),
      ],
      finalLat: [
        parseFloat(this.props.lastWaypoint.lng),
        parseFloat(this.props.lastWaypoint.lat),
      ],
    });
  }

  render() {
    return (
      <Map
        style="mapbox://styles/mapbox/streets-v8"
        containerStyle={{
          height: '100%',
          width: '100%',
        }}
        center={[3.363280699999999, 6.5441226]}
        zoom={[12]}
        movingMethod={'easeTo'}
        fitBounds={[this.state.initalLat, this.state.finalLat]}
        fitBoundsOptions={{
          padding: {
            top: 100,
            bottom: 100,
            left: 100,
            right: 100,
          },
        }}
      >
        {this.props.waypoints &&
          this.props.waypoints.map((waypoint) => {
            if (waypoint.location === 'pickup') {
              return (
                <Fragment key={waypoint.lat}>
                  <Marker
                    style={{ width: 20, height: 20 }}
                    coordinates={[
                      parseFloat(waypoint.lng),
                      parseFloat(waypoint.lat),
                    ]}
                  >
                    <img
                      alt={''}
                      src={PickupMarker}
                      style={{ width: 40, height: 40 }}
                    />
                  </Marker>
                </Fragment>
              );
            } else {
              return (
                <Fragment>
                  <Marker
                    style={{ width: 40, height: 40 }}
                    coordinates={[
                      parseFloat(waypoint.lng),
                      parseFloat(waypoint.lat),
                    ]}
                  >
                    <img
                      src={DropoffMarker}
                      style={{ width: 40, height: 40 }}
                      alt={''}
                    />
                  </Marker>
                </Fragment>
              );
            }
          })}

        {/* <GeoJSONLayer
          data={{
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: this.state.polylineCoordinate,
                },
              },
            ],
          }}
          // linePaint={linePaint}
          // lineLayout={lineLayout}
        /> */}
      </Map>
    );
  }
}

export default Mapbox;
