import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';

import * as api from '../../api/index';
import FreshChat from 'react-freshchat';
import Text from '../../components/molecules/text';
import Button from '../../components/molecules/button';
import AddressSearch from '../../components/molecules/address-search/AddressSearch';
import MapControl from '../../components/molecules/map-control/MapControl';
import Config from "../../Config";
import Header from '../../components/organisms/header/index';
import SideMenu from '../../components/organisms/menu/index';
import { confirmAlert } from 'react-confirm-alert';
import AreaCode from '../../assets/icons/pngs/areaCode.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from "axios";
import Loading from '../../assets/loading.gif';
import { useMediaQuery } from 'react-responsive';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: transparent;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100vw;
  height: 100vh;
`;

const MenuOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 64px;
  padding-right: 64px;
`;

const MenuOption = styled.a`
width: 95%
justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: 'Inter';
  font-weight: 400;
 font-size: 18px;
 cursor: pointer;
  ${(props) => (props.fontColor ? `color: ${props.fontColor}` : '#0e0e0f')};
  ${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight}` : '')};
  cursor: pointer;
  display: flex;
  ${(props) => (props.bottom ? 'bottom: 24px;' : '')}
  ${(props) => (props.bottom ? 'position: absolute;' : '')}
  border-top: 1px solid rgb(227, 229, 232);
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
`;

const Row = styled.div`
  margin-top: 22px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;

  width: 100%;
`;
const PhoneInput = styled.input`
  top: 416px;
  left: 308px;
  width: 340px;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e1e4eb;
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Inter';
  text-align: left;
  padding-left: 60px;
  padding-right: 10px;
  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
  background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
  background-position: left;
  background-size: 60px;
  background-repeat: no-repeat;
`;
const Input = styled.input`
  top: 416px;
  left: 308px;
  width: 384px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #cccccc;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'Inter';
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;

  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
`;

const VerifyForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
`;

const TableAlt = ({ ...props }) => {
  const api_token = props.api_token;
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeCheckboxes, setActiveCheckboxes] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  let showCheckbox = props.hideCheckbox ? false : true;

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
});

  useEffect(() => {
    setData(props.data);
    console.log(props.data2);
  }, [data, props.data, props.data2]);

  let scheduleSelected = function(i) {
    let scheduleIds = [];
    for(let i = 0; i < data.length; i++) {
      if(data[i].checked) {
        scheduleIds.push(data[i].id);
      }
    }
    
    // console.log(scheduleIds);
    // call API here to get the payload to feed into next page to actually schedule it

  }
  let cancelSelected = function(i) {

  }
  let checkboxToggle = function(i) {
    selectCheckbox(i);
  };

  let selectCheckbox = function(id = null) {
    let dataTemp = data;
    let active = activeCheckboxes >= 0 ? activeCheckboxes : 0;
    for(let i = 0; i < dataTemp.length; i++) {
      if(dataTemp[i]['id'] == id || id == null) {
        if(typeof dataTemp[i]['checked'] != 'undefined') {
          if(dataTemp[i]['checked']) {
            dataTemp[i]['checked'] = false;
            active--;
          } else {
            dataTemp[i]['checked'] = true;
            active++;
          }
        } else {
          dataTemp[i]['checked'] = true;
          active++;
        }
      }
    }

    setActiveCheckboxes(active);
    setData([
      ...dataTemp
    ]);
  }

  return (
    <div>

    {activeCheckboxes > 0 ? (

      <div className="table-options">
        <button className="schedule" onClick={scheduleSelected}>
          <i className="icon"></i>
          <span>Schedule Selected</span>
        </button>
        <button className="cancel" onClick={cancelSelected}>
          <i className="icon"></i>
          <span>Cancel Selected</span>
        </button>
      </div>


    ) : ''}

  {isTabletOrMobileDevice ? (
      <div className='order-table'>
        {data.map((answer, index) => {
          const firstItem = index === 0;
          const hasOrderId = props.data2.order_id;
          const hasPickupTime = props.data2.pickup_time && !props.comp;

          const orderId = firstItem ? props.data2.order_id : '';
          const pickupTime = firstItem ? props.data2.pickup_time : '';
          const pilotId = firstItem ? props.data2.pilot_id : '';
          const name = answer.name ? answer.name : answer.order_unit_waypoints[1].waypoint.customer_name;
          const phone = answer.name ? answer.phone : answer.order_unit_waypoints[1].waypoint.customer_phone;
          const address = answer.name ? answer.address : answer.order_unit_waypoints[1].waypoint.location.address;
          const price = () => {
            if (firstItem) {
              if (answer.name) return answer.price;
              return props.data2.order_finance.payable_amount + " NGN"
            }
            return "";
          }

          return (
            <div className='order-card'>
              {showCheckbox ? <input key={answer.id}
                type="checkbox" 
                checked={answer.checked}
                onChange={() => {
                  checkboxToggle(answer.id);
                }}
              /> : ""}
              {hasOrderId && (
                <div className='order-item'>
                  <p className='order-property'>Order ID</p>
                  <p className='order-value'>{orderId}</p>
                </div>
              )}
              {hasPickupTime && (
                <div className='order-item'>
                  <p className='order-property'>Scheduled Time</p>
                  <p className='order-value'>{pickupTime}</p>
                </div>
              )}
              <div className='order-item'>
                <p className='order-property'>Rider</p>
                <p className='order-value'>{pilotId}</p>
              </div>
              <div className='order-item'>
                <p className='order-property'>Name</p>
                <p className='order-value'>{name}</p>
              </div>
              <div className='order-item'>
                <p className='order-property'>Phone</p>
                <p className='order-value'>{phone}</p>
              </div>
              <div className='order-item'>
                <p className='order-property'>Dropoff Address</p>
                <p className='order-value'>{address}</p>
              </div>
              <div className='order-item'>
                <p className='order-property'>Delivery Price</p>
                <p className='order-value'>{price()}</p>
              </div>
            </div>
          )
        })}
      </div>
    ) : (
      <table className="main-table">
        <tr>
          {showCheckbox ? (

            <th><input type="checkbox"
          onChange={(e) => {

            let checked = e.target.checked;
            let dataTemp = data;
            let active = 0;
            for(let i = 0; i < dataTemp.length; i++) {
              if(typeof dataTemp[i]['checked'] != 'undefined') {
                if(dataTemp[i]['checked']) {
                  dataTemp[i]['checked'] = checked;
                  active = (checked) ? active + 1: active - 1;
                } else {
                  dataTemp[i]['checked'] = checked;
                  active = (checked) ? active + 1: active - 1;
                }
              } else {
                dataTemp[i]['checked'] = checked;
                active = (checked) ? active + 1: active - 1;
              }
            }

            setActiveCheckboxes(active);
            setData([
              ...dataTemp
            ]);
          }}
          /></th>

          ) : "" }
          
          {
            props.data2.order_id ? (<th>Order ID</th>) : ""
          }

          {
            props.data2.pickup_time && !props.comp ? (<th>Scheduled Time</th>) : ""
          }

          <th>Rider</th>
          <th width="10%">Name</th>
          <th width="10%">Phone</th>
          <th width="40%">Dropoff Address</th>
          <th width="15%">Delivery Price</th>
          <th>Status</th>
        </tr>
        {data.map((answer, i) => {     
          return (
          <tr>
            {
              props.data2.order_id ? ( (i == 0) ? (<td>{props.data2.order_id}</td>) : (<td></td>) ) : ""
            }
            {
              props.data2.pickup_time && !props.comp ? ( (i == 0) ? (<td>{props.data2.pickup_time}</td>) : (<td></td>) ) : ""
            }
            <td>
              {props.data2.pilot_id && i == 0 ? props.data2.pilot_id : ""}
            </td>
            <td>
              {answer.name ? answer.name : answer.order_unit_waypoints[1].waypoint.customer_name}
            </td>
            <td>
              {answer.name ? answer.phone : answer.order_unit_waypoints[1].waypoint.customer_phone}
            </td>
            <td>
              {answer.name ? answer.address : answer.order_unit_waypoints[1].waypoint.location.address}
            </td>
            <td className="priceShow">
              {
                i == 0 ? ( (answer.name) ? answer.price : (props.data2.order_finance.payable_amount + " NGN") ) : ""
              }
            </td>
            <td>
              {answer.state ? answer.state : "-"}
            </td>
          </tr>) 
        })}
      </table>
      )
    }
    </div>
  );
};

const LoginInput = styled.input`
    width: 100%;
    height: 44px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #00c795;
    border-radius: 10px;
    font-size: 14px;
    font-family: "Inter";
    background: #F8F9FB;
    border: 1px solid #BCC1CC;
    border-radius: 8px;

    // background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
    // background-position: left;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    background-size: 60px;
    background-repeat: no-repeat;
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

export default TableAlt;
