import React, { useState, useEffect, Fragment, useContext, useCallback } from "react";
import styled from "styled-components";
import { usePaystackPayment, PaystackConsumer } from "react-paystack";

import * as api from "../../api/index";

import FreshChat from "react-freshchat";
import DashIcon from "../../components/molecules/dash-icon";
import Text from "../../components/molecules/text";
import Button from "../../components/molecules/button";
import Header from "../../components/organisms/header/index";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useMediaQuery } from "react-responsive";
import SideMenu from '../../components/organisms/menu/index';
import { MenuContext } from "../../MenuContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: transparent;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100vw;
  height: 100vh;
`;

const MenuOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 64px;
  padding-right: 64px;
`;

const MenuOption = styled.a`
width: 95%
justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: 'Inter';
  font-weight: 400;
 font-size: 18px;
 cursor: pointer;
  ${(props) => (props.fontColor ? `color: ${props.fontColor}` : '#0e0e0f')};
  ${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight}` : '')};
  cursor: pointer;
  display: flex;
  ${(props) => (props.bottom ? 'bottom: 24px;' : '')}
  ${(props) => (props.bottom ? 'position: absolute;' : '')}
  border-top: 1px solid rgb(227, 229, 232);
`;

const Wallet = ({ history, match }) => {
    const theme = localStorage.getItem("theme") + "-mode";
    let api_token;
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-device-width: 1224px)",
    });
    const isTabletOrMobileDevice = useMediaQuery({
        query: "(max-device-width: 1224px)",
    });

    const field = "user_id";
    const url = window.location.href;
    if (url.includes("?" + field + "=")) {
        const index = url.indexOf("=") + 1;
        const userId = url.slice(index, url.length);
        api_token = window.localStorage.getItem(`${userId}`);
    } else {
        api_token = localStorage.getItem("api_token");
    }

    if (!api_token) {
        history.push("/login");
    } else if (typeof api_token === "object") {
        if (api_token.message.includes("invalid")) {
            localStorage.clear();
            history.push("/login");
        }
    }

    const {sidemenu} = useContext(MenuContext);
    const [user, setUser] = useState({});
    const [apiAccessBalance, setApiAccessBalance] = useState(false);
    const [apiAccessTopOff, setApiAccessTopOff] = useState(false);
    const [addPayment, setAddPayment] = useState(false);
    const [apiAccessDetails, setApiAccessDetails] = useState({});
    const [apiForm, setApiForm] = useState(false);
    const [appName, setAppName] = useState("");
    const [callbackReference, setCallbackReference] = useState("");
    const [msg, setMsg] = useState("");
    const [appDescription, setAppDescription] = useState("");
    const [topOffAmount, setTopOffAmount] = useState("");
    const [webhookURL, setWebhookURL] = useState("");

    const [cardNum, setCardNum] = useState("");
    const [cardholderName, setCardholderName] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [cvv, setCvv] = useState("");

    const getUser = useCallback(() => {
        api.getUserDetails({
            api_token,
        }).then((result) => {
            if (result.error === "invalid_api_key") {
                localStorage.removeItem("api_token");
                history.push("/login");
            } else {
                setUser(result);
            }
        });
    }, [api_token, history]);

    const getApiAccessDetails = useCallback(() => {
        if (webhookURL) {
            const status_webhook_url = `${webhookURL}`;
            api.getApiAccess({
                api_token,
                status_webhook_url: status_webhook_url,
            }).then((result) => {
                console.log("ggetApiAccesset", result);
                if (result.id) {
                    setApiAccessDetails(result);
                    setWebhookURL(result.status_webhook_url);
                    setApiForm(false);
                    setApiAccessBalance(true);
                } else if (result.message.includes("signup")) {
                    setApiForm(true);
                    setApiAccessBalance(false);
                }
            });
        } else {
            api.getApiAccess({ api_token }).then((result) => {
                console.log("getApiAccesse", result);
                if (result.id) {
                    setApiAccessDetails(result);
                    setApiForm(false);
                    setWebhookURL(result.status_webhook_url);
                    setApiAccessBalance(true);
                } else if (result.message.includes("signup")) {
                    setApiForm(true);
                    setApiAccessBalance(false);
                }
            });
        }
    }, [api_token, webhookURL]);

    const config = {
        reference: callbackReference,
        email: user.email || "customer@email.com",
        amount: topOffAmount * 100,
        // publicKey: "pk_test_13c6764234ac3499067d18546edb24312301c05c",
        publicKey: "pk_live_93586a95e2bace6979904ca707e55f3b88e229a8",
    };

    const componentProps = {
        ...config,
        text: "Confirm",
        onSuccess: (response) => {
            validatePaystack(response);
        },
    };

    useEffect(() => {
        getUser();
        getApiAccessDetails();
    }, [getApiAccessDetails, getUser]);

    const initializePayment = usePaystackPayment(config);

    // you can call this function anything
    const onSuccess = (reference) => {
        api.validatePaystackTopoff({
            wallet_id: user.wallet_id,
            gateway: 'paystack',
            gateway_reference: reference.reference,
            api_token,
        }).then((result) => {
            console.log(result)
            console.log("validatePaystackTopoff", result);
            setApiAccessTopOff(false);
            getApiAccessDetails();
            getUser();
        });
    };

    // you can call this function anything
    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log('closed')
    }

    useEffect(() => {
        if(callbackReference != '') {
            initializePayment(onSuccess, onClose)
            // initializePayment()
        }
    }, [callbackReference, initializePayment, onSuccess]);

    const signupApi = () => {
        api.signUpApiAcess({
            app_name: "n/a",
            app_description: "n/a",
            api_token,
        }).then((result) => {
            console.log("signup Api Access", result);
            setApiAccessDetails(result);
            setApiForm(false);
            setApiAccessBalance(true);
        });
    };

    const generateTransaction = (cb) => {
        api.generateTransaction({
            wallet_id: user.wallet_id,
            amount: topOffAmount,
            // app_name: "n/a",
            // app_description: "n/a",
            api_token
        }).then((result) => {
            setCallbackReference(result.data.reference);
            // cb(result);
        });
    };

    const validatePaystack = (response) => {
        api.validatePaystackTopoff({
            reference: response.reference,
            api_token,
        }).then((result) => {
            console.log("validatePaystackTopoff", result);
            setApiAccessTopOff(false);
            getApiAccessDetails();
        });
    };

    const updateWebhook = () => {
        api.updateDevAcount({
            webhookUrl: webhookURL,
            api_token,
        }).then((result) => {
            console.log("updateDevAccount", result);
            if (result.success === true) {
                confirmAlert({
                    title: "Webhook URL Added",
                    message: "Adding your Webhook URL was succesful.",
                    buttons: [
                        {
                            label: "Okay",
                            onClick: () => {
                                getApiAccessDetails();
                            },
                        },
                    ],
                });
            } else if (result.status === false) {
                confirmAlert({
                    title: "Error Adding Webhook URL",
                    message:
                        "There was an error adding your Webhook URL. Please try again",
                    buttons: [
                        {
                            label: "Okay",
                            onClick: () => setAppName(""),
                        },
                    ],
                });
            }
        });
    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            setWebhookURL("");
            updateWebhook();
        }
    };

    const renderBody = () => {
        return (
            user.wallet &&
            user.wallet.transactions.map(
                ({ id, created_at, amount, type, notes }) => {
                    return (
                        <tr
                            key={id}
                            style={{
                                height: "80px",
                                boxShadow: "inset 0 -1px 0 0 #e1e2e6",
                            }}
                        >
                            <td
                                style={{
                                    marginRight: "90px",
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "1.43",

                                    textTransform: "capitalize",
                                }}
                            >
                                {created_at}
                            </td>
                            <td
                                style={{
                                    marginRight: "90px",
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "1.43",
                                }}
                            >
                                {type}
                            </td>
                            <td
                                style={{
                                    marginRight: "90px",
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "1.43",
                                }}
                            >
                                {amount}
                            </td>

                            <td
                                style={{
                                    marginRight: "90px",
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    lineHeight: "1.43",
                                }}
                            >
                                {notes}
                            </td>
                        </tr>
                    );
                }
            )
        );
    };

    const renderHeader = () => {
        let headerElement = ["date", "type", "amount", "description"];

        return headerElement.map((key, index) => {
            return (
                <th
                    style={{
                        paddingRight: "80px",
                    }}
                    key={index}
                >
                    {key.toUpperCase()}
                </th>
            );
        });
    };

    return (
        <div className={theme}>
         {sidemenu ? (<SideMenu history={history} />) : (

          <OuterContainer>
            <Header history={history} />
            <div style={{display: 'flex',width: '100%'}}>
            {!isTabletOrMobileDevice && <SideMenu history={history} />}
              <div className="main-content">
                <div className="inner-box">
                  <h1>Wallet</h1>
                  
                    {
        user.wallet && user.wallet.balance > 0 ? (null) : (
          <p className="warning">
        <b>Notice:</b> You must have funds in your wallet to create orders.
      </p>)
      }
                  
                    {true && (
                        <Fragment>
                            {apiAccessBalance && (
                                <BodyDiv height={"100%"} className={theme}>
                                    <Fragment>
                                        <BalanceContainer>
                                            <Text
                                                marginTop={0}
                                                fontSize={14}
                                                textTransform={"uppercase"}
                                                color={"#a1a7b3"}
                                                fontWeight={"bold"}
                                            >
                                                Wallet Balance{" "}
                                            </Text>

                                            <Row marginTop={20} marginBottom={20}>
                                                {(() => {
                                                    if (user.wallet) {
                                                        return (
                                                            <Text
                                                                textAlign={"center"}
                                                                fontSize={24}
                                                                fontWeight={900}
                                                            >
                                                                ₦{" "}
                                                                {
                                                                    user.wallet.balance
                                                                }
                                                            </Text>
                                                        );
                                                    } else {
                                                        return (
                                                            <Text
                                                                textAlign={"center"}
                                                                fontSize={24}
                                                                fontWeight={900}
                                                            >
                                                                ₦ 0
                                                            </Text>
                                                        );
                                                    }
                                                })()}

                                                <Button
                                                    width={120}
                                                    height={40}
                                                    marginLeft={8}
                                                    borderRadius={10}
                                                    onClick={() => {
                                                        setApiAccessBalance(false);
                                                        setApiAccessTopOff(true);
                                                    }}
                                                >
                                                    Top Up
                                                </Button>
                                            </Row>
                                        </BalanceContainer>

                                        <TransactionsContainer>
                                            <HeaderCont
                                                marginTop={0}
                                                fontSize={14}
                                                textTransform={"uppercase"}
                                                color={"#a1a7b3"}
                                                fontWeight={"bold"}
                                            >
                                                <Text fontSize={24} fontWeight={"bold"}>
                                                    Wallet Transactions
                                                </Text>
                                            </HeaderCont>

                                        <div className="table-container">
                                            <table id="orders">
                                                <thead>
                                                    <tr
                                                        style={{
                                                            height: "60px",
                                                            fontFamily: "Inter",
                                                            textAlign: "left",
                                                            color: "#a1a7b3",
                                                            fontSize: "14px",
                                                            fontWeight: "bold",
                                                            boxShadow:
                                                                "inset 0 -1px 0 0 #e1e2e6",
                                                        }}
                                                    >
                                                        {renderHeader()}
                                                    </tr>
                                                </thead>
                                                <tbody>{renderBody()}</tbody>
                                            </table>
                                            </div>
                                        </TransactionsContainer>
                                    </Fragment>
                                </BodyDiv>
                            )}

                            {addPayment && (
                                <BodyDiv height={"100vh"} className={theme}>
                                    <div style={{ height: "110px" }}>
                                        <Text
                                            fontSize={32}
                                            marginTop={40}
                                            fontWeight={"bold"}
                                        >
                                            Add New Card
                                        </Text>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <Column marginBottom={20}>
                                            <Text
                                                fontSize={14}
                                                fontWeight={"bold"}
                                                color={"#a1a7b3"}
                                            >
                                                Credit Card Number
                                            </Text>
                                            <Input
                                                marginTop={10}
                                                width={460}
                                                placeholder="Enter Card Number"
                                                value={cardNum}
                                                onChange={(e) =>
                                                    setCardNum(e.target.value)
                                                }
                                            />
                                        </Column>

                                        <Column marginBottom={20}>
                                            <Text
                                                fontSize={14}
                                                fontWeight={"bold"}
                                                color={"#a1a7b3"}
                                            >
                                                Card Holder
                                            </Text>
                                            <Input
                                                marginTop={10}
                                                width={460}
                                                placeholder="John Smith"
                                                value={cardholderName}
                                                onChange={(e) =>
                                                    setCardholderName(e.target.value)
                                                }
                                            />
                                        </Column>

                                        <Row marginBottom={40}>
                                            <Column>
                                                <Text
                                                    fontSize={14}
                                                    fontWeight={"bold"}
                                                    color={"#a1a7b3"}
                                                >
                                                    Expiry Date
                                                </Text>
                                                <Input
                                                    width={210}
                                                    marginTop={10}
                                                    placeholder="MM/YY"
                                                    value={expirationDate}
                                                    onChange={(e) =>
                                                        setExpirationDate(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Column>

                                            <Column marginLeft={18}>
                                                <Text
                                                    fontSize={14}
                                                    fontWeight={"bold"}
                                                    color={"#a1a7b3"}
                                                >
                                                    CVV
                                                </Text>
                                                <Input
                                                    width={210}
                                                    marginTop={10}
                                                    placeholder="Security code"
                                                    value={cvv}
                                                    onChange={(e) =>
                                                        setCvv(e.target.value)
                                                    }
                                                />
                                            </Column>
                                        </Row>

                                        <Row marginTop={20} justifyContent={"flex-end"}>
                                            <Button
                                                width={140}
                                                height={40}
                                                backgroundColor={"light-gray"}
                                                color={"dark-gray"}
                                                borderRadius={10}
                                                onClick={() => {
                                                    setAddPayment(false);
                                                    setApiAccessBalance(true);
                                                }}
                                            >
                                                Cancel
                                            </Button>

                                            <Button
                                                width={140}
                                                marginLeft={10}
                                                height={40}
                                                backgroundColor={"#00c795"}
                                                color={"white"}
                                                borderRadius={10}
                                                onClick={() => {
                                                    setApiAccessTopOff(false);
                                                    setApiAccessBalance(true);
                                                }}
                                            >
                                                Add Card
                                            </Button>
                                        </Row>
                                    </div>
                                </BodyDiv>
                            )}

                            {apiAccessTopOff && (
                                <BodyDiv height={"100vh"} className={theme}>
                                    <div style={{ height: "110px" }}>
                                        <Text
                                            fontSize={32}
                                            marginTop={40}
                                            marginBottom={40}
                                            fontWeight={"bold"}
                                        >
                                            Add Funds
                                        </Text>
                                    </div>

                                    <ApiKeyContainer>
                                        <Text
                                            marginTop={20}
                                            fontSize={14}
                                            textTransform={"uppercase"}
                                            color={"#a1a7b3"}
                                            fontWeight={"bold"}
                                        >
                                            Current Balance{" "}
                                        </Text>

                                        {(() => {
                                            if (apiAccessDetails.balance) {
                                                return (
                                                    <Text
                                                        marginTop={20}
                                                        marginBottom={20}
                                                        textAlign={"left"}
                                                        fontSize={24}
                                                        fontWeight={900}
                                                    >
                                                        ₦ {user.wallet.balance}
                                                    </Text>
                                                );
                                            } else {
                                                return (
                                                    <Text
                                                        marginBottom={20}
                                                        textAlign={"left"}
                                                        fontSize={24}
                                                        fontWeight={900}
                                                    >
                                                        ₦ 0
                                                    </Text>
                                                );
                                            }
                                        })()}
                                    </ApiKeyContainer>

                                    <BalanceContainer>
                                        <Text
                                            fontSize={14}
                                            textTransform={"uppercase"}
                                            color={"#a1a7b3"}
                                            fontWeight={"bold"}
                                        >
                                            Top Up Amount (₦)
                                        </Text>

                                        <WebhookInput
                                            marginTop={20}
                                            width={isTabletOrMobileDevice ? '100%' : 440}
                                            border={"2px solid #00c795"}
                                            placeholder="Enter Amount"
                                            value={topOffAmount}
                                            onChange={(e) => {
                                                setTopOffAmount(e.target.value);
                                            }}
                                        />

                                        <Row
                                            marginTop={40}
                                            marginBottom={20}
                                            justifyContent={"flex-end"}
                                        >
                                            <Button
                                                width={140}
                                                height={40}
                                                backgroundColor={"light-gray"}
                                                color={"dark-gray"}
                                                borderRadius={10}
                                                onClick={() => {
                                                    setApiAccessTopOff(false);
                                                    setApiAccessBalance(true);
                                                }}
                                            >
                                                Cancel
                                            </Button>

                                            <PaystackConsumer {...componentProps}>
                                                {({ initializePayment }) => (
                                                    <Button
                                                        width={140}
                                                        marginLeft={8}
                                                        height={40}
                                                        borderRadius={10}
                                                        onClick={ () => {
                                                            // initializePayment()
                                                            generateTransaction((data) => {
                                                                alert('done');
                                                                console.log(data);
                                                            })
                                                        } }
                                                    >
                                                        Confirm
                                                    </Button>
                                                )}
                                            </PaystackConsumer>
                                        </Row>
                                    </BalanceContainer>
                                </BodyDiv>
                            )}

                            {apiForm && (
                                <BodyDiv height={"100vh"} className={theme} width={660}>
                                    <div style={{ height: "110px" }}>
                                        <Text
                                            fontSize={32}
                                            marginTop={40}
                                            marginBottom={40}
                                            fontWeight={"bold"}
                                        >
                                            My Wallet Permission
                                        </Text>
                                    </div>
                                    <VerifyForm>
                                        <Text
                                            marginTop={20}
                                            fontSize={14}
                                            color={"#a1a7b3"}
                                            fontWeight={"bold"}
                                        >
                                            NOTICE
                                        </Text>
                                        <Text
                                            fontSize={14}
                                            color={"#0e0e0f"}
                                            fontWeight={"bold"}
                                        >
                                            Please Press "Enable Now" to access your
                                            wallet.
                                        </Text>
                                        <Row marginTop={20} justifyContent={"left"}>
                                            <Button
                                                marginLeft={0}
                                                width={150}
                                                height={40}
                                                borderRadius={8}
                                                onClick={signupApi}
                                            >
                                                Enable Wallet
                                            </Button>
                                        </Row>
                                    </VerifyForm>
                                </BodyDiv>
                            )}
                        </Fragment>
                    )}



                </div>
              </div>
            </div>
          </OuterContainer>
                    )}
        </div>

    );
};

export default Wallet;

const ViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100vw;
`;

const BodyDiv = styled.div`
    display: flex;
    flex-direction: column;
    ${(props) => (props.height ? `height:${props.height}` : "100vh")};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
    ${(props) => (props.width ? `width:${props.width}px` : "940px")};
`;

const ApiKeyContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 -1px 0 0 #e1e2e6;
    width: 100%;
    margin-bottom: 20px;
    ${(props) => (props.height ? `height:${props.height}px` : "120px")};
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
    ${(props) =>
        props.justifyContent ? `justify-content:${props.justifyContent}` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) =>
        props.overFlowWrap ? `overflow-wrap:${props.overFlowWrap}` : ""};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
    ${(props) =>
        props.justifyContent ? `justify-content:${props.justifyContent}` : ""};
    ${(props) => (props.alignItems ? `align-items:${props.alignItems}` : "")};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
`;

const BalanceContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    box-shadow: inset 0 -1px 0 0 #e1e2e6;
`;

const PaymentMethodCont = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80px;
    box-shadow: inset 0 -1px 0 0 #e1e2e6;
`;

const HeaderCont = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 72px;
    justify-content: center;
    box-shadow: inset 0 -1px 0 0 #e1e2e6;
`;

const AddNewCardCont = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80px;
    cursor: pointer;
    justify-content: center;
`;
const TransactionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
`;
const WebhookInput = styled.input`
    top: 416px;
    left: 308px;
    height: 40px;

    border-radius: 10px;
    font-size: 16px;
    font-family: "Inter";
    text-align: left;
    padding-left: 10px;
    margin-right: 20px;
    padding-right: 10px;
    ${(props) => (props.width ? `width:${props.width}px` : "800px")};
    ${(props) =>
        props.border ? `border:${props.border}` : "solid 2px #e1e2e6"};
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

const VerifyForm = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;
const Input = styled.input`
    height: 40px;

    border-radius: 8px;
    border: solid 2px #00c795;
    font-size: 16px;
    font-family: "Inter";
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;

    ${(props) => (props.width ? `width:${props.width}px` : "")};
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

const MobileContainer = styled.div`
    display: flex;
    height: 1223px;
    width: fit-content;
    background-color: #f5f7fa;
    flex-direction: column;
`;
