import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';

import * as api from '../../api/index';
import FreshChat from 'react-freshchat';
import Text from '../../components/molecules/text';
import Button from '../../components/molecules/button';
import AddressSearch from '../../components/molecules/address-search/AddressSearch';
import MapControl from '../../components/molecules/map-control/MapControl';
import Config from "../../Config";
import Header from '../../components/organisms/header/index';
import SideMenu from '../../components/organisms/menu/index';
import { confirmAlert } from 'react-confirm-alert';
import AreaCode from '../../assets/icons/pngs/areaCode.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from "axios";
import Loading from '../../assets/loading.gif';
import Table from './Table';
import TableAlt from './TableAlt';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: transparent;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100vw;
  height: 100vh;
`;

const MenuOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 64px;
  padding-right: 64px;
`;

const MenuOption = styled.a`
width: 95%
justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: 'Inter';
  font-weight: 400;
 font-size: 18px;
 cursor: pointer;
  ${(props) => (props.fontColor ? `color: ${props.fontColor}` : '#0e0e0f')};
  ${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight}` : '')};
  cursor: pointer;
  display: flex;
  ${(props) => (props.bottom ? 'bottom: 24px;' : '')}
  ${(props) => (props.bottom ? 'position: absolute;' : '')}
  border-top: 1px solid rgb(227, 229, 232);
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
`;

const Row = styled.div`
  margin-top: 22px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;

  width: 100%;
`;
const PhoneInput = styled.input`
  top: 416px;
  left: 308px;
  width: 340px;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e1e4eb;
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Inter';
  text-align: left;
  padding-left: 60px;
  padding-right: 10px;
  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
  background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
  background-position: left;
  background-size: 60px;
  background-repeat: no-repeat;
`;
const Input = styled.input`
  top: 416px;
  left: 308px;
  width: 384px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #cccccc;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'Inter';
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;

  ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : '')};
  ${(props) =>
    props.marginRight ? `margin-right:${props.marginRight}px` : ''};
  ${(props) =>
    props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ''};
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
`;

const VerifyForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : '')};
`;

const TabTable = ({ ...props }) => {
  const api_token = props.api_token;
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [phone, setPhone] = useState(null);
  // const [email, setEmail] = useState(props.user.business !== null ? props.user.business.email  : null);
  // const [address, setAddress] = useState(null);
  // const [pickupLocation, setPickupLocation] = useState(props.user.business !== null ? props.user.business.address  : null);
  // let cb2 = props.addressChanged;

  let changeTab = function(index) {
    setActiveIndex(index);
    // setLoading(true);

    // setTimout(function() {
    //   setLoading(false);
    // }, 500);
    // let data = {
    //   'dropoff_name': name,
    //   'dropoff_phone': phone ? phone : '',
    //   'dropoff_email': email,
    //   'dropoff_address': address ? address.address : '',
    //   'dropoff_lat': address ? address.lat : '',
    //   'dropoff_lng': address ? address.lng : ''
    // }

    // console.log(data);

    // const headers = {
    //     Authorization: "Bearer " + api_token,
    // };

    // axios
    //   .post(Config.BASE_URL + "api/v1/user/order", data, {
    //       headers: headers,
    //   })
    //   .then((res) => {
    //     window.history.back();
    //   })
    //   .catch(function (error) {
    //     alert(error.response.data.message);
    //   });
  }

  return (
    <div className="tab-table">
      <ul className="tab-menu">
        <li className={activeIndex == 0 ? 'active' : ''}
        onClick={() => {
          changeTab(0);
        }}
        >New Orders</li>
        <li className={activeIndex == 1 ? 'active' : ''}
        onClick={() => {
          changeTab(1);
        }}
        >Scheduled</li>
        <li className={activeIndex == 2 ? 'active' : ''}
        onClick={() => {
          changeTab(2);
        }}
        >Active</li>
        <li className={activeIndex == 3 ? 'active' : ''}
        onClick={() => {
          changeTab(3);
        }}
        >Completed</li>
      </ul>
      <div className="table-container">
        {props.user && loading && (
          <div style={{textAlign: 'center',width: '100%'}}>
            <img src={Loading} width="25" height="25" />
          </div>
        )}

        {props.user && !loading && activeIndex == 0 && (
          <div style={{textAlign: 'center',width: '100%'}}>
            <Table history={props.history} api_token={api_token} user={props.user} data={props.user.business_orders} />
          </div>
        )}

        {props.user && !loading && activeIndex == 1 && (
          <div style={{textAlign: 'center',width: '100%'}}>
            {props.user.business_orders_schd.map((row,index) => (
              <div>
                <TableAlt user={props.user} index={index} data2={row[0]} data={row} hideCheckbox={true} key={Math.random()} /> <br />
              </div>
            ))}
          </div>
        )}

        {props.user && !loading && activeIndex == 2 && (
          <div style={{textAlign: 'center',width: '100%'}}>
            {props.user.business_orders_actv.map((row,index) => (
              <div>
                <TableAlt comp={true} user={props.user} index={index} data2={row[0]} data={row} hideCheckbox={true} key={Math.random()} /> <br />
              </div>
            ))}
          </div>
        )}

        {props.user && !loading && activeIndex == 3 && (
          <div style={{textAlign: 'center',width: '100%'}}>
            {props.user.business_orders_comp.map((row,index) => (
              <div>
                <TableAlt comp={true} user={props.user} index={index} data2={row[0]} data={row} hideCheckbox={true} key={Math.random()} /> <br />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const LoginInput = styled.input`
    width: 100%;
    height: 44px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #00c795;
    border-radius: 10px;
    font-size: 14px;
    font-family: "Inter";
    background: #F8F9FB;
    border: 1px solid #BCC1CC;
    border-radius: 8px;

    // background-image: ${(props) => props.bgImage || `url(${AreaCode})`};
    // background-position: left;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    background-size: 60px;
    background-repeat: no-repeat;
    ${(props) => (props.marginLeft ? `margin-left:${props.marginLeft}px` : "")};
    ${(props) =>
        props.marginRight ? `margin-right:${props.marginRight}px` : ""};
    ${(props) =>
        props.marginBottom ? `margin-bottom:${props.marginBottom}px` : ""};
    ${(props) => (props.marginTop ? `margin-top:${props.marginTop}px` : "")};
`;

export default TabTable;
